import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import ContractForm from '@/src/components/contract/ContractForm';

const CreateContract = () => {
  const { customerId } = useParams();

  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Заказчик', link: `/customers/${customerId}` },
    { text: 'Создание договора', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Новый договор</h3>

      <ContractForm />
    </Layout>
  );
};

export default CreateContract;
