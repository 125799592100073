const { default: $api } = require('@/src/lib/api');

const ObjectService = {
  createObject: async data => {
    return $api.post('/objects', data);
  },

  getObjects: async (offset, limit) => {
    return $api.get('/objects', { params: { offset, limit } });
  },

  getObject: async id => {
    return $api.get(`/objects/${id}`);
  },

  updateObject: async (id, data) => {
    return $api.patch(`/objects/${id}`, data);
  },

  deleteObject: async id => {
    return $api.delete(`/objects/${id}`);
  },

  getObjectsForUser: async (id, offset, limit, isArchived) => {
    return $api.get(`/users/${id}/objects`, {
      params: { id, offset, limit, isArchived }
    });
  },

  getObjectsForCustomer: async (id, offset, limit, isArchived) => {
    return $api.get(`/customers/${id}/objects`, {
      params: { id, offset, limit, isArchived }
    });
  },

  getObjectsForCustomerAndUser: async (
    customerId,
    userId,
    offset,
    limit,
    isArchived
  ) => {
    return $api.get(`/customers/${customerId}/user/${userId}/objects`, {
      params: { customerId, userId, offset, limit, isArchived }
    });
  }
};

module.exports = ObjectService;
