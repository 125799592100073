import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loader2 } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import {
  Command,
  CommandEmpty,
  CommandItem,
  CommandList
} from '@/src/components/ui/command';
import useDebounce from '@/src/hooks/useDebounce';
import Loader from '@/src/components/Loader';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useCustomerStore } from '@/src/store/useCustomerStore';

const PAGE_URL = '/customers';

const formSchema = z.object({
  inn: z.coerce.number().min(1, {
    message: 'Поле не может быть пустым'
  }),
  title: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  })
});

const CustomerForm = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const isEditing = !!customerId;

  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  const [suggestions, setSuggestions] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const user = useAuthStore(state => state.user);

  const { getCustomer, createCustomer, updateCustomer, resetError, error } =
    useCustomerStore(state => ({
      getCustomer: state.getCustomer,
      createCustomer: state.createCustomer,
      updateCustomer: state.updateCustomer,
      resetError: state.resetError,
      error: state.error
    }));

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const ustomerData = await getCustomer(customerId);
        setCustomer(ustomerData);
      } catch (error) {
        console.error('Ошибка при загрузке данных объекта:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    if (isEditing) {
      fetchCustomer();
    } else {
      setIsFetchLoading(false);
    }
  }, [getCustomer, customerId, isEditing]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      inn: '',
      title: ''
    }
  });

  useEffect(() => {
    if (isEditing && customer) {
      form.reset(customer);
    }
  }, [form, isEditing, customer]);

  const fetchDadata = async () => {
    const formData = form.getValues();
    try {
      const response = await fetch(
        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token ${process.env.REACT_APP_DADATA_API_KEY}`
          },
          body: JSON.stringify({
            query: formData.inn,
            status: 'ACTIVE'
          })
        }
      );

      const data = await response.json();
      setSuggestions(data.suggestions);
      setIsPopupVisible(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const debounceTitle = useDebounce(fetchDadata, 500);

  const handleInputChange = event => {
    const value = event.target.value;
    debounceTitle(value);
    form.setValue('inn', value);
  };

  const onSubmit = async () => {
    const formData = form.getValues();
    setIsLoading(true);

    try {
      if (!customerId) {
        const customer = await createCustomer({
          ...formData,
          user_list: [user.id]
        });
        setIsLoading(false);

        if (!error) {
          navigate(`${PAGE_URL}/${customer._id}`);
        }
      }

      if (customerId) {
        await updateCustomer(customerId, {
          ...formData
        });
        setIsLoading(false);

        if (!error) {
          navigate(`${PAGE_URL}/${customerId}`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchLoading) {
    return (
      <div className="mt-4">
        <Loader />
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <FormField
          control={form.control}
          name="inn"
          render={({ field }) => (
            <FormItem>
              <FormLabel>ИНН</FormLabel>
              <FormControl>
                <Command className="overflow-visible">
                  <Input
                    {...field}
                    type="number"
                    onChange={handleInputChange}
                    placeholder="1234567890"
                  />

                  {isPopupVisible && (
                    <CommandList className="mt-2 border rounded-md">
                      <CommandEmpty>
                        <span className="muted">Не найдено</span>
                      </CommandEmpty>
                      {suggestions.map(suggestion => (
                        <CommandItem
                          key={suggestion.value}
                          value={suggestion.value}
                          onSelect={() => {
                            resetError();
                            form.setValue('title', suggestion.value);
                            form.setValue('inn', suggestion.data.inn);
                            setIsPopupVisible(false);
                          }}
                        >
                          <p>
                            {suggestion.value}
                            <span className="muted">
                              {' '}
                              {suggestion.data.address.value}
                            </span>
                          </p>
                        </CommandItem>
                      ))}
                    </CommandList>
                  )}
                </Command>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Название</FormLabel>
              <FormControl>
                <Input placeholder="ООО Ромашка" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button size="sm" type="submit" disabled={isLoading}>
          {isEditing ? (
            isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Сохранение...
              </>
            ) : (
              'Сохранить'
            )
          ) : isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Создание...
            </>
          ) : (
            'Создать'
          )}
        </Button>

        {error && (
          <p className="text-sm font-medium text-destructive py-0">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default CustomerForm;
