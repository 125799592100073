import { Link } from 'react-router-dom';
import { formatDate } from '@/src/utils/utils';

const ContractList = ({ data }) => {
  return (
    <>
      {data && data.length > 0 ? (
        <div className="flex flex-col space-y-4 mt-4">
          {data.map(contract => (
            <Link
              key={contract._id}
              to={`/customers/${contract.customerId}/contracts/${contract._id}`}
              className="transition-colors hover:hover:text-gray-500"
            >
              <div className="flex flex-col space-y-1">
                <div className="text-sm font-medium leading-none">
                  {contract.number} от {formatDate(contract.dateSignature)}
                </div>
                <div className="text-sm text-muted-foreground">
                  {`${formatDate(contract.dateStart)} - ${
                    contract.isUnlimited
                      ? 'Бессрочно'
                      : formatDate(contract.dateEnd)
                  }`}
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <p className="muted">Нет договоров</p>
      )}
    </>
  );
};

export default ContractList;
