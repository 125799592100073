import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const BreadcrumbItem = ({ text, link, isLast }) => {
  return (
    <div className="flex items-center">
      {link ? (
        <Link to={link} className="hover:underline">
          {text}
        </Link>
      ) : (
        <span className="text-gray-500">{text}</span>
      )}
      {!isLast && <ChevronRight className="mx-2 mt-[1px] h-4 w-4" />}
    </div>
  );
};

export default BreadcrumbItem;
