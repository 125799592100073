const { default: $api } = require('@/src/lib/api');

const NoteService = {
  createNote: async data => {
    return $api.post('/notes', data);
  },

  getNotes: async (offset, limit) => {
    return $api.get('/notes', { params: { offset, limit } });
  },

  getNote: async noteId => {
    return $api.get(`/notes/${noteId}`);
  },

  updateNote: async (id, data) => {
    return $api.patch(`/notes/${id}`, data);
  },

  deleteNote: async id => {
    return $api.delete(`/notes/${id}`);
  },

  getNotesForObject: async (objectId, offset, limit) => {
    return $api.get(`/notes/object/${objectId}`, {
      params: { objectId, offset, limit }
    });
  },

  getNotesForUser: async (userId, offset, limit) => {
    return $api.get(`/notes/user/${userId}`, {
      params: { userId, offset, limit }
    });
  },

  getLatestNotes: async () => {
    return $api.get(`/notes/latest`);
  },

  getNotesExport: async objectId => {
    return $api.get(`/notes/${objectId}/export`);
  }
};

module.exports = NoteService;
