import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import CustomerForm from '@/src/components/customer/CustomerForm';

const CreateCustomer = () => {
  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Создание', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Новый заказчик</h3>

      <CustomerForm />
    </Layout>
  );
};

export default CreateCustomer;
