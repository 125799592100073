import {
  CheckCircle2,
  CircleDot,
  Circle,
  AlertCircle,
  XCircle
} from 'lucide-react';

// ALL
export const MAX_FILE_COUNT = 5;
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
export const DEFAULT_MOSCOW_LATITUDE = 55.755864;
export const DEFAULT_MOSCOW_LONGITUDE = 37.617698;

export const OBJECT_TYPE = [
  'Отдельно стоящее здание',
  'Встроенное',
  'Пристроенное',
  'Встроенно-пристроенные'
];

export const OBJECT_FIRE_RESISTANCE = ['I', 'II', 'III', 'IV'];

export const OBJECT_KKPO = ['С0', 'С1', 'С2', 'С3'];

export const OBJECT_KFPO = [
  {
    label: 'Класс Ф1',
    description:
      'здания, предназначенные для постоянного проживания и временного пребывания людей',
    items: [
      {
        id: '1',
        label: 'Ф1.1',
        description:
          'здания дошкольных образовательных организаций, специализированных домов престарелых и инвалидов (неквартирные), спальные корпуса образовательных организаций с наличием интерната и детских организаций, здания медицинских организаций, предназначенные для оказания медицинской помощи в стационарных условиях (круглосуточно)'
      },
      {
        id: '2',
        label: 'Ф1.2',
        description:
          'гостиницы, общежития (за исключением общежитий квартирного типа), спальные корпуса санаториев и домов отдыха общего типа, кемпингов'
      },
      {
        id: '3',
        label: 'Ф1.3',
        description:
          'многоквартирные жилые дома, в том числе общежития квартирного типа'
      },
      {
        id: '4',
        label: 'Ф1.4',
        description: 'одноквартирные жилые дома, в том числе блокированные'
      }
    ]
  },
  {
    label: 'Класс Ф2',
    description: 'здания зрелищных и культурно-просветительных учреждений',
    items: [
      {
        id: '1',
        label: 'Ф2.1',
        description:
          'театры, кинотеатры, концертные залы, клубы, цирки, спортивные сооружения с трибунами, библиотеки и другие учреждения с расчетным числом посадочных мест для посетителей в закрытых помещениях;'
      },
      {
        id: '1',
        label: 'Ф2.2',
        description:
          'музеи, выставки, танцевальные залы и другие подобные учреждения в закрытых помещениях'
      },
      {
        id: '1',
        label: 'Ф2.3',
        description:
          'здания учреждений, указанные в подпункте "а" настоящего пункта, на открытом воздухе'
      },
      {
        id: '1',
        label: 'Ф2.4',
        description:
          'здания учреждений, указанные в подпункте "б" настоящего пункта, на открытом воздухе'
      }
    ]
  },
  {
    label: 'Класс Ф3',
    description: 'здания организаций по обслуживанию населения',
    items: [
      {
        id: '1',
        label: 'Ф3.1',
        description: 'здания организаций торговли'
      },
      {
        id: '2',
        label: 'Ф3.2',
        description: 'здания организаций общественного питания'
      },
      {
        id: '3',
        label: 'Ф3.3',
        description: 'вокзалы'
      },
      {
        id: '4',
        label: 'Ф3.4',
        description:
          'здания медицинских организаций, предназначенные для осуществления медицинской деятельности, за исключением зданий, указанных в подпункте "а" пункта 1 настоящей части'
      },

      {
        id: '5',
        label: 'Ф3.5',
        description:
          'помещения для посетителей организаций бытового и коммунального обслуживания с нерасчетным числом посадочных мест для посетителей'
      },
      {
        id: '6',
        label: 'Ф3.6',
        description:
          'физкультурно-оздоровительные комплексы и спортивно-тренировочные учреждения с помещениями без трибун для зрителей, бытовые помещения, бани'
      },
      {
        id: '7',
        label: 'Ф3.7',
        description: 'объекты религиозного назначения'
      }
    ]
  },
  {
    label: 'Класс Ф4',
    description:
      'здания образовательных организаций, научных и проектных организаций, органов управления учреждений',
    items: [
      {
        id: '1',
        label: 'Ф4.1',
        description:
          'здания общеобразовательных организаций, организаций дополнительного образования детей, профессиональных образовательных организаций'
      },
      {
        id: '2',
        label: 'Ф4.2',
        description:
          'здания образовательных организаций высшего образования, организаций дополнительного профессионального образования'
      },
      {
        id: '3',
        label: 'Ф4.3',
        description:
          'здания органов управления учреждений, проектно-конструкторских организаций, информационных и редакционно-издательских организаций, научных организаций, банков, контор, офисов'
      },
      {
        id: '4',
        label: 'Ф4.4',
        description: 'здания пожарных депо'
      }
    ]
  },
  {
    label: 'Класс Ф5',
    description: 'здания производственного или складского назначения',
    items: [
      {
        id: '1',
        label: 'Ф5.1',
        description:
          'производственные здания, сооружения, производственные и лабораторные помещения, мастерские, крематории'
      },
      {
        id: '2',
        label: 'Ф5.2',
        description:
          'складские здания, сооружения, стоянки для автомобилей без технического обслуживания и ремонта, книгохранилища, архивы, складские помещения'
      },
      {
        id: '3',
        label: 'Ф5.3',
        description: 'здания сельскохозяйственного назначения'
      }
    ]
  }
];

export const OBJECT_SERVICE_INTERVALS = ['Ежемесячно', 'Ежеквартально'];

export const OBJECT_SYSTEMS = [
  { id: '1', label: 'СПС', value: 'sps' },
  { id: '2', label: 'СОУЭ', value: 'soue' },
  { id: '3', label: 'АУП', value: 'aup' },
  { id: '4', label: 'ВПВ', value: 'vpv' },
  { id: '5', label: 'СПДВ', value: 'spdv' },
  { id: '6', label: 'ОС', value: 'os' },
  { id: '7', label: 'СКУД', value: 'skud' },
  { id: '8', label: 'СОТ', value: 'sot' },
  { id: '9', label: 'АО', value: 'ao' },
  { id: '10', label: 'Стрелец-Мониторинг', value: 'pak' }
];

export const OBJECT_SYSTEMS_STATUS = [
  'Работоспособна',
  'Требует внимания',
  'Не работоспособна'
];

// СПС
export const OBJECT_SYSTEMS_SPS_TYPE = ['Адресный', 'Аналоговый', 'Смешанный'];
export const OBJECT_SYSTEMS_SPS_MANUFACTURER = [
  'Аргус-Спектр',
  'Болид',
  'Рубеж',
  'ВЭРС',
  'Сибирский Арсенал',
  'Юнитест',
  'Bosch',
  'Siemens',
  'Simplex',
  'Другой'
];

// СОУЭ
export const OBJECT_SYSTEMS_SOUE_TYPE = [
  '1-й тип (звуковое оповещение)',
  '2-й тип (звуковое оповещение + световые табло)',
  '3-й тип (речевое оповещение + световые табло)',
  '4-й тип (зональное речевое оповещение + световые табло + обратная связь)',
  '5-й тип (зональное речевое оповещение + интеллектуальные световые табло + обратная связь)'
];
export const OBJECT_SYSTEMS_SOUE_MANUFACTURER = [
  'Аргус-Спектр',
  'Болид',
  'ВЭРС',
  'Омега Саунд',
  'Рубеж',
  'Сибирский Арсенал',
  'Тромбон',
  'Электротехника и Автоматика',
  'Юнитест',
  'Bosch',
  'Inter-M',
  'LPA',
  'МЕТА',
  'ROXTON',
  'Siemens',
  'Simplex',
  'ZVON',
  'Другой'
];

// АУП
export const OBJECT_SYSTEMS_AUP_TYPE = [
  'Водяная',
  'Газовая',
  'Порошковая',
  'Аэрозольная',
  'Пенная',
  'Водяная (со смачивателем)',
  'ТРВ (тонкораспыленная вода)'
];
export const OBJECT_SYSTEMS_AUP_TYPE_1 = [
  'Спринклерная',
  'Дренчерная',
  'Воздухозаполненная'
];

// ВПВ
export const OBJECT_SYSTEMS_VPV_TYPE = [
  'Совмещенный с АУП',
  'Совмещенный с хозяйственно-питьевым водопроводом',
  'Обособленный'
];

// СПДВ
export const OBJECT_SYSTEMS_SPDV_TYPE = [
  'Вытяжная противодымная вентиляция',
  'Приточная противодымная вентиляция'
];
export const OBJECT_SYSTEMS_SPDV_TYPE_1 = [
  'Дымоудаление из коридоров',
  'Дымоудаление и атриумов/пассажей',
  'Дымоудаление из паркинга',
  'Дымоудаление из других помещений'
];
export const OBJECT_SYSTEMS_SPDV_TYPE_2 = [
  'Подпор в тамбур-шлюзы',
  'Подпор в лестничные клетки',
  'Подпор в лифтовые шахты',
  'Компенсация ДУ',
  'Подпор в иные помещения'
];

// ОС
export const OBJECT_SYSTEMS_OS_TYPE = ['Адресный', 'Аналоговый', 'Смешанный'];
export const OBJECT_SYSTEMS_OS_MANUFACTURER = ['Болид', 'Рубеж', 'Другой'];

// СКУД
export const OBJECT_SYSTEMS_SKUD_TYPE = ['Адресный', 'Аналоговый', 'Смешанный'];
export const OBJECT_SYSTEMS_SKUD_MANUFACTURER = ['Болид', 'Рубеж', 'Другой'];

// СОТ
export const OBJECT_SYSTEMS_SOT_TYPE = ['Аналоговая', 'IP'];

// АО
export const OBJECT_SYSTEMS_AO_TYPE = ['Совмещенная', 'Раздельная'];

// OBJECT STATUSES
export const STATUSES = [
  {
    label: 'Выполнено',
    value: 2, //1
    color: 'stroke-green-600',
    icon: CheckCircle2
  },
  {
    label: 'Запланировано',
    value: 3, // 2
    color: 'stroke-slate-600',
    icon: CircleDot
  },
  {
    label: 'Нет записей',
    value: 1, // 3
    color: 'stroke-slate-600',
    icon: Circle
  },
  {
    label: 'Внимание',
    value: 4,
    color: 'stroke-amber-600',
    icon: AlertCircle
  },
  {
    label: 'Просрочено',
    value: 5,
    color: 'stroke-red-600',
    icon: XCircle
  }
];

export const PERIODICITY = [
  {
    label: 'Ежемесячно',
    value: 'Ежемесячно'
  },
  {
    label: 'Ежеквартально',
    value: 'Ежеквартально'
  }
];
