import Layout from '@/src/components/Layout';
import LoginForm from '@/src/components/auth/LoginForm';

const Login = () => {
  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
        <h3 className="text-center">Вход</h3>

        <LoginForm />
      </div>
    </Layout>
  );
};

export default Login;
