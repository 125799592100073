import { useState } from 'react';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loader2 } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import { useUserStore } from '@/src/store/useUserStore';

const formSchema = z.object({
  email: z
    .string()
    .trim()
    .toLowerCase()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .email({
      message: 'Поле должно содержать валидный e-mail'
    })
});

const ForgotPasswordForm = () => {
  const [isSending, setIsSending] = useState(false);

  const { isLoading, forgotPassword, error } = useUserStore(state => ({
    isLoading: state.isLoading,
    forgotPassword: state.forgotPassword,
    error: state.error
  }));

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = async ({ email }) => {
    forgotPassword(email);
    setIsSending(true);
  };

  return (
    <>
      {isSending && !error ? (
        <>
          <blockquote className="alert">
            Мы отправили вам на почту инструкции по восстановлению пароля. Чтобы
            восстановить доступ к аккаунту, пройдите по ссылке из письма. Если
            этого письма нет во «Входящих», пожалуйста, проверьте «Спам».
          </blockquote>
          <p className="text-center muted p-4 pt-0">
            <Link to="/" className="link">
              Вернуться на главную
            </Link>
          </p>
        </>
      ) : (
        <>
          <blockquote className="alert">
            Введите адрес электронной почты, и мы вышлем вам инструкцию по
            восстановлению пароля.
          </blockquote>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 mt-6"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Почта</FormLabel>
                    <FormControl>
                      <Input
                        // autoFocus={true}
                        placeholder="ivanov@example.com"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {isLoading ? (
                <Button className="w-full" disabled>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                </Button>
              ) : (
                <Button type="submit" className="w-full">
                  Отправить
                </Button>
              )}
            </form>

            {error && (
              <p className="text-sm font-medium text-destructive text-center px-6 py-0">
                {error}
              </p>
            )}

            <p className="text-center muted p-4 pt-0">
              Есть аккаунт?{' '}
              <Link to="/" className="link">
                Вернуться к авторизации
              </Link>
            </p>
          </Form>
        </>
      )}
    </>
  );
};

export default ForgotPasswordForm;
