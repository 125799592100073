import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import UserService from '@/src/services/UserService';

export const useUserStore = create(
  devtools(
    set => ({
      // user: null,
      users: [],
      isLoading: false,
      error: null,

      forgotPassword: async email => {
        try {
          set({ isLoading: true }, false, 'Forgot Password Loading');
          await UserService.forgotPassword(email);
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Forgot Password Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Forgot Password Loading');
        }
      },

      changePassword: async (forgotPasswordLink, password) => {
        try {
          set({ isLoading: true }, false, 'Change Password Loading');
          await UserService.changePassword(forgotPasswordLink, password);
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Change Password Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Change Password Loading');
        }
      },

      createUser: async data => {
        try {
          set({ isLoading: true }, false, 'Create User Loading');
          const response = await UserService.createUser(data);
          set(
            state => ({
              users: [...state.users, response.data],
              error: null
            }),
            false,
            'Create User'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Create User Error'
          );
          // throw new Error(`Ошибка при регистрации пользователя`);
        } finally {
          set({ isLoading: false }, false, 'Create User Loading');
        }
      },

      getUsers: async (userId, role, isActive, offset, limit) => {
        try {
          set({ isLoading: true }, false, 'Get Users Loading');
          set({ users: [], error: null }, false, 'Clear Get Users');
          const response = await UserService.getUsers(
            userId,
            role,
            isActive,
            offset,
            limit
          );
          set(
            {
              users: response.data.users,
              error: null
            },
            false,
            'Get Users'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Users Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Users Loading');
        }
      },

      getUser: async id => {
        try {
          set({ isLoading: true }, false, 'Get User Loading');
          // set({ user: null, error: null }, false, 'Clear Get User');
          const response = await UserService.getUser(id);
          // set({ user: response.data, error: null }, false, 'Get User');
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get User Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get User Loading');
        }
      },

      updateUser: async (id, data) => {
        try {
          set({ isLoading: true }, false, 'Update User Loading');
          const response = await UserService.updateUser(id, data);
          set(
            state => ({
              users: state.users.map(user => {
                if (user._id === id) {
                  return { ...user, ...data };
                }
                return user;
              }),
              error: null
            }),
            false,
            'Update User'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Update User Error'
          );
          // throw new Error(`Ошибка при обновлении пользователя`);
        } finally {
          set({ isLoading: false }, false, 'Update User Loading');
        }
      },

      deleteUser: async id => {
        try {
          set({ isLoading: true }, false, 'Delete User Loading');
          await UserService.deleteUser(id);
          set(
            state => ({
              users: state.users.filter(user => user._id !== id),
              error: null
            }),
            false,
            'Delete User'
          );
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Delete User Error'
          );
          // throw new Error(`Ошибка при удалении пользователя`);
        } finally {
          set({ isLoading: false }, false, 'Delete User Loading');
        }
      },

      getUsersForObject: async id => {
        try {
          set({ isLoading: true }, false, 'Get Users For Object Loading');
          const response = await UserService.getUsersForObject(id);
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Users For Object Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Users For Object Loading');
        }
      },

      getUsersForCustomer: async (
        customerId,
        role,
        isActive,
        offset,
        limit
      ) => {
        try {
          set({ isLoading: true }, false, 'Get Users For Customer Loading');
          const response = await UserService.getUsersForCustomer(
            customerId,
            role,
            isActive,
            offset,
            limit
          );
          set(
            {
              users: response.data.users,
              error: null
            },
            false,
            'Get Users For Customer'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Users For Customer Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Users For Customer Loading');
        }
      },

      disconnectTelegram: async () => {
        try {
          set({ isLoading: true }, false, 'Disconnect Telegram Loading');
          const response = await UserService.disconnectTelegram();
          return response.data;
        } catch (error) {
          set(
            { error: error.response.data.message },
            false,
            'Disconnect Telegram Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Disconnect Telegram Loading');
        }
      },

      resetError: () => {
        set({ error: null }, false, 'Reset Error');
      }
    }),
    { name: 'users-storage' }
  )
);
