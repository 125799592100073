import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import ObjectForm from '@/src/components/object/ObjectForm';

const CreateObject = () => {
  const { customerId } = useParams();

  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Заказчик', link: `/customers/${customerId}` },
    { text: 'Создание объекта', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Новый объект</h3>

      <ObjectForm />
    </Layout>
  );
};

export default CreateObject;
