import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from '@/src/routes/AppRouter';
import { useAuthStore } from '@/src/store/useAuthStore';

const App = () => {
  const { checkAuth } = useAuthStore(state => ({
    checkAuth: state.checkAuth
  }));

  useEffect(() => {
    // if (localStorage.getItem('token')) {
    checkAuth();
    // }
  }, []);

  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
};

export default App;
