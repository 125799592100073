import { DataTableColumnHeader } from '@/src/components/note/data-table/data-table-column-header';
import { formatDateAndTime } from '@/src/utils/utils';

export const columns = [
  {
    accessorKey: 'customer',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Заказчик" />
    ),
    cell: ({ row }) => (
      <div className="max-w-max font-medium md:max-w-[500px] md:font-normal">
        {row.getValue('customer').title}
      </div>
    ),
    filterFn: (row, id, value) => {
      const customerTitle = row.getValue('customer').title;
      return customerTitle.toLowerCase().includes(value.toLowerCase());
    }
  },
  {
    accessorKey: 'object',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Объект" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-max md:max-w-[500px]">
            {row.getValue('object').address.name}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const addressName = row.getValue('object').address.name;
      return addressName.toLowerCase().includes(value.toLowerCase());
    }
  },
  {
    accessorKey: 'system',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Система" />
    ),
    cell: ({ row }) => {
      return row.getValue('system');
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'master',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Ответственный" />
    ),
    cell: ({ row }) => {
      const master = `${row.getValue('master')?.lastName} ${
        row.getValue('master')?.firstName
      } ${row.getValue('master')?.middleName}`;

      return master;
    },

    filterFn: (row, id, value) => {
      const master = `${row.getValue('master')?.lastName} ${
        row.getValue('master')?.firstName
      } ${row.getValue('master')?.middleName}`;
      return master.toLowerCase().includes(value.toLowerCase());
    }
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Дата" />
    ),
    cell: ({ row }) => {
      return formatDateAndTime(row.getValue('createdAt'));
    },
    filterFn: (row, id, value) => {
      const createdAt = formatDateAndTime(row.getValue('createdAt'));
      return createdAt.toLowerCase().includes(value.toLowerCase());
    }
  }
];
