export const SIDEBAR_NAV_ITEMS = [
  {
    title: 'Профиль',
    href: '/settings'
  },
  {
    title: 'Уведомления',
    href: '/settings/notifications'
  }
];

export const NOTIFICATION_METHOD = [
  {
    id: 'email',
    label: 'E-mail'
  },
  {
    id: 'telegram',
    label: 'Телеграм'
  }
];
