const { default: $api } = require('@/src/lib/api');

const ContractService = {
  createContract: async data => {
    return $api.post('/contracts', data);
  },

  getContracts: async customerId => {
    return $api.get('/contracts', { params: { customerId } });
  },

  getContract: async contractId => {
    return $api.get(`/contracts/${contractId}`);
  },

  updateContract: async (contractId, data) => {
    return $api.patch(`/contracts/${contractId}`, data);
  },

  deleteContract: async contractId => {
    return $api.delete(`/contracts/${contractId}`);
  }
};

module.exports = ContractService;
