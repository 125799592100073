import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Loader2, User, XCircle } from 'lucide-react';
import { Skeleton } from '@/src/components/ui/skeleton';
import { Button } from '@/src/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/src/components/ui/alert-dialog';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import ContractList from '@/src/components/contract/ContractList';
import ObjectList from '@/src/components/object/ObjectList';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useUserStore } from '@/src/store/useUserStore';
import { useCustomerStore } from '@/src/store/useCustomerStore';
import { useObjectStore } from '@/src/store/useObjectStore';
import { useContractStore } from '@/src/store/useContractStore';

const PAGE_URL = '/customers';
const OBJECTS_LIMIT = 5;

const CustomerItem = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [objects, setObjects] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isDeletedLoading, setIsDeletedLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalObjects, setTotalObjects] = useState(0);

  const [archivedObjects, setArchivedObjects] = useState([]);
  const [isLoadingMoreArchivedObjects, setIsLoadingMoreArchivedObjects] =
    useState(false);
  const [totalArchivedObjects, setTotalArchivedObjects] = useState(0);
  const [currentArchivedPage, setCurrentArchivedPage] = useState(0);

  const sortedUsers = [...users]?.sort((a, b) =>
    a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1
  );

  const user = useAuthStore(state => state.user);

  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');

  const { getCustomer, deleteCustomer, error } = useCustomerStore(state => ({
    getCustomer: state.getCustomer,
    deleteCustomer: state.deleteCustomer,
    error: state.error
  }));

  const { getUsersForCustomer } = useUserStore(state => ({
    getUsersForCustomer: state.getUsersForCustomer
  }));

  const { getObjectsForCustomer, getObjectsForCustomerAndUser } =
    useObjectStore(state => ({
      getObjectsForCustomer: state.getObjectsForCustomer,
      getObjectsForCustomerAndUser: state.getObjectsForCustomerAndUser
    }));

  const { getContracts } = useContractStore(state => ({
    getContracts: state.getContracts
  }));

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerData = await getCustomer(customerId);
        setCustomer(customerData);
      } catch (error) {
        console.error('Ошибка при загрузке информации о заказчике:', error);
      }
    };

    fetchCustomerData();
  }, [getCustomer, customerId]);

  useEffect(() => {
    const fetchUsersForCustomer = async () => {
      try {
        const usersData = await getUsersForCustomer(customerId, 'customer');
        setUsers(usersData.users);
      } catch (error) {
        console.error(
          'Ошибка при загрузке пользователей для заказчика:',
          error
        );
      }
    };

    fetchUsersForCustomer();
  }, [getUsersForCustomer, customerId]);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const contractsData = await getContracts(customerId);
        setContracts(contractsData);
      } catch (error) {
        console.error('Ошибка при загрузке контрактов:', error);
      }
    };

    fetchContracts();
  }, [getContracts, customerId]);

  useEffect(() => {
    const fetchObjectsForCustomer = async () => {
      try {
        let objectsData;

        if (isUser) {
          objectsData = await getObjectsForCustomer(
            customerId,
            currentPage * OBJECTS_LIMIT,
            OBJECTS_LIMIT,
            false
          );
        }

        if (isMaster) {
          objectsData = await getObjectsForCustomerAndUser(
            customerId,
            user.id,
            currentPage * OBJECTS_LIMIT,
            OBJECTS_LIMIT,
            false
          );
        }

        setObjects(prevObjects => [...prevObjects, ...objectsData.objects]);
        setTotalObjects(objectsData.total);
      } catch (error) {
        console.error('Ошибка при загрузке объектов:', error);
      } finally {
        setIsFetchLoading(false);
        setIsLoadingMore(false);
      }
    };

    fetchObjectsForCustomer();
  }, [
    getObjectsForCustomer,
    getObjectsForCustomerAndUser,
    customerId,
    currentPage,
    isUser,
    isMaster,
    user
  ]);

  useEffect(() => {
    const fetchArchivedObjectsForCustomer = async () => {
      try {
        let archivedObjectsData;

        if (isUser) {
          archivedObjectsData = await getObjectsForCustomer(
            customerId,
            currentArchivedPage * OBJECTS_LIMIT,
            OBJECTS_LIMIT,
            true
          );
        }

        if (isMaster) {
          archivedObjectsData = await getObjectsForCustomerAndUser(
            customerId,
            user.id,
            currentArchivedPage * OBJECTS_LIMIT,
            OBJECTS_LIMIT,
            true
          );
        }

        setArchivedObjects(prevObjects => [
          ...prevObjects,
          ...archivedObjectsData.objects
        ]);
        setTotalArchivedObjects(archivedObjectsData.total);
      } catch (error) {
        console.error('Ошибка при загрузке архивных объектов:', error);
      } finally {
        setIsFetchLoading(false);
        setIsLoadingMore(false);
      }
    };

    fetchArchivedObjectsForCustomer();
  }, [
    getObjectsForCustomer,
    getObjectsForCustomerAndUser,
    customerId,
    currentArchivedPage,
    isUser,
    isMaster,
    user
  ]);

  const handleDelete = async () => {
    setIsDeletedLoading(true);
    try {
      await deleteCustomer(customerId);
    } catch (error) {
      console.error('Ошибка при удалении записи:', error);
    } finally {
      setIsDeletedLoading(false);
    }

    navigate(PAGE_URL);
  };

  const handleLoadMoreObjects = () => {
    setIsLoadingMore(true);
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleLoadMoreArchivedObjects = () => {
    setIsLoadingMoreArchivedObjects(true);
    setCurrentArchivedPage(prevPage => prevPage + 1);
  };

  if (error) {
    return <p className="text-sm font-medium text-destructive py-0">{error}</p>;
  }

  if (isFetchLoading) {
    return (
      <div className="mt-4 grid gap-2">
        <Skeleton className="h-7 w-[450px]" />
        <Skeleton className="h-4 w-[200px]" />
        <div className="mt-4 grid gap-4">
          <Skeleton className="h-4 w-[350px]" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-2 items-start justify-between md:flex-row">
        <div>
          <h3>{customer?.title}</h3>
        </div>

        {isUser && (
          <div className="flex gap-2">
            <Button size="sm" asChild>
              <Link to={`/customers/${customerId}/edit`}>Редактировать</Link>
            </Button>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button size="sm" disabled={isDeletedLoading}>
                  {isDeletedLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Удаление...
                    </>
                  ) : (
                    'Удалить'
                  )}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle className="border-0">
                    Вы уверены, что хотите удалить объект?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Отменить</AlertDialogCancel>
                  <AlertDialogAction onClick={handleDelete}>
                    Удалить
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        )}
      </div>

      <div className="mt-4 grid gap-2">
        {customer?.inn && <p>ИНН: {customer.inn}</p>}
      </div>

      <div className="grid gap-4">
        {isUser && (
          <div className="mt-4">
            <div className="flex flex-row gap-2 items-center justify-between">
              <h4 className="font-semibold leading-none tracking-tight">
                Представители заказчика
              </h4>
              <div>
                <Button size="sm" asChild>
                  <Link to={`/customers/${customerId}/users/create`}>
                    Добавить
                  </Link>
                </Button>
              </div>
            </div>

            {sortedUsers.length > 0 ? (
              <div className="grid gap-4 mt-4">
                {sortedUsers.map(user => (
                  <Link
                    key={user.id}
                    to={`/customers/${customerId}/users/${user.id}`}
                    className="transition-colors hover:hover:text-gray-500"
                  >
                    <div className="flex items-center space-x-4">
                      <Avatar>
                        <AvatarFallback>
                          {user.isActive ? <User /> : <XCircle />}
                        </AvatarFallback>
                      </Avatar>
                      <div>
                        {user.isActive ? (
                          <p className="text-sm font-medium leading-none">
                            {`${user.lastName} ${user.firstName} ${user.middleName}`}
                          </p>
                        ) : (
                          <p className="text-sm text-muted-foreground">
                            {`${user.lastName} ${user.firstName} ${user.middleName}`}
                          </p>
                        )}
                        <p className="text-sm text-muted-foreground">
                          {user.position}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <p className="muted">Нет пользователей</p>
            )}
          </div>
        )}

        <div className="mt-4">
          <div className="flex flex-row gap-2 items-center justify-between">
            <h4 className="font-semibold leading-none tracking-tight">
              Договоры
            </h4>
            <div>
              <Button size="sm" asChild>
                <Link to={`/customers/${customerId}/contracts/create`}>
                  Добавить
                </Link>
              </Button>
            </div>
          </div>

          <ContractList data={contracts} />
        </div>

        <div className="mt-4">
          <div className="flex flex-row gap-2 items-center justify-between">
            <h4 className="font-semibold leading-none tracking-tight">
              Объекты заказчика
            </h4>
            {isUser && (
              <div>
                <Button size="sm" asChild>
                  <Link to={`/customers/${customerId}/objects/create`}>
                    Добавить
                  </Link>
                </Button>
              </div>
            )}
          </div>

          <ObjectList data={objects} />

          {objects.length < totalObjects && (
            <div className="flex justify-center mt-5">
              <Button onClick={handleLoadMoreObjects} disabled={isLoadingMore}>
                {isLoadingMore ? 'Загрузка...' : 'Загрузить еще'}
              </Button>
            </div>
          )}
        </div>

        {archivedObjects.length > 0 && (
          <div className="mt-4">
            <div className="flex flex-row gap-2 items-center justify-between">
              <h4 className="font-semibold leading-none tracking-tight">
                Архив объектов
              </h4>
            </div>

            <ObjectList data={archivedObjects} />

            {archivedObjects.length < totalArchivedObjects && (
              <div className="flex justify-center mt-5">
                <Button
                  onClick={handleLoadMoreArchivedObjects}
                  disabled={isLoadingMoreArchivedObjects}
                >
                  {isLoadingMoreArchivedObjects
                    ? 'Загрузка...'
                    : 'Загрузить еще'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerItem;
