import BreadcrumbItem from '@/src/components/BreadcrumbItem';

const Breadcrumb = ({ items }) => {
  return (
    <div className="flex flex-wrap mb-4">
      {items.map((item, index) => (
        <BreadcrumbItem
          key={index}
          text={item.text}
          link={item.link}
          isLast={index === items.length - 1}
        />
      ))}
    </div>
  );
};

export default Breadcrumb;
