import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/src/components/ui/button';
import { Checkbox } from '@/src/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import { Separator } from '@/src/components/ui/separator';
import Layout from '@/src/components/Layout';
import SidebarNav from '@/src/components/SidebarNav';
import Loader from '@/src/components/Loader';
import { useUserStore } from '@/src/store/useUserStore';
import { useAuthStore } from '@/src/store/useAuthStore';
import {
  SIDEBAR_NAV_ITEMS,
  NOTIFICATION_METHOD
} from '@/src/constants/mainConstants';
import { Link } from 'react-router-dom';

const formSchema = z.object({
  // notification_method: z
  //   .array(z.string())
  //   .refine(value => value.some(item => item), {
  //     message: 'Необходимо выбрать хотя бы один вариант'
  //   })
});

const Notifications = () => {
  const [editingUser, setEditingUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  const user = useAuthStore(state => state.user);

  const { getUser, updateUser, error, disconnectTelegram } = useUserStore(
    state => ({
      getUser: state.getUser,
      updateUser: state.updateUser,
      error: state.error,
      disconnectTelegram: state.disconnectTelegram
    })
  );

  useEffect(() => {
    const fetchUser = async () => {
      setIsFetchLoading(true);
      try {
        const userData = await getUser(user.id);
        setEditingUser(userData);
      } catch (error) {
        console.error('Ошибка при загрузке данных пользователя:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchUser();
  }, [getUser, user.id]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      notification_method: []
    }
  });

  useEffect(() => {
    if (editingUser) {
      form.setValue('notification_method', editingUser.notification_method);
    }
  }, [editingUser, form]);

  const handleDisconnectTelegram = async () => {
    await disconnectTelegram();

    const fetchUser = async () => {
      try {
        const userData = await getUser(user.id);
        setEditingUser(userData);
      } catch (error) {
        console.error('Ошибка при загрузке данных пользователя:', error);
      }
    };

    fetchUser();
  };

  const onSubmit = async () => {
    const { notification_method } = form.getValues();
    setIsLoading(true);
    try {
      await updateUser(editingUser.id, {
        notification_method
      });

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      toast(`Изменения сохранены`, {
        description: 'Обновление уведомлений'
      });
    }
  };

  if (isFetchLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <Layout>
      <h3>Настройки</h3>
      <Separator className="my-6" />

      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="lg:w-1/5">
          <SidebarNav items={SIDEBAR_NAV_ITEMS} />
        </aside>
        <div className="flex-1">
          <h3>Уведомления</h3>

          <div className="space-y-4 mt-4">
            <p className="text-sm text-muted-foreground">
              Получайте уведомления в Телеграм
            </p>
            {!editingUser?.telegram?.id ? (
              <div className="flex flex-col items-start">
                <Button size="sm" asChild>
                  <Link
                    target="_blank"
                    to={`https://t.me/${process.env.REACT_APP_TG_BOT_NAME}?start=${editingUser.telegramConnectLink}`}
                  >
                    Подключить
                  </Link>
                </Button>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-between">
                {editingUser.telegram?.username
                  ? `@${editingUser.telegram?.username}`
                  : editingUser.telegram?.id}
                <Button size="sm" onClick={handleDisconnectTelegram}>
                  Отключить
                </Button>
              </div>
            )}
          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 mt-4"
            >
              <FormField
                control={form.control}
                name="notification_method"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-base">
                      Способ получения уведомлений
                    </FormLabel>
                    {NOTIFICATION_METHOD.map(item => (
                      <FormField
                        key={item.id}
                        control={form.control}
                        name="notification_method"
                        render={({ field }) => {
                          return (
                            <FormItem
                              key={item.id}
                              className="flex flex-row items-start space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(item.id)}
                                  disabled={
                                    item.id === 'telegram' &&
                                    !editingUser?.telegram?.id
                                  }
                                  onCheckedChange={checked => {
                                    return checked
                                      ? field.onChange([
                                          ...field.value,
                                          item.id
                                        ])
                                      : field.onChange(
                                          field.value?.filter(
                                            value => value !== item.id
                                          )
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="font-normal">
                                {item.label}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button size="sm" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Сохранение...
                  </>
                ) : (
                  'Сохранить'
                )}
              </Button>

              {error && (
                <p className="text-sm font-medium text-destructive py-0">
                  {error}
                </p>
              )}
            </form>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
