const Loader = () => {
  return (
    <div className="flex">
      <div className="w-2 h-2 m-1 bg-slate-500 rounded-full animate-loader"></div>
      <div className="w-2 h-2 m-1 bg-slate-500 rounded-full animate-loader delay-600"></div>
      <div className="w-2 h-2 m-1 bg-slate-500 rounded-full animate-loader"></div>
    </div>
  );
};

export default Loader;
