import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import InputMask from 'react-input-mask';
import { Loader2 } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/src/components/ui/select';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import Loader from '@/src/components/Loader';
import { useUserStore } from '@/src/store/useUserStore';

const PAGE_URL = '/users';
const USER_DEFAULT_ROLE = 'master';

const formSchema = z.object({
  lastName: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  firstName: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  middleName: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  position: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  type: z.enum(['Руководитель', 'Инженер', 'Специалист'], {
    errorMap: () => ({ message: 'Необходимо указать тип пользователя' })
  }),
  phone: z
    .string()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .refine(value => /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(value), {
      message: 'Поле должно содержать 10 цифр'
    }),
  email: z
    .string()
    .trim()
    .toLowerCase()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .email({
      message: 'Поле должно содержать валидный e-mail'
    }),
  roles: z.enum(['user', 'master', 'customer'])
});

const UserForm = () => {
  const { customerId, userId } = useParams();
  const navigate = useNavigate();

  const isEditing = !!userId;

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  const { getUser, createUser, updateUser, error } = useUserStore(state => ({
    getUser: state.getUser,
    createUser: state.createUser,
    updateUser: state.updateUser,
    error: state.error
  }));

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser(userId);
        setUser(userData);
      } catch (error) {
        console.error('Ошибка при загрузке данных пользователя:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    if (isEditing) {
      fetchUser();
    } else {
      setIsFetchLoading(false);
    }
  }, [getUser, userId, isEditing]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      roles: USER_DEFAULT_ROLE,
      lastName: '',
      firstName: '',
      middleName: '',
      position: '',
      type: '',
      phone: '',
      email: ''
    }
  });

  useEffect(() => {
    if (isEditing && user) {
      const {
        roles,
        lastName,
        firstName,
        middleName,
        position,
        type,
        phone,
        email
      } = user;
      form.setValue('roles', roles[0]);
      form.setValue('lastName', lastName);
      form.setValue('firstName', firstName);
      form.setValue('middleName', middleName);
      form.setValue('position', position);
      form.setValue('type', type);
      form.setValue('phone', phone);
      form.setValue('email', email);
    }
  }, [form, isEditing, user]);

  const onSubmit = async () => {
    const {
      lastName,
      firstName,
      middleName,
      position,
      type,
      phone,
      email,
      roles
    } = form.getValues();
    setIsLoading(true);

    try {
      if (!isEditing) {
        await createUser({
          ...(customerId ? { customer: customerId } : {}),
          roles: [customerId ? 'customer' : roles],
          lastName,
          firstName,
          middleName,
          position,
          type,
          phone,
          email
        });
        setIsLoading(false);

        if (customerId) {
          return navigate(`/customers/${customerId}`);
        }

        if (!error) {
          navigate(PAGE_URL);
        }
      } else {
        await updateUser(userId, {
          lastName,
          firstName,
          middleName,
          position,
          type,
          phone,
          email
        });
        setIsLoading(false);

        if (customerId) {
          return navigate(`/customers/${customerId}`);
        }

        if (!error) {
          navigate(`${PAGE_URL}/${userId}`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchLoading) {
    return (
      <div className="mt-4">
        <Loader />
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
        {!isEditing && (
          <blockquote className="alert">
            После создания пользователя на указанный емейл будет отправлено
            письмо с паролем и ссылкой для активации аккаунта.
          </blockquote>
        )}

        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Фамилия</FormLabel>
              <FormControl>
                <Input placeholder="Иванов" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Имя</FormLabel>
              <FormControl>
                <Input placeholder="Иван" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="middleName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Отчество</FormLabel>
              <FormControl>
                <Input placeholder="Иванович" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="position"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Должность</FormLabel>
              <FormControl>
                <Input
                  placeholder="Начальник отдела технического обслуживания"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Тип пользователя</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger
                    className={!field.value && 'text-muted-foreground'}
                  >
                    <SelectValue placeholder="Выберите тип пользователя" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Руководитель">Руководитель</SelectItem>
                  <SelectItem value="Инженер">Инженер</SelectItem>
                  <SelectItem value="Специалист">Специалист</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        {!isEditing && (
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Телефон</FormLabel>
                <FormControl>
                  <InputMask
                    mask="+7 (999) 999-99-99"
                    placeholder="+7 (900) 000-00-00"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  >
                    {inputProps => <Input {...inputProps} />}
                  </InputMask>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {!isEditing && (
          <FormField
            // disabled={isEditing}
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Почта</FormLabel>
                <FormControl>
                  <Input placeholder="ivanov@example.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* <FormField
          control={form.control}
          name="photo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Фото</FormLabel>
              <FormControl>
                <Input id="picture" type="file" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        /> */}

        <Button size="sm" type="submit" disabled={isLoading}>
          {isEditing ? (
            isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Сохранение...
              </>
            ) : (
              'Сохранить'
            )
          ) : isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Создание...
            </>
          ) : (
            'Создать'
          )}
        </Button>

        {error && (
          <p className="text-sm font-medium text-destructive py-0">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default UserForm;
