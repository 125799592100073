import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import NoteService from '@/src/services/NoteService';

export const useNoteStore = create(
  devtools(
    set => ({
      // note: null,
      offset: 0,
      limit: 10,
      total: 0,
      notes: [],
      isLoading: false,
      error: null,

      createNote: async data => {
        try {
          set({ isLoading: true }, false, 'Create Notes Loading');
          const response = await NoteService.createNote(data);
          set(
            state => ({
              notes: [...state.notes, response.data],
              error: null
            }),
            false,
            'Create Notes'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Create Notes Error'
          );
          throw new Error(`Ошибка при создании записи`);
        } finally {
          set({ isLoading: false }, false, 'Create Notes Loading');
        }
      },

      getNotes: async (offset, limit) => {
        try {
          set({ isLoading: true }, false, 'Get Note Loading');
          // set({ note: null, error: null }, false, 'Clear Get Note');
          const response = await NoteService.getNotes(offset, limit);
          // set({ note: response.data, error: null }, false, 'Get Note');
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Note Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Note Loading');
        }
      },

      getNote: async noteId => {
        try {
          set({ isLoading: true }, false, 'Get Note Loading');
          // set({ note: null, error: null }, false, 'Clear Get Note');
          const response = await NoteService.getNote(noteId);
          // set({ note: response.data, error: null }, false, 'Get Note');
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Note Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Note Loading');
        }
      },

      updateNote: async (id, data) => {
        try {
          set({ isLoading: true }, false, 'Update Note Loading');
          await NoteService.updateNote(id, data);
          set(
            state => ({
              notes: state.notes.map(note => {
                if (note._id === id) {
                  return { ...note, ...data };
                }
                return note;
              }),
              error: null
            }),
            false,
            'Update Note'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Update Note Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Update Note Loading');
        }
      },

      deleteNote: async id => {
        try {
          set({ isLoading: true }, false, 'Delete Note Loading');
          await NoteService.deleteNote(id);
          set(
            state => ({
              notes: state.notes.filter(note => note._id !== id),
              error: null
            }),
            false,
            'Delete Note'
          );
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Delete Note Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Delete Note Loading');
        }
      },

      getNotesForObject: async (objectId, offset, limit) => {
        try {
          set({ isLoading: true }, false, 'Get Notes Loading');
          // set({ notes: [], error: null }, false, 'Clear Get Notes');
          const response = await NoteService.getNotesForObject(
            objectId,
            offset,
            limit
          );
          // set(
          //   {
          //     notes: response.data.notes,
          //     offset: response.data.offset,
          //     limit: response.data.limit,
          //     total: response.data.total,
          //     error: null
          //   },
          //   false,
          //   'Get Notes'
          // );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Notes Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Notes Loading');
        }
      },

      getNotesForUser: async (userId, offset, limit) => {
        try {
          set({ isLoading: true }, false, 'Get Notes Loading');
          // set({ notes: [], error: null }, false, 'Clear Get Notes');
          const response = await NoteService.getNotesForUser(
            userId,
            offset,
            limit
          );
          // set(
          //   {
          //     notes: response.data.notes,
          //     offset: response.data.offset,
          //     limit: response.data.limit,
          //     total: response.data.total,
          //     error: null
          //   },
          //   false,
          //   'Get Notes'
          // );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Notes Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Notes Loading');
        }
      },

      getLatestNotes: async () => {
        try {
          set({ isLoading: true }, false, 'Get Lates Notes Loading');
          const response = await NoteService.getLatestNotes();
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Lates Notes Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Lates Notes Loading');
        }
      },

      getNotesExport: async objectId => {
        try {
          const response = await NoteService.getNotesExport(objectId);
          return response.data;
        } catch (error) {
          console.log('Ошибка при экспорте записей:', error);
        }
      }
    }),
    { name: 'notes-storage' }
  )
);
