import Layout from '@/src/components/Layout';

const Privacy = () => {
  return (
    <Layout>
      <h3>Политика конфиденциальности</h3>
    </Layout>
  );
};

export default Privacy;
