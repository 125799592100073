import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Loader2, CalendarIcon } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/src/components/ui/popover';
import { Checkbox } from '@/src/components/ui/checkbox';
import { Calendar } from '@/src/components/ui/calendar';
import Loader from '@/src/components/Loader';
import { useContractStore } from '@/src/store/useContractStore';

const formSchema = z.object({
  number: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  dateSignature: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  dateStart: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  dateEnd: z.nullable(
    z.string().min(1, {
      message: 'Поле не может быть пустым'
    })
  )
});

const ContractForm = () => {
  const { customerId, contractId } = useParams();
  const navigate = useNavigate();

  const isEditing = !!contractId;

  const [contract, setContract] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  const { getContract, createContract, updateContract, error } =
    useContractStore(state => ({
      getContract: state.getContract,
      createContract: state.createContract,
      updateContract: state.updateContract,
      error: state.error
    }));

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      number: '',
      dateSignature: '',
      dateStart: '',
      dateEnd: '',
      isUnlimited: false
    }
  });

  useEffect(() => {
    const fetchContract = async () => {
      try {
        const contractData = await getContract(contractId);
        setContract(contractData);
      } catch (error) {
        console.error('Ошибка при загрузке данных договора:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    if (isEditing) {
      fetchContract();
    } else {
      setIsFetchLoading(false);
    }
  }, [getContract, contractId, isEditing]);

  useEffect(() => {
    if (isEditing && contract) {
      form.reset({
        ...contract
      });
    }
  }, [form, isEditing, contract]);

  const onSubmit = async () => {
    const formData = form.getValues();
    setIsLoading(true);

    try {
      if (!isEditing) {
        await createContract({
          ...(customerId ? { customerId: customerId } : {}),
          ...formData
        });

        setIsLoading(false);

        return navigate(`/customers/${customerId}`);
      } else {
        await updateContract(contractId, formData);
        setIsLoading(false);

        return navigate(`/customers/${customerId}/contracts/${contractId}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchLoading) {
    return (
      <div className="mt-4">
        <Loader />
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
          <FormField
            control={form.control}
            name="number"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Номер договора</FormLabel>
                <FormControl>
                  <Input placeholder="Номер договора" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="dateSignature"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Дата заключения</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={'outline'}
                        className={
                          ('w-[240px] pl-3 text-left font-normal',
                          !field.value && 'text-muted-foreground')
                        }
                      >
                        {field.value ? (
                          format(parseISO(field.value), 'dd MMMM yyyy', {
                            locale: ru
                          })
                        ) : (
                          <span>Дата заключения</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="single"
                      locale={ru}
                      selected={field.value ? parseISO(field.value) : null}
                      onSelect={date =>
                        field.onChange(date ? format(date, 'yyyy-MM-dd') : null)
                      }
                      disabled={date =>
                        date > new Date() || date < new Date('1900-01-01')
                      }
                      captionLayout="dropdown-buttons"
                      fromYear={2015}
                      toYear={2035}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
          <FormField
            control={form.control}
            name="dateStart"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Дата начала</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={'outline'}
                        className={
                          ('w-[240px] pl-3 text-left font-normal',
                          !field.value && 'text-muted-foreground')
                        }
                      >
                        {field.value ? (
                          format(parseISO(field.value), 'dd MMMM yyyy', {
                            locale: ru
                          })
                        ) : (
                          <span>Дата начала</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="single"
                      locale={ru}
                      selected={field.value ? parseISO(field.value) : null}
                      onSelect={date =>
                        field.onChange(date ? format(date, 'yyyy-MM-dd') : null)
                      }
                      captionLayout="dropdown-buttons"
                      fromYear={2015}
                      toYear={2035}
                      // selected={parseISO(field.value)}
                      // onSelect={field.onChange}
                      // disabled={date =>
                      //   date > new Date() || date < new Date('1900-01-01')
                      // }
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-col space-y-4">
            <FormField
              control={form.control}
              name="dateEnd"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Дата окончания</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={'outline'}
                          disabled={form.watch(`isUnlimited`)}
                          className={
                            ('w-[240px] pl-3 text-left font-normal',
                            !field.value && 'text-muted-foreground')
                          }
                        >
                          {field.value ? (
                            format(parseISO(field.value), 'dd MMMM yyyy', {
                              locale: ru
                            })
                          ) : (
                            <span>Дата окончания</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        initialFocus
                        mode="single"
                        locale={ru}
                        selected={field.value ? parseISO(field.value) : null}
                        onSelect={date =>
                          field.onChange(
                            date ? format(date, 'yyyy-MM-dd') : null
                          )
                        }
                        captionLayout="dropdown-buttons"
                        fromYear={2015}
                        toYear={2035}
                        // selected={parseISO(field.value)}
                        // onSelect={field.onChange}
                        // disabled={date =>
                        //   date > new Date() || date < new Date('1900-01-01')
                        // }
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="isUnlimited"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={value => {
                        field.onChange(value);
                        if (value) {
                          form.setValue('dateEnd', null);
                        } else {
                          form.setValue('dateEnd', '');
                        }
                      }}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Неограниченная пролонгация</FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </div>
        </div>

        <Button size="sm" type="submit" disabled={isLoading}>
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Сохранение...
            </>
          ) : (
            'Сохранить'
          )}
        </Button>

        {error && (
          <p className="text-sm font-medium text-destructive py-0">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default ContractForm;
