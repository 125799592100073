import { useEffect, useState } from 'react';
import {
  Link,
  useParams
  // useNavigate
} from 'react-router-dom';
import { Loader2, Building2, Archive } from 'lucide-react';
import { toast } from 'sonner';
import { Skeleton } from '@/src/components/ui/skeleton';
import { Button } from '@/src/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogTrigger
} from '@/src/components/ui/alert-dialog';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useUserStore } from '@/src/store/useUserStore';
import { useObjectStore } from '@/src/store/useObjectStore';
import NotificationMethods from '../NotificationMethods';

const OBJECTS_LIMIT = 5;

const UserItem = () => {
  const { customerId, userId } = useParams();

  const [userItem, setUserItem] = useState(null);
  const [objects, setObjects] = useState([]);
  const [isChangeActiveLoading, setIsChangeActiveLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalObjects, setTotalObjects] = useState(0);

  const [archivedObjects, setArchivedObjects] = useState([]);
  const [isLoadingMoreArchivedObjects, setIsLoadingMoreArchivedObjects] =
    useState(false);
  const [totalArchivedObjects, setTotalArchivedObjects] = useState(0);
  const [currentArchivedPage, setCurrentArchivedPage] = useState(0);

  const user = useAuthStore(state => state.user);
  const isUser = user?.roles?.includes('user');

  const { getUser, updateUser, error } = useUserStore(state => ({
    getUser: state.getUser,
    updateUser: state.updateUser,
    error: state.error
  }));

  const { getObjectsForUser } = useObjectStore(state => ({
    getObjectsForUser: state.getObjectsForUser
  }));

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser(userId);
        setUserItem(userData);

        let objectsData;
        if (isUser) {
          objectsData = await getObjectsForUser(
            userId,
            currentPage * OBJECTS_LIMIT,
            OBJECTS_LIMIT,
            false
          );

          setObjects(prevObjects => [...prevObjects, ...objectsData.objects]);
          setTotalObjects(objectsData.total);
        }
      } catch (error) {
        console.error('Ошибка при загрузке пользователя:', error);
      } finally {
        setIsFetchLoading(false);
        setIsLoadingMore(false);
      }
    };

    fetchUser();
  }, [isUser, userId, currentPage, getUser, getObjectsForUser]);

  useEffect(() => {
    const fetchArchivedObjectsForUser = async () => {
      try {
        let archivedObjectsData;

        if (isUser) {
          archivedObjectsData = await getObjectsForUser(
            userId,
            currentArchivedPage * OBJECTS_LIMIT,
            OBJECTS_LIMIT,
            true
          );
        }

        setArchivedObjects(prevObjects => [
          ...prevObjects,
          ...archivedObjectsData.objects
        ]);
        setTotalArchivedObjects(archivedObjectsData.total);
      } catch (error) {
        console.error('Ошибка при загрузке архивных объектов:', error);
      } finally {
        setIsFetchLoading(false);
        setIsLoadingMore(false);
      }
    };

    fetchArchivedObjectsForUser();
  }, [isUser, userId, currentArchivedPage, getObjectsForUser]);

  const handleChangeActive = async () => {
    setIsChangeActiveLoading(true);
    try {
      const updatedUser = await updateUser(userId, {
        isActive: !userItem.isActive
      });
      setUserItem(updatedUser);
    } catch (error) {
      console.error('Ошибка при изменении активности пользователя:', error);
    } finally {
      setIsChangeActiveLoading(false);
    }
    {
      userItem.isActive
        ? toast('Пользователь отключен', {
            description: `${userItem.lastName} ${userItem.firstName} ${userItem.middleName}`
          })
        : toast('Пользователь активен', {
            description: `${userItem.lastName} ${userItem.firstName} ${userItem.middleName}`
          });
    }
  };

  const handleLoadMoreObjects = () => {
    setIsLoadingMore(true);
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleLoadMoreArchivedObjects = () => {
    setIsLoadingMoreArchivedObjects(true);
    setCurrentArchivedPage(prevPage => prevPage + 1);
  };

  if (error) {
    return <p className="text-sm font-medium text-destructive py-0">{error}</p>;
  }

  if (isFetchLoading) {
    return (
      <div className="mt-4 grid gap-2">
        <Skeleton className="h-7 w-[450px]" />
        <Skeleton className="h-4 w-[200px]" />
        <div className="mt-4 grid gap-4">
          <Skeleton className="h-4 w-[350px]" />
          <Skeleton className="h-4 w-[350px]" />
          <Skeleton className="h-4 w-[350px]" />
          <Skeleton className="h-4 w-[250px]" />
        </div>
      </div>
    );
  }

  if (userItem.isDeleted) {
    return (
      <>
        <p className="text-4xl text-muted-foreground mt-2">👻</p>
        <p className="text-muted-foreground mt-2">Пользователь удален</p>
      </>
    );
  }

  return (
    <>
      {!userItem.isActive && (
        <blockquote className="alert mb-4">
          <span className="text-base">Пользователь отключен 🙅🏻‍♂️</span>
        </blockquote>
      )}
      <div className="flex flex-col gap-2 items-start justify-between md:flex-row">
        <div>
          <h3>{`${userItem.lastName} ${userItem.firstName} ${userItem.middleName}`}</h3>
          {userItem.type && (
            <p className="text-muted-foreground">{userItem.type}</p>
          )}
        </div>

        {isUser && (
          <div className="flex gap-2">
            <Button size="sm" asChild>
              <Link
                to={
                  customerId
                    ? `/customers/${customerId}/users/${userId}/edit`
                    : `/users/${userId}/edit`
                }
              >
                Редактировать
              </Link>
            </Button>

            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button size="sm" disabled={isChangeActiveLoading}>
                  {isChangeActiveLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {userItem.isActive ? 'Отключение...' : 'Включение...'}
                    </>
                  ) : userItem.isActive ? (
                    'Отключить'
                  ) : (
                    'Включить'
                  )}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle className="border-0">
                    {`Вы уверены, что хотите ${
                      userItem.isActive ? 'отключить' : 'включить'
                    } пользователя?`}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    Изменение активности пользователя не повлияет на оставленные
                    им записи
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Отменить</AlertDialogCancel>
                  <AlertDialogAction onClick={handleChangeActive}>
                    {userItem.isActive ? 'Отключить' : 'Включить'}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        )}
      </div>

      <div className="grid gap-4">
        <div className="mt-4 grid gap-2">
          <p>Должность: {userItem.position}</p>
          <p>Телефон: {userItem.phone}</p>
          <p>Почта: {userItem.email}</p>
          {isUser && (
            <div>
              Уведомления:{' '}
              <NotificationMethods
                notificationMethods={userItem.notification_method}
              />
            </div>
          )}
        </div>

        {objects && objects.length > 0 && (
          <div className="mt-4">
            <h4 className="font-semibold leading-none tracking-tight">
              Объекты
            </h4>
            {isFetchLoading ? (
              <div className="flex items-center space-x-4 mt-5 mb-5">
                <Skeleton className="h-10 w-10 rounded-full" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[200px]" />
                </div>
              </div>
            ) : (
              <>
                <div className="grid gap-6 mt-6">
                  {objects.map(object => (
                    <Link
                      key={object._id}
                      to={`/customers/${object.customer._id}/objects/${object._id}`}
                      className="transition-colors hover:hover:text-gray-500"
                    >
                      <div className="flex items-center space-x-4">
                        <Avatar>
                          <AvatarFallback>
                            {object.isArchived ? <Archive /> : <Building2 />}
                          </AvatarFallback>
                        </Avatar>
                        <div>
                          <p className="text-sm font-medium leading-none">
                            {object.customer.title}
                          </p>
                          <p className="text-sm text-muted-foreground">
                            {object.address.name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>

                {objects.length < totalObjects && (
                  <div className="flex justify-center mt-5">
                    <Button
                      onClick={handleLoadMoreObjects}
                      disabled={isLoadingMore}
                    >
                      {isLoadingMore ? 'Загрузка...' : 'Загрузить еще'}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {archivedObjects.length > 0 && (
          <div className="mt-4">
            <div className="flex flex-row gap-2 items-center justify-between">
              <h4 className="font-semibold leading-none tracking-tight">
                Архив объектов
              </h4>
            </div>

            <div className="grid gap-6 mt-6">
              {archivedObjects.map(object => (
                <Link
                  key={object._id}
                  to={`/customers/${object.customer._id}/objects/${object._id}`}
                  className="transition-colors hover:hover:text-gray-500"
                >
                  <div className="flex items-center space-x-4">
                    <Avatar>
                      <AvatarFallback>
                        {object.isArchived ? <Archive /> : <Building2 />}
                      </AvatarFallback>
                    </Avatar>
                    <div>
                      <p className="text-sm font-medium leading-none">
                        {object.customer.title}
                      </p>
                      <p className="text-sm text-muted-foreground">
                        {object.address.name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            {archivedObjects.length < totalArchivedObjects && (
              <div className="flex justify-center mt-5">
                <Button
                  onClick={handleLoadMoreArchivedObjects}
                  disabled={isLoadingMoreArchivedObjects}
                >
                  {isLoadingMoreArchivedObjects
                    ? 'Загрузка...'
                    : 'Загрузить еще'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserItem;
