import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import ObjectService from '@/src/services/ObjectService';

export const useObjectStore = create(
  devtools(
    set => ({
      // object: null,
      offset: 0,
      limit: 10,
      total: 0,
      objects: [],
      isLoading: false,
      error: null,

      createObject: async data => {
        try {
          set({ isLoading: true }, false, 'Create Object Loading');
          const response = await ObjectService.createObject(data);
          set(
            state => ({
              objects: [...state.objects, response.data],
              error: null
            }),
            false,
            'Create Object'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Create Object Error'
          );
          // throw new Error(`Ошибка при создании объекта`, error);
        } finally {
          set({ isLoading: false }, false, 'Create Object Loading');
        }
      },

      getObjects: async (offset, limit) => {
        try {
          set({ isLoading: true }, false, 'Get Objects Loading');
          set({ objects: [], error: null }, false, 'Clear Get Objects');
          const response = await ObjectService.getObjects(offset, limit);
          set(
            {
              objects: response.data.objects,
              offset: response.data.offset,
              limit: response.data.limit,
              total: response.data.total,
              error: null
            },
            false,
            'Get Objects'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Objects Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Objects Loading');
        }
      },

      getObject: async id => {
        try {
          set({ isLoading: true }, false, 'Get Object Loading');
          // set({ object: null, error: null }, false, 'Clear Get Object');
          const response = await ObjectService.getObject(id);
          // set({ object: response.data, error: null }, false, 'Get Object');
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Object Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Object Loading');
        }
      },

      updateObject: async (id, data) => {
        try {
          set({ isLoading: true }, false, 'Update Object Loading');
          await ObjectService.updateObject(id, data);
          set(
            state => ({
              objects: state.objects.map(object => {
                if (object._id === id) {
                  return { ...object, ...data };
                }
                return object;
              }),
              error: null
            }),
            false,
            'Update Object'
          );
          return response.data;
          // throw new Error(`Ошибка при обновлении объекта`, error);
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Update Object Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Update Object Loading');
        }
      },

      deleteObject: async id => {
        try {
          set({ isLoading: true }, false, 'Delete Object Loading');
          await ObjectService.deleteObject(id);
          set(
            state => ({
              objects: state.objects.filter(object => object._id !== id),
              error: null
            }),
            false,
            'Delete Object'
          );
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Delete Object Error'
          );
          // console.log(error);
          // throw new Error(`Ошибка при удалении объекта`, error);
        } finally {
          set({ isLoading: false }, false, 'Delete Object Loading');
        }
      },

      getObjectsForUser: async (id, offset, limit, isArchived) => {
        try {
          set({ isLoading: true }, false, 'Get Objects For User Loading');
          set({ objects: [], error: null }, false, 'Clear Objects For User');
          const response = await ObjectService.getObjectsForUser(
            id,
            offset,
            limit,
            isArchived
          );
          set(
            {
              objects: response.data.objects,
              offset: response.data.offset,
              limit: response.data.limit,
              total: response.data.total,
              error: null
            },
            false,
            'Get Objects For User'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Objects For User Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Objects For User Loading');
        }
      },

      getObjectsForCustomer: async (id, offset, limit, isArchived) => {
        try {
          set({ isLoading: true }, false, 'Get Objects For Customer Loading');
          set(
            { objects: [], error: null },
            false,
            'Clear Objects For Customer'
          );
          const response = await ObjectService.getObjectsForCustomer(
            id,
            offset,
            limit,
            isArchived
          );
          set(
            {
              objects: response.data.objects,
              offset: response.data.offset,
              limit: response.data.limit,
              total: response.data.total,
              error: null
            },
            false,
            'Get Objects For Customer'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Objects For Customer Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Objects For Customer Loading');
        }
      },

      getObjectsForCustomerAndUser: async (
        customerId,
        userId,
        offset,
        limit,
        isArchived
      ) => {
        try {
          set({ isLoading: true }, false, 'Get Objects For Customer Loading');
          set(
            { objects: [], error: null },
            false,
            'Clear Objects For Customer'
          );
          const response = await ObjectService.getObjectsForCustomerAndUser(
            customerId,
            userId,
            offset,
            limit,
            isArchived
          );
          set(
            {
              objects: response.data.objects,
              offset: response.data.offset,
              limit: response.data.limit,
              total: response.data.total,
              error: null
            },
            false,
            'Get Objects For Customer'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Objects For Customer Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Objects For Customer Loading');
        }
      }
    }),
    { name: 'objects-storage' }
  )
);
