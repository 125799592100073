import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader2, Trash2 } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/src/components/ui/alert-dialog';

const FileItem = ({ file, onDelete }) => {
  const [isDeletedLoading, setIsDeletedLoading] = useState(false);

  const handleDelete = async () => {
    setIsDeletedLoading(true);
    try {
      await onDelete(file._id, file.name);
    } finally {
      setIsDeletedLoading(false);
    }
  };

  return (
    <li key={file._id} className="flex items-center">
      <Link
        to={file.s3Url}
        className="inline-block hover:underline"
        target="_blank"
        rel="noreferrer"
      >
        <span className="flex">
          <span className="mr-2">{file.name}</span>
        </span>
      </Link>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button size="sm" variant="ghost" disabled={isDeletedLoading}>
            {isDeletedLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Удаление...
              </>
            ) : (
              <Trash2 className="w-4 h-4" />
            )}
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="border-0">
              Вы уверены, что хотите удалить файл?
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Отменить</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              Удалить
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </li>
  );
};

export default FileItem;
