import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import CustomerService from '@/src/services/CustomerService';

export const useCustomerStore = create(
  devtools(
    set => ({
      // customer: null,
      customers: [],
      isLoading: false,
      error: null,

      createCustomer: async data => {
        try {
          set({ isLoading: true }, false, 'Create Customer Loading');
          const response = await CustomerService.createCustomer(data);
          set(
            state => ({
              customers: [...state.customers, response.data],
              error: null
            }),
            false,
            'Create Customer'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Create Customer Error'
          );
          // console.log(error);
          // throw new Error(`Ошибка при создании заказчика`, error);
        } finally {
          set({ isLoading: false }, false, 'Create Customer Loading');
        }
      },

      getCustomers: async (offset, limit, search) => {
        try {
          set({ isLoading: true }, false, 'Get Customers Loading');
          set({ customers: [], error: null }, false, 'Clear Get Customers');
          const response = await CustomerService.getCustomers(
            offset,
            limit,
            search
          );
          set(
            {
              customers: response.data.customers,
              error: null
            },
            false,
            'Get Customers'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Customers Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Customers Loading');
        }
      },

      getCustomer: async id => {
        try {
          set({ isLoading: true }, false, 'Get Customer Loading');
          // set({ customer: null, error: null }, false, 'Clear Get Customer');
          const response = await CustomerService.getCustomer(id);
          // set({ customer: response.data, error: null }, false, 'Get Customer');
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Customer Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Customer Loading');
        }
      },

      updateCustomer: async (id, data) => {
        try {
          set({ isLoading: true }, false, 'Update Customer Loading');
          await CustomerService.updateCustomer(id, data);
          set(
            state => ({
              customers: state.customers.map(customer => {
                if (customer._id === id) {
                  return { ...customer, ...data };
                }
                return customer;
              }),
              error: null
            }),
            false,
            'Update Customer'
          );
          // console.log(error);
          // throw new Error(`Ошибка при обновлении заказчика`);
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Update Customer Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Update Customer Loading');
        }
      },

      deleteCustomer: async id => {
        try {
          set({ isLoading: true }, false, 'Delete Customer Loading');
          await CustomerService.deleteCustomer(id);
          set(
            state => ({
              customers: state.customers.filter(
                customer => customer._id !== id
              ),
              error: null
            }),
            false,
            'Delete Customer'
          );
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Delete Customer Error'
          );
          // console.log(error);
          // throw new Error(`Ошибка при удалении заказчика`);
        } finally {
          set({ isLoading: false }, false, 'Delete Customer Loading');
        }
      },

      getCustomersForUser: async (id, offset, limit, search) => {
        try {
          set({ isLoading: true }, false, 'Get Customers For User Loading');
          set(
            { customers: [], error: null },
            false,
            'Clear Customers For User'
          );
          const response = await CustomerService.getCustomersForUser(
            id,
            offset,
            limit,
            search
          );
          set(
            {
              customers: response.data.customers,
              error: null
            },
            false,
            'Get Customers For User'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Customers For User Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Customers For User Loading');
        }
      },

      resetError: () => {
        set({ error: null }, false, 'Reset Error');
      }
    }),
    { name: 'customers-storage' }
  )
);
