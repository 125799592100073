import { Input } from '@/src/components/ui/input';
import useDebounce from '@/src/hooks/useDebounce';
import Loader from '@/src/components/Loader';

export function Search({ onSearch, loading }) {
  const debounceSearch = useDebounce(onSearch, 500);

  const handleSearch = event => {
    const query = event.target.value;
    debounceSearch(query);
  };

  return (
    <div className="mt-4">
      <div className="flex items-center space-x-4">
        <Input
          type="search"
          placeholder="Поиск"
          className="md:w-[350px]"
          onChange={handleSearch}
        />
        {loading && <Loader />}
      </div>
    </div>
  );
}
