import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import UserItem from '@/src/components/user/UserItem';
import { useAuthStore } from '@/src/store/useAuthStore';

const User = () => {
  const { customerId } = useParams();

  const user = useAuthStore(state => state.user);

  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');
  const isCustomer = user?.roles?.includes('customer');

  let breadcrumbItems = [];

  if (!customerId) {
    if (isUser) {
      breadcrumbItems = [
        { text: 'Сотрудники', link: '/users' },
        { text: 'Пользователь', link: null }
      ];
    }
    if (isMaster) {
      breadcrumbItems = [
        { text: 'Сотрудники', link: null },
        { text: 'Пользователь', link: null }
      ];
    }
    if (isCustomer) {
      breadcrumbItems = [
        { text: 'Главная', link: '/' },
        { text: 'Пользователь', link: null }
      ];
    }
  }

  if (customerId) {
    if (isUser) {
      breadcrumbItems = [
        { text: 'Заказчики', link: '/customers' },
        { text: 'Заказчик', link: `/customers/${customerId}` },
        { text: 'Пользователь', link: null }
      ];
    }
    if (isMaster) {
      breadcrumbItems = [
        { text: 'Заказчики', link: '/customers' },
        { text: 'Заказчик', link: `/customers/${customerId}` },
        { text: 'Пользователь', link: null }
      ];
    }
    if (isCustomer) {
      breadcrumbItems = [
        { text: 'Главная', link: '/' },
        { text: 'Пользователь', link: null }
      ];
    }
  }

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <UserItem />
    </Layout>
  );
};

export default User;
