import axios from 'axios';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import AuthService from '@/src/services/AuthService';

export const useAuthStore = create(
  devtools(
    set => ({
      user: {},
      isAuth: false,
      isLoading: false,
      isLoadingAuth: true,
      error: null,

      registration: async (code, email, password) => {
        try {
          set({ isLoading: true }, false, 'Registration Loading');
          const response = await AuthService.registration(
            code,
            email,
            password
          );
          localStorage.setItem('token', response.data.accessToken);
          set(
            { user: response.data.user, isAuth: true, error: null },
            false,
            'Registration'
          );
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Registration Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Registration Loading');
        }
      },

      login: async (email, password) => {
        try {
          set({ isLoading: true }, false, 'Login Loading');
          const response = await AuthService.login(email, password);
          localStorage.setItem('token', response.data.accessToken);
          set(
            { user: response.data.user, isAuth: true, error: null },
            false,
            'Login'
          );
        } catch (error) {
          set({ error: error.response?.data?.message }, false, 'Login Error');
        } finally {
          set({ isLoading: false }, false, 'Login Loading');
        }
      },

      logout: async () => {
        try {
          set({ isLoading: true }, false, 'Logout Loading');
          await AuthService.logout();
          localStorage.removeItem('token');
          set({ user: {}, isAuth: false, error: null }, false, 'Logout');
        } catch (error) {
          set({ error: error.response?.data?.message }, false, 'Logout Error');
        } finally {
          set({ isLoading: false }, false, 'Logout Loading');
        }
      },

      checkAuth: async () => {
        try {
          set({ isLoadingAuth: true }, false, 'Check Auth Loading 1');
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/auth/refresh`,
            { withCredentials: true }
          );
          localStorage.setItem('token', response.data.accessToken);
          set({ user: response.data.user, isAuth: true }, false, 'Check Auth');
        } catch (error) {
          console.log(error.response?.data?.message);
        } finally {
          set({ isLoadingAuth: false }, false, 'Check Auth Loading 2');
        }
      },

      resetError: () => {
        set({ error: null }, false, 'Reset Error');
      }
    }),
    { name: 'auth-storage' }
  )
);
