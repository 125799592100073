import { Link } from 'react-router-dom';
import Layout from '@/src/components/Layout';

const Support = () => {
  return (
    <Layout>
      <h3>Поддержка</h3>

      <p className="mt-4">
        <p className="text-muted-foreground">
          Telegram:{' '}
          <Link
            to="https://t.me/firecrm_help_bot"
            target="_blank"
            className="link"
          >
            firecrm_help_bot
          </Link>
        </p>
        <p className="text-muted-foreground">
          Почта:{' '}
          <Link
            to="mailto:support@fire-crm.ru"
            target="_blank"
            className="link"
          >
            support@fire-crm.ru
          </Link>
        </p>
      </p>
    </Layout>
  );
};

export default Support;
