import { DataTableColumnHeader } from '@/src/components/object/data-table/data-table-column-header';
import { STATUSES, PERIODICITY } from '@/src/constants/objectConstants';

export const columns = [
  {
    accessorKey: 'customer',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Заказчик" />
    ),
    cell: ({ row }) => (
      <div className="max-w-max font-medium md:max-w-[500px] md:font-normal">
        {row.getValue('customer').title}
      </div>
    ),
    filterFn: (row, id, value) => {
      const customerTitle = row.getValue('customer').title;
      return customerTitle.toLowerCase().includes(value.toLowerCase());
    }
  },
  {
    accessorKey: 'address',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Объект" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-max md:max-w-[500px]">
            {row.getValue('address').name}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const addressName = row.getValue('address').name;
      return addressName.toLowerCase().includes(value.toLowerCase());
    }
  },
  {
    accessorKey: 'systems',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Системы" />
    ),
    cell: ({ row }) => {
      const systems = row
        .getValue('systems')
        .map(system => system.label)
        .join(', ');

      return systems;

      // const systems = OBJECT_SYSTEMS.find(
      //   systems => systems.label === row.getValue('systems').label
      // );

      // if (!systems) {
      //   return 'null';
      // }

      // return (
      //   <div className="flex w-[100px] items-center">
      //     {/* {systems.icon && (
      //       <systems.icon className="mr-2 h-4 w-4 text-muted-foreground" />
      //     )} */}
      //     <span>{systems.label}</span>
      //   </div>
      // );
    },
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'service_intervals',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Периодичность" />
    ),
    cell: ({ row }) => {
      // const serviceIntervals = row.getValue('service_intervals');
      // return `${serviceIntervals}`;

      const periodicity = PERIODICITY.find(
        periodicity => periodicity.value === row.getValue('service_intervals')
      );

      if (!periodicity) {
        return null;
      }

      return (
        <div className="flex items-center">
          {periodicity.icon && (
            <periodicity.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{periodicity.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Статус" />
    ),
    cell: ({ row }) => {
      const status = STATUSES.find(
        status => status.value === row.getValue('status')
      );

      if (!status) {
        return null;
      }

      return (
        <div className="flex flex-row-reverse justify-end items-center md:flex-row md:justify-start">
          {status.icon && (
            <status.icon
              className={`ml-2 mr-0 h-4 w-4 text-muted-foreground md:mr-2 md:ml-0 ${
                status.color && status.color
              }`}
            />
          )}
          <span className="whitespace-nowrap">{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  }
];
