import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loader2, Eye, EyeOff } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import { useAuthStore } from '@/src/store/useAuthStore';

const formSchema = z.object({
  email: z
    .string()
    .trim()
    .toLowerCase()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .email({
      message: 'Поле должно содержать валидный e-mail'
    }),
  password: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  })
});

const LoginForm = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const { isAuth, isLoading, login, resetError, error } = useAuthStore(
    state => ({
      isAuth: state.isAuth,
      isLoading: state.isLoading,
      login: state.login,
      resetError: state.resetError,
      error: state.error
    })
  );

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
  }, [isAuth]);

  useEffect(() => {
    resetError();
  }, []);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = async ({ email, password }) => {
    login(email, password);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Почта</FormLabel>
              <FormControl>
                <Input
                  // autoFocus={true}
                  placeholder="ivanov@example.com"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center justify-between">
                <FormLabel>Пароль</FormLabel>
                <div className="text-sm">
                  <Link
                    to={'/forgot-password'}
                    className={
                      'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-700'
                    }
                  >
                    Забыли пароль?
                  </Link>
                </div>
              </div>
              <div className="relative">
                <FormControl>
                  <Input
                    className="pr-[34px]"
                    type={showPassword ? 'text' : 'password'}
                    {...field}
                    onInput={e => {
                      e.target.value = e.target.value.replace(/\s/g, ''); // Удаляем пробелы
                    }}
                  />
                </FormControl>
                <span
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="w-5 h-5 stroke-slate-600" />
                  ) : (
                    <Eye className="w-5 h-5 stroke-slate-600" />
                  )}
                </span>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        {isLoading ? (
          <Button className="w-full" disabled>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          </Button>
        ) : (
          <Button type="submit" className="w-full">
            Войти
          </Button>
        )}
      </form>

      {error && (
        <p className="text-sm font-medium text-destructive text-center px-6 py-0">
          {error}
        </p>
      )}

      <p className="text-center muted px-6 py-0">
        Нет аккаунта?{' '}
        <Link to="/registration" className="link">
          Зарегистрируйтесь
        </Link>
      </p>
    </Form>
  );
};

export default LoginForm;
