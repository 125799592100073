const NotificationMethods = ({ notificationMethods }) => {
  const labels = notificationMethods.map(method => {
    switch (method) {
      case 'email':
        return 'E-mail';
      case 'telegram':
        return 'Телеграм';
      default:
        return 'Неизвестный метод';
    }
  });

  return <>{labels.join(', ')}</>;
};

export default NotificationMethods;
