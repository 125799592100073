import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import UserForm from '@/src/components/user/UserForm';

const EditUser = () => {
  const { userId, customerId } = useParams();

  let breadcrumbItems = [];

  if (customerId) {
    breadcrumbItems = [
      { text: 'Заказчики', link: '/customers' },
      { text: 'Заказчик', link: `/customers/${customerId}` },
      {
        text: 'Пользователь',
        link: `/customers/${customerId}/users/${userId}`
      },
      { text: 'Редактирование', link: null }
    ];
  }

  if (!customerId) {
    breadcrumbItems = [
      { text: 'Сотрудники', link: '/users' },
      { text: 'Пользователь', link: `/users/${userId}` },
      { text: 'Редактирование', link: null }
    ];
  }

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Редактировать пользователя</h3>

      <UserForm />
    </Layout>
  );
};

export default EditUser;
