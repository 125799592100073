import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Loader2, ExternalLink, Info } from 'lucide-react';
import { Skeleton } from '@/src/components/ui/skeleton';
import { Button } from '@/src/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/src/components/ui/popover';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/src/components/ui/alert-dialog';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useNoteStore } from '@/src/store/useNoteStore';
import { useObjectStore } from '@/src/store/useObjectStore';
import { useCustomerStore } from '@/src/store/useCustomerStore';
import { formatDate, formatTime } from '@/src/utils/utils';

const NoteItem = () => {
  const { customerId, objectId, noteId } = useParams();

  const [note, setNote] = useState(null);
  const [object, setObject] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isDeletedLoading, setIsDeletedLoading] = useState(false);

  const navigate = useNavigate();

  const user = useAuthStore(state => state.user);

  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');
  // const isCustomer = user?.roles?.includes('customer');

  const { getNote, deleteNote, error } = useNoteStore(state => ({
    getNote: state.getNote,
    deleteNote: state.deleteNote,
    error: state.error
  }));

  const { getObject } = useObjectStore(state => ({
    getObject: state.getObject
  }));

  const { getCustomer } = useCustomerStore(state => ({
    getCustomer: state.getCustomer
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const noteData = await getNote(noteId);
        setNote(noteData);

        const objectData = await getObject(objectId);
        setObject(objectData);

        const customerData = await getCustomer(customerId);
        setCustomer(customerData);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchData();
  }, [getNote, getObject, getCustomer, noteId, objectId, customerId]);

  const handleDelete = async () => {
    setIsDeletedLoading(true);
    try {
      const deletedNote = await deleteNote(noteId);
      setNote(deletedNote);
    } catch (error) {
      console.error('Ошибка при удалении записи:', error);
    } finally {
      setIsDeletedLoading(false);
    }

    if (customerId) {
      navigate(`/customers/${customerId}/objects/${objectId}`);
    }
  };

  const isEditable = () => {
    // Получаем текущую дату и время
    const currentDate = new Date();
    const currentDateString = formatDate(currentDate);

    // Получаем дату и время создания записи
    const noteCreationDate = new Date(note.createdAt);
    const noteCreationDateString = formatDate(noteCreationDate);

    // Сравниваем даты
    const currentDateIsAfterCreation = currentDate > noteCreationDate;

    // Проверяем, разрешено ли редактирование до конца текущего дня
    const editingAllowed =
      currentDateIsAfterCreation &&
      currentDateString === noteCreationDateString;

    return editingAllowed;
  };

  if (error) {
    return <p className="text-sm font-medium text-destructive py-0">{error}</p>;
  }

  if (isFetchLoading) {
    return (
      <div className="mt-4 grid gap-2">
        <Skeleton className="h-7 w-[450px]" />
        <div className="mt-4 grid gap-4">
          <Skeleton className="h-4 w-[350px]" />
          <Skeleton className="h-4 w-[350px]" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-2 items-start justify-between md:flex-row">
        <h3>
          {formatDate(note.createdAt)}{' '}
          <span className="text-muted-foreground">
            в {formatTime(note.createdAt)}
          </span>
        </h3>
        {(user.id === note.master._id || isUser) && isEditable() && (
          <div className="flex gap-2">
            <Popover>
              <PopoverTrigger>
                <Info className="w-4 h-4" />
              </PopoverTrigger>
              <PopoverContent>
                <p className="text-sm">
                  Редакитрование записи возможно только до конца текущего дня
                </p>
              </PopoverContent>
            </Popover>
            <Button size="sm" asChild>
              <Link
                to={`/customers/${customerId}/objects/${objectId}/notes/${noteId}/edit`}
              >
                Редактировать
              </Link>
            </Button>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button size="sm" disabled={isDeletedLoading}>
                  {isDeletedLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Удаление...
                    </>
                  ) : (
                    'Удалить'
                  )}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle className="border-0">
                    Вы уверены, что хотите удалить запись?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Отменить</AlertDialogCancel>
                  <AlertDialogAction onClick={handleDelete}>
                    Удалить
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        )}
      </div>

      <div className="grid gap-4">
        <div className="mt-4 grid gap-2">
          {(isUser || isMaster) && customer && (
            <p>
              Заказчик:{' '}
              <Link to={`/customers/${customerId}`} className="hover:underline">
                {customer.title}
              </Link>
            </p>
          )}
          {object && (
            <p>
              Объект:{' '}
              <Link
                to={`/customers/${customerId}/objects/${objectId}`}
                className="hover:underline"
              >
                {object.address.name}
              </Link>
            </p>
          )}
          {note.master.lastName && (
            <p>
              Ответственный:{' '}
              <Link
                to={`/users/${note.master._id}`}
                className="hover:underline"
              >
                {`${note.master.lastName} ${note.master.firstName} ${note.master.middleName}`}
              </Link>
            </p>
          )}
          <p>Система: {note.system}</p>

          <div className="mt-4 grid gap-2">
            <h4 className="text-lg font-semibold leading-none tracking-tight">
              Наименование установки
            </h4>
            <p>{note.name}</p>
          </div>

          <div className="mt-4 grid gap-2">
            <h4 className="text-lg font-semibold leading-none tracking-tight">
              Вид работ
            </h4>
            <p>{note.result}</p>
          </div>

          {note.files.length > 0 && (
            <div>
              <h4 className="text-lg font-semibold leading-none tracking-tight">
                Фото
              </h4>
              <ul className="my-2 ml-6 list-disc [&>li]:mt-2">
                {note.files.map(file => (
                  <li key={file._id}>
                    <Link
                      to={file.s3Url}
                      className="inline-block hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="flex">
                        <span className="mr-2">{file.name}</span>
                        <ExternalLink className="w-4 h-4" />
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {note.complex_test && (
            <div className="mt-4 grid gap-2">
              <h4 className="text-lg font-semibold leading-none tracking-tight">
                Результат комплексного испытания
              </h4>

              <p>{note.complex_test_result}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NoteItem;
