import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Loader2, User, XCircle, ExternalLink } from 'lucide-react';
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  FullscreenControl
} from '@pbe/react-yandex-maps';
import { Skeleton } from '@/src/components/ui/skeleton';
import { Button } from '@/src/components/ui/button';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/src/components/ui/accordion';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/src/components/ui/alert-dialog';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import { DataTable } from '@/src/components/note/data-table/data-table';
import { columns } from '@/src/components/note/data-table/object-notes-columns';
import ContractList from '../contract/ContractList';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useUserStore } from '@/src/store/useUserStore';
import { useObjectStore } from '@/src/store/useObjectStore';
import { useNoteStore } from '@/src/store/useNoteStore';
import {
  DEFAULT_MOSCOW_LATITUDE,
  DEFAULT_MOSCOW_LONGITUDE
} from '@/src/constants/objectConstants';
import { useContractStore } from '@/src/store/useContractStore';

const ObjectItem = () => {
  const { customerId, objectId } = useParams();

  const [object, setObject] = useState(null);
  const [users, setUsers] = useState([]);
  const [contract, setContract] = useState([]);
  const [notes, setNotes] = useState(null);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isArchivedLoading, setIsArchivedLoading] = useState(false);
  const [isDeletedLoading, setIsDeletedLoading] = useState(false);
  const [isExpotrLoading, setIsExpotrLoading] = useState(false);

  const navigate = useNavigate();

  const link = customerId ? `/customers/${customerId}` : '';

  const sortedUsers = [...users]?.sort((a, b) =>
    a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1
  );

  const user = useAuthStore(state => state.user);

  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');

  const { getObject, updateObject, deleteObject, error } = useObjectStore(
    state => ({
      getObject: state.getObject,
      updateObject: state.updateObject,
      deleteObject: state.deleteObject,
      error: state.error
    })
  );

  const { getContract } = useContractStore(state => ({
    getContract: state.getContract
  }));

  const { getUsersForObject } = useUserStore(state => ({
    getUsersForObject: state.getUsersForObject
  }));

  const { getNotesForObject, getNotesExport } = useNoteStore(state => ({
    getNotesForObject: state.getNotesForObject,
    getNotesExport: state.getNotesExport
  }));

  useEffect(() => {
    const fetchObject = async () => {
      try {
        const objectData = await getObject(objectId);
        setObject(objectData);

        if (objectData.contract) {
          const contractData = await getContract(objectData.contract);
          setContract([contractData]);
        }

        const usersData = await getUsersForObject(objectId);
        setUsers(usersData);

        const notesData = await getNotesForObject(objectId);
        setNotes(notesData.notes);
      } catch (error) {
        console.error('Ошибка при загрузке объекта:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchObject();
  }, [getObject, objectId]);

  const handleArchive = async value => {
    setIsArchivedLoading(true);

    try {
      const updatedObject = await updateObject(objectId, { isArchived: value });
      setObject(updatedObject);
    } catch (error) {
      console.error('Ошибка при архивировании объекта:', error);
    } finally {
      setIsArchivedLoading(false);
    }

    navigate(`${link}`);
  };

  const handleDelete = async () => {
    setIsDeletedLoading(true);
    try {
      const deletedObject = await deleteObject(objectId);
      setObject(deletedObject);
    } catch (error) {
      console.error('Ошибка при удалении объекта:', error);
    } finally {
      setIsDeletedLoading(false);
    }

    navigate(`${link}`);
  };

  const handleExport = async () => {
    setIsExpotrLoading(true);
    try {
      const response = await getNotesExport(objectId);
      response && window.open(response, '_blank');
    } catch (error) {
      console.error('Ошибка при экспорте записей:', error);
    } finally {
      setIsExpotrLoading(false);
    }
  };

  if (error) {
    return <p className="text-sm font-medium text-destructive py-0">{error}</p>;
  }

  if (isFetchLoading) {
    return (
      <div className="mt-4 grid gap-2">
        <Skeleton className="h-7 w-[450px]" />
        <Skeleton className="h-4 w-[200px]" />
        <div className="mt-4 grid gap-4">
          <Skeleton className="h-4 w-[350px]" />
          <Skeleton className="h-4 w-[350px]" />
          <Skeleton className="h-4 w-[350px]" />
          <Skeleton className="h-4 w-[250px]" />
        </div>
      </div>
    );
  }

  return (
    <>
      {object.isArchived && (
        <blockquote className="alert mb-4">
          <span className="text-base">Объект в архиве 🗄</span>
        </blockquote>
      )}
      <div className="flex flex-col gap-2 items-start justify-between md:flex-row">
        <div>
          <h3>{object.address.name}</h3>
          {object.description && (
            <p className="text-muted-foreground">{object.description}</p>
          )}
        </div>

        <div className="w-full md:w-auto">
          <div className="overflow-auto whitespace-nowrap">
            <div className="inline-flex space-x-2">
              <Button
                size="sm"
                className="whitespace-nowrap"
                disabled={isExpotrLoading || !notes.length}
                onClick={handleExport}
              >
                {isExpotrLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Экспорт журнала...
                  </>
                ) : (
                  'Скачать журнал'
                )}
              </Button>

              {isUser && (
                <>
                  {!object.isArchived ? (
                    <>
                      <Button size="sm" asChild>
                        <Link to={`${link}/objects/${objectId}/edit`}>
                          Редактировать
                        </Link>
                      </Button>

                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button size="sm" disabled={isArchivedLoading}>
                            {isArchivedLoading ? (
                              <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Архивирование...
                              </>
                            ) : (
                              'В архив'
                            )}
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle className="border-0">
                              Вы уверены, что хотите отправить объект в архив?
                            </AlertDialogTitle>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Отменить</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() => handleArchive(true)}
                            >
                              В архив
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </>
                  ) : (
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button size="sm" disabled={isArchivedLoading}>
                          {isArchivedLoading ? (
                            <>
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                              Разархивирование...
                            </>
                          ) : (
                            'Из архива'
                          )}
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle className="border-0">
                            Вы уверены, что хотите вернуть объект из архива?
                          </AlertDialogTitle>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Отменить</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => handleArchive(false)}
                          >
                            Из архива
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  )}

                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button size="sm" disabled={isDeletedLoading}>
                        {isDeletedLoading ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Удаление...
                          </>
                        ) : (
                          'Удалить'
                        )}
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle className="border-0">
                          Вы уверены, что хотите удалить объект?
                        </AlertDialogTitle>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Отменить</AlertDialogCancel>
                        <AlertDialogAction onClick={handleDelete}>
                          Удалить
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-col space-y-4">
        <div className="mt-4">
          <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_MAP_API_KEY }}>
            <Map
              className="w-full h-[300px]"
              defaultState={{
                center: [
                  object.address.coordinates?.latitude ||
                    DEFAULT_MOSCOW_LATITUDE,
                  object.address.coordinates?.longitude ||
                    DEFAULT_MOSCOW_LONGITUDE
                ],
                zoom: 15,
                controls: []
              }}
            >
              <ZoomControl />
              <FullscreenControl />
              {object.address.coordinates && (
                <Placemark
                  geometry={[
                    object.address.coordinates?.latitude,
                    object.address.coordinates?.longitude
                  ]}
                />
              )}
            </Map>
          </YMaps>
        </div>

        <div className="mt-4 grid gap-2">
          <p>Этажность: {object.floor}</p>
          <p>Площадь: {object.area} м²</p>
          <p>Типы помещения: {object.type}</p>
          <p>Степень огнестойкости: {object.fire_resistance}</p>
          <p>Класс конструктивной пожарной опасности: {object.kkpo}</p>
          <p>Класс функциональной пожарной опасности: {object.kfpo}</p>
          <p>Периодичность обслуживания: {object.service_intervals}</p>
          {object.files.length > 0 && (
            <div>
              Планировки объекта с экспликацией помещения:
              <ul className="my-2 ml-6 list-disc [&>li]:mt-2">
                {object.files.map(file => (
                  <li key={file._id}>
                    <Link
                      to={file.s3Url}
                      className="inline-block hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="flex">
                        <span className="mr-2">{file.name}</span>
                        <ExternalLink className="w-4 h-4" />
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {object.systems.length > 0 && <p>Обслуживаемые системы: </p>}

          <Accordion type="single" collapsible className="w-full">
            {object?.systems_list.sps && (
              <AccordionItem value="item-1">
                <AccordionTrigger className="text-lg font-semibold">
                  СПС
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.sps.sps_type && (
                    <p>Тип системы: {object?.systems_list.sps.sps_type}</p>
                  )}
                  {object?.systems_list.sps.sps_manufacturer && (
                    <p>
                      Производитель: {object?.systems_list.sps.sps_manufacturer}
                    </p>
                  )}
                  {object?.systems_list.sps.sps_manufacturer_other && (
                    <p>
                      Производитель:{' '}
                      {object?.systems_list.sps.sps_manufacturer_other}
                    </p>
                  )}
                  {object?.systems_list.sps.sps_start_year && (
                    <p>
                      Год ввода в эксплуатацию:{' '}
                      {object?.systems_list.sps.sps_start_year}
                    </p>
                  )}
                  {(isUser || isMaster) &&
                    object?.systems_list.sps.sps_status && (
                      <p>
                        Работоспособность: {object?.systems_list.sps.sps_status}
                      </p>
                    )}
                </AccordionContent>
              </AccordionItem>
            )}

            {object?.systems_list.soue && (
              <AccordionItem value="item-2">
                <AccordionTrigger className="text-lg font-semibold">
                  СОУЭ
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.soue.soue_type && (
                    <p>Тип системы: {object?.systems_list.soue.soue_type}</p>
                  )}
                  {object?.systems_list.soue.soue_manufacturer && (
                    <p>
                      Производитель:{' '}
                      {object?.systems_list.soue.soue_manufacturer}
                    </p>
                  )}
                  {object?.systems_list.soue.soue_manufacturer_other && (
                    <p>
                      Производитель:{' '}
                      {object?.systems_list.soue.soue_manufacturer_other}
                    </p>
                  )}
                  {object?.systems_list.soue.soue_start_year && (
                    <p>
                      Год ввода в эксплуатацию:{' '}
                      {object?.systems_list.soue.soue_start_year}
                    </p>
                  )}
                  {(isUser || isMaster) &&
                    object?.systems_list.soue.soue_status && (
                      <p>
                        Работоспособность:{' '}
                        {object?.systems_list.soue.soue_status}
                      </p>
                    )}
                </AccordionContent>
              </AccordionItem>
            )}

            {object?.systems_list.aup.length > 0 && (
              <AccordionItem value="item-3">
                <AccordionTrigger className="text-lg font-semibold">
                  АУП
                </AccordionTrigger>
                {object?.systems_list.aup.map((item, index) => (
                  <AccordionContent key={index}>
                    {item.aup_name && (
                      <p>Наименование системы: {item.aup_name}</p>
                    )}
                    {item.aup_type && <p>Тип системы: {item.aup_type}</p>}
                    {item.aup_type_water_system && (
                      <p>Тип водяной установки: {item.aup_type_water_system}</p>
                    )}
                    {item.aup_substance && (
                      <p>Газовое огнетушащее вещество: {item.aup_substance}</p>
                    )}
                    {item.aup_inspection_date && (
                      <p>
                        Дата последнего освидетельствования баллонов:{' '}
                        {item.aup_inspection_date}
                      </p>
                    )}
                    {item.aup_manufacturer && (
                      <p>Производитель: {item.aup_manufacturer}</p>
                    )}
                    {item.aup_module_manufacturer && (
                      <p>
                        Модули порошкового пожаротушения (производитель):{' '}
                        {item.aup_module_manufacturer}
                      </p>
                    )}
                    {item.aup_start_year && (
                      <p>Год ввода в эксплуатацию: {item.aup_start_year}</p>
                    )}
                    {(isUser || isMaster) && item.aup_status && (
                      <p>Работоспособность: {item.aup_status}</p>
                    )}
                  </AccordionContent>
                ))}
              </AccordionItem>
            )}

            {object?.systems_list.vpv && (
              <AccordionItem value="item-4">
                <AccordionTrigger className="text-lg font-semibold">
                  ВПВ
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.vpv.vpv_type && (
                    <p>Тип системы: {object?.systems_list.vpv.vpv_type}</p>
                  )}
                  {object?.systems_list.vpv.vpv_manufacturer && (
                    <p>
                      Производитель: {object?.systems_list.vpv.vpv_manufacturer}
                    </p>
                  )}
                  {object?.systems_list.vpv.vpv_start_year && (
                    <p>
                      Год ввода в эксплуатацию:{' '}
                      {object?.systems_list.vpv.vpv_start_year}
                    </p>
                  )}
                  {(isUser || isMaster) &&
                    object?.systems_list.vpv.vpv_status && (
                      <p>
                        Работоспособность: {object?.systems_list.vpv.vpv_status}
                      </p>
                    )}
                </AccordionContent>
              </AccordionItem>
            )}

            {object?.systems_list.spdv.length > 0 && (
              <AccordionItem value="item-5">
                <AccordionTrigger className="text-lg font-semibold">
                  СПДВ
                </AccordionTrigger>
                {object?.systems_list.spdv.map((item, index) => (
                  <AccordionContent key={index}>
                    {item.spdv_name && (
                      <p>Наименование системы: {item.spdv_name}</p>
                    )}
                    {item.spdv_description && (
                      <p>Описание системы: {item.spdv_description}</p>
                    )}
                    {item.spdv_type && <p>Тип системы: {item.spdv_type}</p>}
                    {item.spdv_type_value && (
                      <p>
                        Тип вытяжной противодымной вентиляции:{' '}
                        {item.spdv_type_value}
                      </p>
                    )}
                    {item.spdv_manufacturer && (
                      <p>Производитель: {item.spdv_manufacturer}</p>
                    )}
                    {item.spdv_start_year && (
                      <p>Год ввода в эксплуатацию: {item.spdv_start_year}</p>
                    )}
                    {(isUser || isMaster) && item.spdv_status && (
                      <p>Работоспособность: {item.spdv_status}</p>
                    )}
                  </AccordionContent>
                ))}
              </AccordionItem>
            )}

            {object?.systems_list.os && (
              <AccordionItem value="item-6">
                <AccordionTrigger className="text-lg font-semibold">
                  ОС
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.os.os_type && (
                    <p>Тип системы: {object?.systems_list.os.os_type}</p>
                  )}
                  {object?.systems_list.os.os_manufacturer && (
                    <p>
                      Производитель: {object?.systems_list.os.os_manufacturer}
                    </p>
                  )}
                  {object?.systems_list.os.os_manufacturer_other && (
                    <p>
                      Производитель:{' '}
                      {object?.systems_list.os.os_manufacturer_other}
                    </p>
                  )}
                  {object?.systems_list.os.os_start_year && (
                    <p>
                      Год ввода в эксплуатацию:{' '}
                      {object?.systems_list.os.os_start_year}
                    </p>
                  )}
                  {(isUser || isMaster) &&
                    object?.systems_list.os.os_status && (
                      <p>
                        Работоспособность: {object?.systems_list.os.os_status}
                      </p>
                    )}
                </AccordionContent>
              </AccordionItem>
            )}

            {object?.systems_list.skud && (
              <AccordionItem value="item-7">
                <AccordionTrigger className="text-lg font-semibold">
                  СКУД
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.skud.skud_type && (
                    <p>Тип системы: {object?.systems_list.skud.skud_type}</p>
                  )}
                  {object?.systems_list.skud.skud_manufacturer && (
                    <p>
                      Производитель:{' '}
                      {object?.systems_list.skud.skud_manufacturer}
                    </p>
                  )}
                  {object?.systems_list.skud.skud_manufacturer_other && (
                    <p>
                      Производитель:{' '}
                      {object?.systems_list.skud.skud_manufacturer_other}
                    </p>
                  )}
                  {object?.systems_list.skud.skud_start_year && (
                    <p>
                      Год ввода в эксплуатацию:{' '}
                      {object?.systems_list.skud.skud_start_year}
                    </p>
                  )}
                  {(isUser || isMaster) &&
                    object?.systems_list.skud.skud_status && (
                      <p>
                        Работоспособность:{' '}
                        {object?.systems_list.skud.skud_status}
                      </p>
                    )}
                </AccordionContent>
              </AccordionItem>
            )}

            {object?.systems_list.sot && (
              <AccordionItem value="item-8">
                <AccordionTrigger className="text-lg font-semibold">
                  СОТ
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.sot.sot_type && (
                    <p>Тип системы: {object?.systems_list.sot.sot_type}</p>
                  )}
                  {object?.systems_list.sot.sot_manufacturer_camera && (
                    <p>
                      Производитель камер:{' '}
                      {object?.systems_list.sot.sot_manufacturer_camera}
                    </p>
                  )}
                  {object?.systems_list.sot.sot_manufacturer_dvr && (
                    <p>
                      Производитель видеорегистратора:{' '}
                      {object?.systems_list.sot.sot_manufacturer_dvr}
                    </p>
                  )}
                  {object?.systems_list.sot.sot_archive_depth && (
                    <p>
                      Глубина архива:{' '}
                      {object?.systems_list.sot.sot_archive_depth}
                    </p>
                  )}
                  {object?.systems_list.sot.sot_number_of_cameras && (
                    <p>
                      Количество камер:{' '}
                      {object?.systems_list.sot.sot_number_of_cameras}
                    </p>
                  )}
                  {object?.systems_list.sot.sot_remote_access && (
                    <p>
                      Удаленный доступ:{' '}
                      {object?.systems_list.sot.sot_remote_access && 'Да'}
                    </p>
                  )}
                </AccordionContent>
              </AccordionItem>
            )}

            {object?.systems_list.ao && (
              <AccordionItem value="item-9">
                <AccordionTrigger className="text-lg font-semibold">
                  АО
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.ao.ao_type && (
                    <p>Тип системы: {object?.systems_list.ao.ao_type}</p>
                  )}
                  {object?.systems_list.ao.ao_number_of_luminaires && (
                    <p>
                      Количество светильников:{' '}
                      {object?.systems_list.ao.ao_number_of_luminaires}
                    </p>
                  )}
                  {object?.systems_list.ao.ao_dgu_availability && (
                    <p>
                      Наличие ДГУ:{' '}
                      {object?.systems_list.ao.ao_dgu_availability && 'Да'}
                    </p>
                  )}
                  {object?.systems_list.ao.ao_battery_power && (
                    <p>
                      Питание от аккумулятора:{' '}
                      {object?.systems_list.ao.ao_battery_power && 'Да'}
                    </p>
                  )}
                </AccordionContent>
              </AccordionItem>
            )}

            {object?.systems_list.pak && (
              <AccordionItem value="item-7">
                <AccordionTrigger className="text-lg font-semibold">
                  Стрелец-Мониторинг
                </AccordionTrigger>
                <AccordionContent>
                  {object?.systems_list.pak.pak_start_year && (
                    <p>
                      Год ввода в эксплуатацию:{' '}
                      {object?.systems_list.pak.pak_start_year}
                    </p>
                  )}
                  {(isUser || isMaster) &&
                    object?.systems_list.pak.pak_status && (
                      <p>
                        Работоспособность: {object?.systems_list.pak.pak_status}
                      </p>
                    )}
                </AccordionContent>
              </AccordionItem>
            )}
          </Accordion>
        </div>

        <div className="mt-4">
          <div className="flex flex-row gap-2 items-center justify-between h-9">
            <h4 className="font-semibold leading-none tracking-tight">
              Договор
            </h4>
          </div>

          {contract.length > 0 && <ContractList data={contract} />}
        </div>

        <div className="mt-4">
          <div className="flex flex-row gap-2 items-center justify-between h-9">
            <h4 className="font-semibold leading-none tracking-tight">
              Пользователи
            </h4>
          </div>

          {sortedUsers.length > 0 ? (
            <div className="grid gap-4 mt-4">
              {sortedUsers.map(user => {
                let linkUrl = '';
                if (user.roles.includes('customer')) {
                  linkUrl = `/customers/${user.customer}/users/${user.id}`;
                } else if (
                  user.roles.includes('user') ||
                  user.roles.includes('master')
                ) {
                  linkUrl = `/users/${user.id}`;
                }
                return (
                  <Link
                    key={user.id}
                    to={linkUrl}
                    className="transition-colors hover:hover:text-gray-500"
                  >
                    <div className="flex items-center space-x-4">
                      <Avatar>
                        <AvatarFallback>
                          {user.isActive ? <User /> : <XCircle />}
                        </AvatarFallback>
                      </Avatar>
                      <div>
                        {user.isActive ? (
                          <p className="text-sm font-medium leading-none">
                            {`${user.lastName} ${user.firstName} ${user.middleName}`}
                          </p>
                        ) : (
                          <p className="text-sm text-muted-foreground">
                            {`${user.lastName} ${user.firstName} ${user.middleName}`}
                          </p>
                        )}
                        <p className="text-sm text-muted-foreground">
                          {user.position}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <p className="muted mt-2">Нет пользователей</p>
          )}
        </div>

        <div className="mt-4">
          <div className="flex flex-row gap-2 items-center justify-between h-9">
            <h4 className="font-semibold leading-none tracking-tight">
              Записи
            </h4>
            {!object.isArchived && (
              <div>
                <Button size="sm" asChild>
                  <Link to={`${link}/objects/${objectId}/notes/create`}>
                    Добавить
                  </Link>
                </Button>
              </div>
            )}
          </div>

          <div className="mt-2">
            {notes?.length > 0 ? (
              isFetchLoading ? (
                <div className="flex flex-col space-y-7 mt-5 mb-5">
                  <Skeleton className="w-full h-[28px] rounded-lg" />
                  <Skeleton className="w-full h-[24px] rounded-lg" />
                  <Skeleton className="w-full h-[24px] rounded-lg" />
                  <Skeleton className="w-full h-[24px] rounded-lg" />
                </div>
              ) : (
                <DataTable columns={columns} data={notes} toolbar={false} />
              )
            ) : (
              <p className="muted">Нет записей</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ObjectItem;
