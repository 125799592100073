import { Link } from 'react-router-dom';
import { Building2, Archive } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import { Card, CardContent } from '@/src/components/ui/card';

const ObjectList = ({ data }) => {
  return (
    <div className="flex flex-col space-y-2">
      {data && data.length > 0 ? (
        data.map(object => (
          <Card key={object._id} className="mt-4">
            <Link
              to={`/customers/${object.customer}/objects/${object._id}`}
              className="transition-colors hover:hover:text-gray-500"
            >
              <CardContent className="grid gap-6 p-6">
                <div className="flex items-center space-x-4">
                  <Avatar>
                    <AvatarFallback>
                      {object.isArchived ? <Archive /> : <Building2 />}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="text-sm font-medium leading-none">
                      {object.address.name}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {object.description}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Link>
          </Card>
        ))
      ) : (
        <p className="muted">Нет доступных объектов</p>
      )}
    </div>
  );
};

export default ObjectList;
