import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import ContractService from '@/src/services/ContractService';

export const useContractStore = create(
  devtools(
    set => ({
      // contract: null,
      offset: 0,
      limit: 10,
      total: 0,
      contracts: [],
      isLoading: false,
      error: null,

      createContract: async data => {
        try {
          set({ isLoading: true }, false, 'Create Contract Loading');
          const response = await ContractService.createContract(data);
          set(
            state => ({
              contracts: [...state.contracts, response.data],
              error: null
            }),
            false,
            'Create Contract'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Create Contract Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Create Contract Loading');
        }
      },

      getContracts: async customerId => {
        try {
          set({ isLoading: true }, false, 'Get Contracts Loading');
          set({ contracts: [], error: null }, false, 'Clear Get Contracts');
          const response = await ContractService.getContracts(customerId);
          set(
            {
              contracts: response.data.contracts,
              offset: response.data.offset,
              limit: response.data.limit,
              total: response.data.total,
              error: null
            },
            false,
            'Get Contracts'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Contracts Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Contracts Loading');
        }
      },

      getContract: async contractId => {
        try {
          set({ isLoading: true }, false, 'Get Contract Loading');
          set({ contract: null, error: null }, false, 'Clear Get Contract');
          const response = await ContractService.getContract(contractId);
          set({ contract: response.data, error: null }, false, 'Get Contract');
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Get Contract Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Get Contract Loading');
        }
      },

      updateContract: async (contractId, data) => {
        try {
          set({ isLoading: true }, false, 'Update Contract Loading');
          await ContractService.updateContract(contractId, data);
          set(
            state => ({
              contracts: state.contracts.map(contract => {
                if (contract._id === contractId) {
                  return { ...contract, ...data };
                }
                return contract;
              }),
              error: null
            }),
            false,
            'Update Contract'
          );
          return response.data;
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Update Contract Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Update Contract Loading');
        }
      },

      deleteContract: async contractId => {
        try {
          set({ isLoading: true }, false, 'Delete Contract Loading');
          await ContractService.deleteContract(contractId);
          set(
            state => ({
              contracts: state.contracts.filter(
                contract => contract._id !== contractId
              ),
              error: null
            }),
            false,
            'Delete Contract'
          );
        } catch (error) {
          set(
            { error: error.response?.data?.message },
            false,
            'Delete Contract Error'
          );
        } finally {
          set({ isLoading: false }, false, 'Delete Contract Loading');
        }
      }
    }),
    { name: 'contract-storage' }
  )
);
