import { Cross2Icon } from '@radix-ui/react-icons';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import { DataTableFacetedFilter } from '@/src/components/object/data-table/data-table-faceted-filter';
import {
  // OBJECT_SYSTEMS,
  STATUSES,
  PERIODICITY
} from '@/src/constants/objectConstants';

export function DataTableToolbar({ table }) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex-col items-start space-x-0 space-y-2 flex flex-1 md:flex-row md:items-center md:space-x-2 md:space-y-0">
        <Input
          placeholder="Введите адрес объекта"
          value={table.getColumn('address')?.getFilterValue() ?? ''}
          onChange={event =>
            table.getColumn('address')?.setFilterValue(event.target.value)
          }
          className="h-8 w-[250px] lg:w-[300px]"
        />
        {/* {table.getColumn('systems') && (
          <DataTableFacetedFilter
            column={table.getColumn('systems')}
            title="Системы"
            options={OBJECT_SYSTEMS}
          />
        )} */}
        {table.getColumn('service_intervals') && (
          <DataTableFacetedFilter
            column={table.getColumn('service_intervals')}
            title="Периодичность"
            options={PERIODICITY}
          />
        )}
        {table.getColumn('status') && (
          <DataTableFacetedFilter
            column={table.getColumn('status')}
            title="Статус"
            options={STATUSES}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Сбросить
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
