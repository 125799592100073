import { Link } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import { useAuthStore } from '@/src/store/useAuthStore';

const Activation = () => {
  const isAuth = useAuthStore(state => state.isAuth);

  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
        <h3 className="text-center">Аккаунт успешно активирован</h3>

        <p className="muted text-center">
          <Link to="/" className="link">
            {isAuth ? 'На главную' : 'Войти'}
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default Activation;
