import { Link } from 'react-router-dom';
import { Pyramid } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import { Card, CardContent } from '@/src/components/ui/card';
import Loader from '@/src/components/Loader';

const CustomerList = ({ customers }) => {
  if (!customers) {
    return <Loader />;
  }

  return (
    <>
      {customers && customers.length > 0 ? (
        customers.map(customer => (
          <Card key={customer._id} className="mt-4">
            <Link
              to={`/customers/${customer._id}`}
              className="transition-colors hover:hover:text-gray-500"
            >
              <CardContent className="grid gap-6 p-6">
                <div className="flex items-center space-x-4">
                  <Avatar>
                    <AvatarFallback>
                      <Pyramid />
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="text-sm font-medium leading-none">
                      {customer.title}
                    </p>
                    {customer.objects_addresses && (
                      <ul className="list-disc [&>li]:mt-0 [&>li:not(:first-child)]:mt-1 my-2 ml-5">
                        {customer.objects_addresses.map((address, index) => (
                          <li
                            key={index}
                            className="text-sm text-muted-foreground"
                          >
                            {address.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </CardContent>
            </Link>
          </Card>
        ))
      ) : (
        <p className="text-muted-foreground mt-4">Заказчики не найдены</p>
      )}
    </>
  );
};

export default CustomerList;
