const { default: $api } = require('@/src/lib/api');

const CustomerService = {
  createCustomer: async data => {
    return $api.post('/customers', data);
  },

  getCustomers: async (offset, limit, search) => {
    return $api.get('/customers', { params: { offset, limit, search } });
  },

  getCustomer: async id => {
    return $api.get(`/customers/${id}`);
  },

  updateCustomer: async (id, data) => {
    return $api.patch(`/customers/${id}`, data);
  },

  deleteCustomer: async id => {
    return $api.delete(`/customers/${id}`);
  },

  getCustomersForUser: async (id, offset, limit, search) => {
    return $api.get(`/users/${id}/customers`, {
      params: { offset, limit, search }
    });
  }
};

module.exports = CustomerService;
