import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { Skeleton } from '@/src/components/ui/skeleton';
import { Button } from '@/src/components/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/src/components/ui/alert-dialog';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useContractStore } from '@/src/store/useContractStore';
import { formatDate } from '@/src/utils/utils';

const ContractItem = () => {
  const { customerId, contractId } = useParams();

  const navigate = useNavigate();

  const user = useAuthStore(state => state.user);

  const isUser = user?.roles?.includes('user');
  // const isMaster = user?.roles?.includes('master');

  const [contract, setContract] = useState(null);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isDeletedLoading, setIsDeletedLoading] = useState(false);

  const { getContract, deleteContract, error } = useContractStore(state => ({
    getContract: state.getContract,
    deleteContract: state.deleteContract,
    error: state.error
  }));

  useEffect(() => {
    const fetchContract = async () => {
      try {
        const contractData = await getContract(contractId);
        setContract(contractData);
      } catch (error) {
        console.error('Ошибка при загрузке договора:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchContract();
  }, [getContract, contractId]);

  const handleDelete = async () => {
    setIsDeletedLoading(true);
    try {
      const deletedContract = await deleteContract(contractId);
      setContract(deletedContract);
    } catch (error) {
      console.error('Ошибка при удалении договора:', error);
    } finally {
      setIsDeletedLoading(false);
    }

    navigate(`/customers/${customerId}`);
  };

  if (error) {
    return <p className="text-sm font-medium text-destructive py-0">{error}</p>;
  }

  if (isFetchLoading) {
    return (
      <div className="mt-4 grid gap-2">
        <Skeleton className="h-4 w-[350px]" />
        <Skeleton className="h-4 w-[350px]" />
        <Skeleton className="h-4 w-[350px]" />
        <Skeleton className="h-4 w-[250px]" />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-2 items-start justify-between md:flex-row">
        <div>
          <h3>Договор {contract.number}</h3>
        </div>

        <div className="w-full md:w-auto">
          <div className="overflow-auto whitespace-nowrap">
            <div className="inline-flex space-x-2">
              {isUser && (
                <>
                  <Button size="sm" asChild>
                    <Link
                      to={`/customers/${customerId}/contracts/${contractId}/edit`}
                    >
                      Редактировать
                    </Link>
                  </Button>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button size="sm" disabled={isDeletedLoading}>
                        {isDeletedLoading ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Удаление...
                          </>
                        ) : (
                          'Удалить'
                        )}
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle className="border-0">
                          Вы уверены, что хотите удалить договор?
                        </AlertDialogTitle>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Отменить</AlertDialogCancel>
                        <AlertDialogAction onClick={handleDelete}>
                          Удалить
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        {contract.dateSignature && (
          <p>Дата заключения: {formatDate(contract.dateSignature)}</p>
        )}
        {contract.dateStart && (
          <p>Дата начала: {formatDate(contract.dateStart)}</p>
        )}
        {(contract.dateEnd || contract.isUnlimited) && (
          <p>
            Дата окончания:{' '}
            {contract.isUnlimited ? 'Бессрочно' : formatDate(contract.dateEnd)}
          </p>
        )}
      </div>
    </>
  );
};

export default ContractItem;
