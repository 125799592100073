import { Link } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import { Button } from '@/src/components/ui/button';

const NotFound = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center text-center">
        <p className="text-6xl font-bold sm:text-8xl">404</p>
        <div className="mt-4 text-3xl font-bold sm:text-5xl">
          Страница не найдена
        </div>
        <p className="mt-4 sm:mt-6 text-base leading-7 text-gray-600">
          Страница, на которую вы пытаетесь попасть не существует или она была
          удалена.
        </p>
        <Button size="sm" className="mt-10" asChild>
          <Link to="/">Перейти на главную</Link>
        </Button>
      </div>
    </Layout>
  );
};

export default NotFound;
