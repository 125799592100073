import { Routes, Route } from 'react-router-dom';
// import Landing from '@/src/pages/Landing';
import Dashboard from '@/src/pages/Dashboard';
import Note from '@/src/pages/note/Note';
import CreateNote from '@/src/pages/note/CreateNote';
import EditNote from '@/src/pages/note/EditNote';
import Customers from '@/src/pages/customer/Customers';
import Customer from '@/src/pages/customer/Customer';
import CreateCustomer from '@/src/pages/customer/CreateCustomer';
import EditCustomer from '@/src/pages/customer/EditCustomer';
import Object from '@/src/pages/object/Object';
import CreateObject from '@/src/pages/object/CreateObject';
import EditObject from '@/src/pages/object/EditObject';
import Contract from '@/src/pages/contract/Contract';
import CreateContract from '@/src/pages/contract/CreateContract';
import EditContract from '@/src/pages/contract/EditContract';
import Users from '@/src/pages/user/Users';
import User from '@/src/pages/user/User';
import CreateUser from '@/src/pages/user/CreateUser';
import EditUser from '@/src/pages/user/EditUser';
// import Settings from '@/src/pages/Settings';
import Account from '@/src/pages/Account';
import Notifications from '@/src/pages/Notifications';
import Support from '@/src/pages/Support';
import Registration from '@/src/pages/auth/Registration';
import Login from '@/src/pages/auth/Login';
import Activation from '@/src/pages/auth/Activation';
import ForgotPassword from '@/src/pages/auth/ForgotPassword';
import ChangePassword from '../pages/auth/ChangePassword';
import Terms from '@/src/pages/Terms';
import Privacy from '@/src/pages/Privacy';
import NotFound from '@/src/pages/NotFound';
import Loader from '@/src/components/Loader';
import { useAuthStore } from '@/src/store/useAuthStore';

const AppRouter = () => {
  const { isAuth, isLoadingAuth, user } = useAuthStore(state => ({
    isAuth: state.isAuth,
    isLoadingAuth: state.isLoadingAuth,
    user: state.user
  }));

  // const isAdmin = user?.roles?.includes('admin');
  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');
  const isCustomer = user?.roles?.includes('customer');

  if (isLoadingAuth) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <Routes>
      {isAuth && (
        <>
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Account />} />
          <Route path="/settings/notifications" element={<Notifications />} />
          <Route path="/support" element={<Support />} />

          {/* customers */}
          {(isUser || isMaster) && (
            <>
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/:customerId" element={<Customer />} />
            </>
          )}
          <Route
            path="/customers/:customerId/users/:userId"
            element={<User />}
          />
          <Route
            path="/customers/:customerId/objects/:objectId"
            element={<Object />}
          />
          <Route
            path="/customers/:customerId/contracts/:contractId"
            element={<Contract />}
          />
          <Route
            path="/customers/:customerId/objects/:objectId/notes/create"
            element={<CreateNote />}
          />
          <Route
            path="/customers/:customerId/objects/:objectId/notes/:noteId"
            element={<Note />}
          />
          <Route
            path="/customers/:customerId/objects/:objectId/notes/:noteId/edit"
            element={<EditNote />}
          />
          {isUser && (
            <>
              <Route path="/customers/create" element={<CreateCustomer />} />
              <Route
                path="/customers/:customerId/edit"
                element={<EditCustomer />}
              />
              <Route
                path="/customers/:customerId/users/create"
                element={<CreateUser />}
              />
              <Route
                path="/customers/:customerId/users/:userId/edit"
                element={<EditUser />}
              />
              <Route
                path="/customers/:customerId/objects/create"
                element={<CreateObject />}
              />
              <Route
                path="/customers/:customerId/objects/:objectId/edit"
                element={<EditObject />}
              />
              <Route
                path="/customers/:customerId/contracts/create"
                element={<CreateContract />}
              />
              <Route
                path="/customers/:customerId/contracts/:contractId/edit"
                element={<EditContract />}
              />
            </>
          )}

          {/* users */}
          <Route path="/users/:userId" element={<User />} />
          {isUser && (
            <>
              <Route path="/users" element={<Users />} />
              <Route path="/users/create" element={<CreateUser />} />
              <Route path="/users/:userId/edit" element={<EditUser />} />
            </>
          )}

          {/* customer */}
          {isCustomer && (
            <>
              {/* <Route path="/users/:userId" element={<User />} /> */}
              {/* <Route path="/users" element={<Users />} /> */}
              <Route path="/users/:userId" element={<User />} />
              {/* <Route path="/objects" element={<Objects />} /> */}
              <Route path="/objects/:objectId" element={<Object />} />
              <Route
                path="/objects/:objectId/notes/create"
                element={<CreateNote />}
              />
              <Route
                path="/objects/:objectId/notes/:noteId"
                element={<Note />}
              />
            </>
          )}
        </>
      )}
      {/* <Route path="/" element={<Landing />} /> */}
      <Route path="/" element={<Login />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/activation" element={<Activation />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password/:id" element={<ChangePassword />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
