import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import ContractItem from '@/src/components/contract/ContractItem';
import { useAuthStore } from '@/src/store/useAuthStore';

const Contract = () => {
  const { customerId } = useParams();

  const user = useAuthStore(state => state.user);

  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');
  const isCustomer = user?.roles?.includes('customer');

  let breadcrumbItems = [];

  if (isUser || isMaster) {
    breadcrumbItems = [
      { text: 'Заказчики', link: '/customers' },
      { text: 'Заказчик', link: `/customers/${customerId}` },
      { text: 'Договор', link: null }
    ];
  }

  if (isCustomer) {
    breadcrumbItems = [
      { text: 'Главная', link: '/' },
      { text: 'Договор', link: null }
    ];
  }

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <ContractItem />
    </Layout>
  );
};

export default Contract;
