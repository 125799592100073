import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@/src/components/ui/skeleton';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@/src/components/ui/tabs';
import Layout from '@/src/components/Layout';
import { DataTable as DataTableObjects } from '@/src/components/object/data-table/data-table';
import { columns as objectColumns } from '@/src/components/object/data-table/columns';
import { DataTable as DataTableNotes } from '@/src/components/note/data-table/data-table';
import { columns as noteColumns } from '@/src/components/note/data-table/latest-notes-columns';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useObjectStore } from '@/src/store/useObjectStore';
import { useNoteStore } from '@/src/store/useNoteStore';

const Dashboard = () => {
  const [objects, setObjects] = useState(null);
  const [notes, setNotes] = useState(null);
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  const user = useAuthStore(state => state.user);

  const { getObjectsForUser } = useObjectStore(state => ({
    getObjectsForUser: state.getObjectsForUser
  }));

  const { getNotes, getNotesForUser, getLatestNotes } = useNoteStore(state => ({
    getNotes: state.getNotes,
    getNotesForUser: state.getNotesForUser,
    getLatestNotes: state.getLatestNotes
  }));

  useEffect(() => {
    const fetchDataObjects = async () => {
      try {
        const objectsData = await getObjectsForUser(user?.id, 0, 100, false); // TODO: убрать чардкод "0, 100"
        setObjects(objectsData.objects);
      } catch (error) {
        console.error('Ошибка при загрузке объектов:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchDataObjects();
  }, [getObjectsForUser]);

  useEffect(() => {
    const fetchDataNotes = async () => {
      try {
        const notesData = await getLatestNotes();
        setNotes(notesData);
      } catch (error) {
        console.error('Ошибка при загрузке записей:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchDataNotes();
  }, [getNotes, getNotesForUser]);

  return (
    <Layout>
      <div className="flex flex-col gap-4">
        {!user.isActivated && (
          <blockquote className="alert">
            Необходимо активировать аккаунт (письмо с инструкцией было
            отправлено при регистрации)
          </blockquote>
        )}

        {(!user.lastName ||
          !user.firstName ||
          !user.middleName ||
          !user.position ||
          !user.type ||
          !user.phone) && (
          <blockquote className="alert">
            Необходимо запонить информацию на странице{' '}
            <Link to="/settings" className="link">
              Профиль
            </Link>
          </blockquote>
        )}

        <Tabs defaultValue="onjects">
          <TabsList>
            <TabsTrigger value="onjects">Объекты</TabsTrigger>
            <TabsTrigger value="notes">Последние записи</TabsTrigger>
          </TabsList>
          <TabsContent value="onjects" className="mt-4">
            {isFetchLoading ? (
              <div className="flex flex-col space-y-7 mt-5 mb-5">
                <Skeleton className="w-[350px] h-[28px] rounded-lg" />
                <Skeleton className="w-full h-[24px] rounded-lg" />
                <Skeleton className="w-full h-[24px] rounded-lg" />
                <Skeleton className="w-full h-[24px] rounded-lg" />
                <Skeleton className="w-full h-[24px] rounded-lg" />
              </div>
            ) : objects?.length > 0 ? (
              <DataTableObjects data={objects} columns={objectColumns} />
            ) : (
              <p className="muted">Нет объектов</p>
            )}
          </TabsContent>
          <TabsContent value="notes" className="mt-4">
            {isFetchLoading ? (
              <div className="flex flex-col space-y-7 mt-5 mb-5">
                <Skeleton className="w-[350px] h-[28px] rounded-lg" />
                <Skeleton className="w-full h-[28px] rounded-lg" />
                <Skeleton className="w-full h-[24px] rounded-lg" />
                <Skeleton className="w-full h-[24px] rounded-lg" />
                <Skeleton className="w-full h-[24px] rounded-lg" />
              </div>
            ) : notes?.length > 0 ? (
              <DataTableNotes columns={noteColumns} data={notes} />
            ) : (
              <p className="muted">Нет записей</p>
            )}
          </TabsContent>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Dashboard;
