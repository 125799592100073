import Layout from '@/src/components/Layout';
import ChangePasswordForm from '@/src/components/auth/ChangePasswordForm';

const ChangePassword = () => {
  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
        <h3 className="text-center">Смена пароля</h3>

        <ChangePasswordForm />
      </div>
    </Layout>
  );
};

export default ChangePassword;
