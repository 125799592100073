import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@/src/components/ui/skeleton';
import { Button } from '@/src/components/ui/button';
import Layout from '@/src/components/Layout';
import CustomerList from '@/src/components/customer/CustomerList';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useCustomerStore } from '@/src/store/useCustomerStore';
import { Search } from '@/src/components/Search';

const CUSTOMERS_LIMIT = 10;

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const user = useAuthStore(state => state.user);

  const { getCustomers, getCustomersForUser } = useCustomerStore(state => ({
    getCustomers: state.getCustomers,
    getCustomersForUser: state.getCustomersForUser
  }));

  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        let customersData;

        if (isUser) {
          customersData = await getCustomers(
            currentPage * CUSTOMERS_LIMIT,
            CUSTOMERS_LIMIT,
            searchQuery
          );
        }

        if (isMaster) {
          customersData = await getCustomersForUser(
            user.id,
            currentPage * CUSTOMERS_LIMIT,
            CUSTOMERS_LIMIT,
            searchQuery
          );
        }

        if (currentPage === 0) {
          // Если текущая страница - 0, перезаписываем список клиентов
          setCustomers(customersData.customers);
        } else {
          // Если текущая страница не 0, добавляем новых клиентов к существующему списку
          setCustomers(prevCustomers => [
            ...prevCustomers,
            ...customersData.customers
          ]);
        }

        setTotalCustomers(customersData.total);
      } catch (error) {
        console.error('Ошибка при загрузке заказчиков:', error);
      } finally {
        setIsFetchLoading(false);
        setIsLoadingMore(false);
        setIsSearchLoading(false);
      }
    };

    fetchCustomers();
  }, [currentPage, searchQuery]);

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleSearch = query => {
    setIsSearchLoading(true);
    setSearchQuery(query); // Обновляем запрос поиска при изменении
    setCurrentPage(0); // Сбрасываем текущую страницу при вводе нового запроса
  };

  return (
    <Layout>
      <div className="flex flex-row gap-2 items-center justify-between">
        <h3>Заказчики</h3>

        {isUser && (
          <Button size="sm" asChild>
            <Link to="/customers/create">Добавить</Link>
          </Button>
        )}
      </div>

      {isFetchLoading ? (
        <div className="flex flex-col space-y-6 mt-4 mb-4">
          <Skeleton className="h-7 w-[350px]" />
          <div className="flex items-center space-x-4">
            <Skeleton className="h-10 w-10 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[350px]" />
              <Skeleton className="h-4 w-[300px]" />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Skeleton className="h-10 w-10 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[350px]" />
              <Skeleton className="h-4 w-[300px]" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Search onSearch={handleSearch} loading={isSearchLoading} />

          <CustomerList customers={customers} />

          {customers.length < totalCustomers && (
            <div className="flex justify-center mt-5">
              <Button onClick={handleLoadMore} disabled={isLoadingMore}>
                {isLoadingMore ? 'Загрузка...' : 'Загрузить еще'}
              </Button>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default Customers;
