const { default: $api } = require('@/src/lib/api');

const FileService = {
  uploadFile: async data => {
    return $api.post('/files', data);
  },
  deleteFile: async id => {
    return $api.delete(`/files/${id}`);
  }
};

module.exports = FileService;
