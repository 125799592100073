import { Link } from 'react-router-dom';
import { User, XCircle } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import { Card, CardContent } from '@/src/components/ui/card';
import Loader from '@/src/components/Loader';

const UserList = ({ users }) => {
  if (!users) {
    return <Loader />;
  }

  const sortedUsers = [...users].sort((a, b) =>
    a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1
  );

  return (
    <>
      {sortedUsers.length > 0 ? (
        sortedUsers.map(user => (
          <Card key={user.id} className="mt-4">
            <Link
              to={`/users/${user.id}`}
              className="transition-colors hover:hover:text-gray-500"
            >
              <CardContent className="grid gap-6 p-6">
                <div className="flex items-center space-x-4">
                  <Avatar>
                    <AvatarFallback>
                      {user.isActive ? <User /> : <XCircle />}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    {user.isActive ? (
                      <p className="text-sm font-medium leading-none">
                        {`${user.lastName} ${user.firstName} ${user.middleName}`}
                      </p>
                    ) : (
                      <p className="text-sm text-muted-foreground">
                        {`${user.lastName} ${user.firstName} ${user.middleName}`}
                      </p>
                    )}
                    <p className="text-sm text-muted-foreground">
                      {user.position}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Link>
          </Card>
        ))
      ) : (
        <p className="text-muted-foreground mt-2">Нет доступных сотрудников</p>
      )}
    </>
  );
};

export default UserList;
