import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loader2 } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'sonner';
import { Button } from '@/src/components/ui/button';
import { Textarea } from '@/src/components/ui/textarea';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/src/components/ui/select';
import { Card, CardContent } from '@/src/components/ui/card';
import { Input } from '@/src/components/ui/input';
import { Checkbox } from '@/src/components/ui/checkbox';
import Loader from '@/src/components/Loader';
import FileItem from '@/src/components/FileItem';
import FileService from '@/src/services/FileService';
import { useNoteStore } from '@/src/store/useNoteStore';
import { useObjectStore } from '@/src/store/useObjectStore';

const KEY_PHRASES = [
  'Проведено ТО в соответствии с регламентом технического обслуживания. ',
  'Система находится в работоспособном состоянии. ',
  'Система частично работоспособна. ',
  'Система не работоспособна. ',
  'Подготовлена дефектная ведомость. ',
  'Требуется подробная дефектовка. '
];

const formSchema = z.object({
  system: z.string().min(1, {
    message: 'Необходимо выбрать систему'
  }),
  name: z.string().min(1, {
    message: 'Поле не может быть пустым'
  }),
  result: z.string().min(1, {
    message: 'Поле не может быть пустым'
  }),
  complex_test: z.boolean(),
  complex_test_result: z.string()
});

const NoteForm = () => {
  const { customerId, objectId, noteId } = useParams();
  const navigate = useNavigate();

  const isEditing = !!noteId;

  const [note, setNote] = useState(null);
  const [object, setObject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [selectedPhrases, setSelectedPhrases] = useState([]);

  const { getNote, createNote, updateNote, error } = useNoteStore(state => ({
    getNote: state.getNote,
    createNote: state.createNote,
    updateNote: state.updateNote,
    error: state.error
  }));

  const { getObject } = useObjectStore(state => ({
    getObject: state.getObject
  }));

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.heic'],
      'image/x-adobe-dng': ['.dng']
    },
    multiple: true,
    onDrop: acceptedFiles => {
      form.setValue('files', acceptedFiles, {
        shouldValidate: true
      });
    }
  });

  const acceptedFileItems = acceptedFiles.map((file, index) => (
    <li key={index}>{file.path}</li>
  ));

  const fileRejectionItems = fileRejections.map((file, index) => (
    <li key={index}>{file.file.path}</li>
  ));

  const style = useMemo(
    () => `
    ${'border-dashed border-2 mt-0'}
    ${isFocused && 'border-slate-500'}
    ${isDragAccept && 'border-green-500'}
    ${isDragReject && 'border-red-500'}
  `,
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const noteData = await getNote(noteId);
        setNote(noteData);
      } catch (error) {
        console.error('Ошибка при загрузке данных записи:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    if (isEditing) {
      fetchNote();
    } else {
      setIsFetchLoading(false);
    }
  }, [getNote, noteId, isEditing]);

  useEffect(() => {
    const fetchObject = async () => {
      try {
        const objectData = await getObject(objectId);
        setObject(objectData);
      } catch (error) {
        console.error('Ошибка при загрузке данных объекта:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchObject();
  }, [getObject, objectId]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      system: '',
      name: '',
      result: '',
      complex_test: false,
      complex_test_result: ''
    }
  });

  useEffect(() => {
    if (isEditing && note) {
      form.reset(note);
    }
  }, [form, isEditing, note]);

  const updateTextarea = phrase => {
    form.setValue('result', `${form.getValues('result')}${phrase}`);
  };

  const handleDelete = async (fileId, fileName) => {
    try {
      await FileService.deleteFile(fileId);

      setNote(prevNote => ({
        ...prevNote,
        files: prevNote.files.filter(file => file._id !== fileId)
      }));

      toast('Файл удален', {
        description: fileName
      });
    } catch (error) {
      toast(error.response.data.message, {
        description: fileName
      });
      // console.error('Ошибка при удалении файла:', error.response.data.message);
    }
  };

  const onSubmit = async () => {
    const formData = form.getValues();
    setIsLoading(true);

    try {
      if (!noteId) {
        const note = await createNote({
          customerId: object?.customer,
          objectId,
          ...formData
        });

        // загрузить файлы
        if (acceptedFiles.length > 0) {
          setIsFileUploading(true);
          const formData = new FormData();
          acceptedFiles.forEach(file => {
            formData.append('files', file, encodeURIComponent(file.path));
          });
          formData.append('objectId', objectId);
          formData.append('noteId', note._id);
          formData.append('type', 'note');

          await FileService.uploadFile(formData);
          setIsFileUploading(false);
        }

        setIsLoading(false);
      } else {
        // загрузить файлы
        if (acceptedFiles.length > 0) {
          setIsFileUploading(true);
          const formData = new FormData();
          acceptedFiles.forEach(file => {
            formData.append('files', file, encodeURIComponent(file.path));
          });
          formData.append('objectId', objectId);
          formData.append('noteId', noteId);
          formData.append('type', 'note');

          await FileService.uploadFile(formData);
          setIsFileUploading(false);
        }

        await updateNote(noteId, formData);
        setIsLoading(false);
      }

      if (customerId) {
        return navigate(`/customers/${customerId}/objects/${objectId}`);
      } else {
        return navigate(`/objects/${objectId}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchLoading) {
    return (
      <div className="mt-4">
        <Loader />
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <FormField
          control={form.control}
          name="system"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Система</FormLabel>
              {object?.systems?.length <= 0 ? (
                <p className="muted">
                  Вы не указали обслуживаемые системы при создании объекта
                </p>
              ) : (
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger
                      className={!field.value && 'text-muted-foreground'}
                    >
                      <SelectValue placeholder="Выберите систему" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {object?.systems &&
                      object?.systems?.map((item, index) => (
                        <SelectItem key={index} value={item.label}>
                          {item.label}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              )}
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Наименование установки{' '}
                <span className="muted">(местоположение)</span>
              </FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Наименование установки и ее местоположение"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="result"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Вид работ. Результат проведенных работ, замечания.
              </FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Техническое обслуживание, ремонт, проверка
          работоспособности, другие регламентные работы"
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                    const updatedText = e.target.value;
                    const updatedPhrases = KEY_PHRASES.filter(phrase =>
                      updatedText.includes(`${phrase.trim()}`)
                    );
                    setSelectedPhrases(updatedPhrases);
                  }}
                />
              </FormControl>
              <FormMessage />
              <div className="pt-2">
                {KEY_PHRASES.map((phrase, index) => (
                  <Button
                    key={index}
                    size="sm"
                    type="button"
                    variant="outline"
                    disabled={selectedPhrases.includes(phrase)}
                    onClick={() => {
                      updateTextarea(`${phrase}`);
                      setSelectedPhrases([...selectedPhrases, phrase]);
                    }}
                    className={`mr-2 mb-2 px-2 py-1 border rounde h-auto text-left`}
                  >
                    {phrase}
                  </Button>
                ))}
              </div>
            </FormItem>
          )}
        />

        {note?.files.length > 0 && (
          <div>
            <div className="text-lg font-semibold">Загруженные файлы</div>

            <ul className="my-2 ml-0 list-disc [&>li]:mt-2">
              {note?.files.map(file => (
                <FileItem key={file._id} file={file} onDelete={handleDelete} />
              ))}
            </ul>
          </div>
        )}

        <FormField
          control={form.control}
          name="files"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Фото</FormLabel>
              <FormDescription>Доступные форматы: jpeg, heic</FormDescription>
              <FormControl>
                <Card {...field} {...getRootProps({ className: style })}>
                  <CardContent className="px-4 py-10 text-center cursor-pointer">
                    <Input {...getInputProps()} />
                    <p>
                      Перетащите сюда несколько файлов или нажмите, чтобы
                      выбрать файлы
                    </p>
                  </CardContent>
                </Card>
              </FormControl>
              <FormMessage />
              {acceptedFileItems.length > 0 && (
                <>
                  <div className="text-lg font-semibold">Принятые файлы</div>
                  <ul className="my-6 ml-6 list-disc [&>li]:mt-2">
                    {acceptedFileItems}
                  </ul>
                </>
              )}
              {fileRejectionItems.length > 0 && (
                <>
                  <div className="text-lg font-semibold">Отклоненные файлы</div>
                  <ul className="my-6 ml-6 list-disc [&>li]:mt-2 text-red-500">
                    {fileRejectionItems}
                  </ul>
                </>
              )}
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="complex_test"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-3 space-y-0 rounded-md border p-4">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>Проводились комплексные испытания</FormLabel>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="complex_test_result"
          render={({ field }) => (
            <FormItem
              style={{ display: form.watch('complex_test') ? 'block' : 'none' }}
            >
              <FormLabel>Результаты комплексных испытаний</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Результаты комплексных испытаний"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button size="sm" type="submit" disabled={isLoading}>
          {isFileUploading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Загрузка файлов...
            </>
          ) : isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Сохранение...
            </>
          ) : (
            'Сохранить'
          )}
        </Button>

        {error && (
          <p className="text-sm font-medium text-destructive py-0">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default NoteForm;
