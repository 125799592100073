import { Toaster } from '@/src/components/ui/sonner';
import Navbar from '@/src/components/Navbar';

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />

      <main className="p-4 sm:p-6 lg:p-8 mx-auto max-w-7xl">{children}</main>

      <Toaster />
    </>
  );
};

export default Layout;
