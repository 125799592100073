import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import UserForm from '@/src/components/user/UserForm';

const CreateUser = () => {
  const { customerId } = useParams();

  let breadcrumbItems = [];

  if (customerId) {
    breadcrumbItems = [
      { text: 'Заказчики', link: '/customers' },
      { text: 'Заказчик', link: `/customers/${customerId}` },
      { text: 'Создание пользователя', link: null }
    ];
  }

  if (!customerId) {
    breadcrumbItems = [
      { text: 'Сотрудники', link: '/users' },
      { text: 'Создание', link: null }
    ];
  }

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Новый пользователь</h3>

      <UserForm />
    </Layout>
  );
};

export default CreateUser;
