import Layout from '@/src/components/Layout';
import RegistrationForm from '@/src/components/auth/RegistrationForm';

const Registration = () => {
  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
        <h3 className="text-center">Регистрация</h3>

        <RegistrationForm />
      </div>
    </Layout>
  );
};

export default Registration;
