import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import ContractForm from '@/src/components/contract/ContractForm';

const EditContract = () => {
  const { customerId, contractId } = useParams();

  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Заказчик', link: `/customers/${customerId}` },
    {
      text: 'Договор',
      link: `/customers/${customerId}/contracts/${contractId}`
    },
    { text: 'Редактирование', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Редактировать договор</h3>

      <ContractForm />
    </Layout>
  );
};

export default EditContract;
