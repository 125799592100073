import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loader2, Eye, EyeOff } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import { useUserStore } from '@/src/store/useUserStore';

const formSchema = z.object({
  password: z
    .string()
    .trim()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .min(8, { message: 'Длина пароля не менее 8 символов' })
    .max(32, { message: 'Длина пароля не более 32 символов' })
    .refine(
      value => {
        return /[a-zA-Zа-яА-Я]/.test(value);
      },
      {
        message: 'Пароль должен содержать минимум одну букву'
      }
    )
    .refine(
      value => {
        return /\d/.test(value);
      },
      { message: 'Пароль должен содержать минимум одну цифру' }
    )
    .refine(
      value => {
        return !/[\p{Extended_Pictographic}]/u.test(value);
      },
      { message: 'Пароль не должен содержать emoji' }
    )
});

const ChangePasswordForm = () => {
  const { id } = useParams();

  const [isSending, setIsSending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRules, setPasswordRules] = useState({
    minLength: false,
    containsLetter: false,
    containsDigit: false
  });

  const { isLoading, changePassword, error } = useUserStore(state => ({
    isLoading: state.isLoading,
    changePassword: state.changePassword,
    error: state.error
  }));

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: ''
    }
  });

  const onSubmit = async ({ password }) => {
    const forgotPasswordLink = id;
    changePassword(forgotPasswordLink, password);
    setIsSending(true);
  };

  const checkPasswordRules = value => {
    const isMinLength = value.length >= 8 && value.length <= 32;
    const containsLetter = /[a-zA-Zа-яА-Я]/.test(value);
    const containsDigit = /\d/.test(value);

    setPasswordRules({
      minLength: isMinLength,
      containsLetter,
      containsDigit
    });
  };

  return (
    <>
      {isSending && !error ? (
        <>
          <p className="text-center">Пароль успешно изменен</p>
          <p className="text-center muted p-4 pt-0">
            <Link to="/" className="link">
              Войти
            </Link>
          </p>
        </>
      ) : (
        <>
          <p className="muted text-center">
            Новый пароль должен содержать от 8 до 32 символов, включая как
            минимум одну букву и одну цифру.
          </p>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 mt-6"
            >
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Новый пароль</FormLabel>
                    <div className="relative">
                      <FormControl>
                        <Input
                          className="pr-[34px]"
                          type={showPassword ? 'text' : 'password'}
                          {...field}
                          onInput={e => {
                            const value = e.target.value.replace(/\s/g, ''); // Удаляем пробелы
                            checkPasswordRules(value);
                          }}
                        />
                      </FormControl>
                      <span
                        className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOff className="w-5 h-5 stroke-slate-600" />
                        ) : (
                          <Eye className="w-5 h-5 stroke-slate-600" />
                        )}
                      </span>
                    </div>
                    <FormMessage />
                    <ul className="space-y-0 pt-1">
                      <li
                        className={`leading-normal text-sm ${
                          passwordRules.containsLetter
                            ? 'text-green-600'
                            : 'muted'
                        }`}
                      >
                        Одна буква
                      </li>
                      <li
                        className={`leading-normal text-sm ${
                          passwordRules.containsDigit
                            ? 'text-green-600'
                            : 'muted'
                        }`}
                      >
                        Одна цифра
                      </li>
                      <li
                        className={`leading-normal text-sm ${
                          passwordRules.minLength ? 'text-green-600' : 'muted'
                        }`}
                      >
                        Длина от 8 до 32 символов
                      </li>
                    </ul>
                  </FormItem>
                )}
              />

              {isLoading ? (
                <Button className="w-full" disabled>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                </Button>
              ) : (
                <Button type="submit" className="w-full">
                  Сохранить
                </Button>
              )}
            </form>

            {error && (
              <p className="text-sm font-medium text-destructive text-center px-6 py-0">
                {error}
              </p>
            )}

            <p className="text-center muted p-4 pt-0">
              Есть аккаунт?{' '}
              <Link to="/" className="link">
                Вернуться к авторизации
              </Link>
            </p>
          </Form>
        </>
      )}
    </>
  );
};

export default ChangePasswordForm;
