const { default: $api } = require('@/src/lib/api');

const AuthService = {
  registration: async (code, email, password) => {
    return $api.post('/auth/registration', { code, email, password });
  },

  login: async (email, password) => {
    return $api.post('/auth/login', { email, password });
  },

  logout: async () => {
    return $api.post('/auth/logout');
  }
};

module.exports = AuthService;
