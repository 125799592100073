import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import CustomerForm from '@/src/components/customer/CustomerForm';

const EditCustomer = () => {
  const { customerId } = useParams();

  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Заказчик', link: `/customers/${customerId}` },
    { text: 'Редактирование', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Редактировать заказчика</h3>

      <CustomerForm />
    </Layout>
  );
};

export default EditCustomer;
