import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import ObjectForm from '@/src/components/object/ObjectForm';

const EditObject = () => {
  const { customerId, objectId } = useParams();

  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Заказчик', link: `/customers/${customerId}` },
    { text: 'Объект', link: `/customers/${customerId}/objects/${objectId}` },
    { text: 'Редактирование', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Редактировать объект</h3>

      <ObjectForm />
    </Layout>
  );
};

export default EditObject;
