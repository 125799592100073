import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loader2, Eye, EyeOff } from 'lucide-react';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import { useAuthStore } from '@/src/store/useAuthStore';

const formSchema = z.object({
  code: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  email: z
    .string()
    .trim()
    .toLowerCase()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .email({
      message: 'Поле должно содержать валидный e-mail'
    }),
  password: z
    .string()
    .trim()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .min(8, { message: 'Длина пароля не менее 8 символов' })
    .max(32, { message: 'Длина пароля не более 32 символов' })
    .refine(
      value => {
        return /[a-zA-Zа-яА-Я]/.test(value);
      },
      {
        message: 'Пароль должен содержать минимум одну букву'
      }
    )
    .refine(
      value => {
        return /\d/.test(value);
      },
      { message: 'Пароль должен содержать минимум одну цифру' }
    )
    .refine(
      value => {
        return !/[\p{Extended_Pictographic}]/u.test(value);
      },
      { message: 'Пароль не должен содержать emoji' }
    )
});

const RegistrationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') || '';

  const [showPassword, setShowPassword] = useState(false);
  const [passwordRules, setPasswordRules] = useState({
    minLength: false,
    containsLetter: false,
    containsDigit: false
  });

  const { isAuth, isLoading, registration, resetError, error } = useAuthStore(
    state => ({
      isAuth: state.isAuth,
      isLoading: state.isLoading,
      registration: state.registration,
      resetError: state.resetError,
      error: state.error
    })
  );

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
  }, [isAuth]);

  useEffect(() => {
    resetError();
  }, []);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: code || '',
      email: '',
      password: ''
    }
  });

  const onSubmit = async ({ code, email, password }) => {
    registration(code, email, password);
  };

  const checkPasswordRules = value => {
    const isMinLength = value.length >= 8 && value.length <= 32;
    const containsLetter = /[a-zA-Zа-яА-Я]/.test(value);
    const containsDigit = /\d/.test(value);

    setPasswordRules({
      minLength: isMinLength,
      containsLetter,
      containsDigit
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <blockquote className="alert">
          В настоящее время регистрация доступна только по приглашениям.
        </blockquote>

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Почта</FormLabel>
              <FormControl>
                <Input
                  // autoFocus={true}
                  placeholder="ivanov@example.com"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Пароль</FormLabel>
              <div className="relative">
                <FormControl>
                  <Input
                    className="pr-[34px]"
                    type={showPassword ? 'text' : 'password'}
                    {...field}
                    onInput={e => {
                      const value = e.target.value.replace(/\s/g, ''); // Удаляем пробелы
                      checkPasswordRules(value);
                    }}
                  />
                </FormControl>
                <span
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="w-5 h-5 stroke-slate-600" />
                  ) : (
                    <Eye className="w-5 h-5 stroke-slate-600" />
                  )}
                </span>
              </div>
              <FormMessage />
              <ul className="space-y-0 pt-1">
                <li
                  className={`leading-normal text-sm ${
                    passwordRules.containsLetter ? 'text-green-600' : 'muted'
                  }`}
                >
                  Одна буква
                </li>
                <li
                  className={`leading-normal text-sm ${
                    passwordRules.containsDigit ? 'text-green-600' : 'muted'
                  }`}
                >
                  Одна цифра
                </li>
                <li
                  className={`leading-normal text-sm ${
                    passwordRules.minLength ? 'text-green-600' : 'muted'
                  }`}
                >
                  Длина от 8 до 32 символов
                </li>
              </ul>
            </FormItem>
          )}
        />

        <blockquote className="alert">
          Чтобы получить код приглашения, отправьте запрос нашей{' '}
          <Link
            to="https://t.me/firecrm_help_bot"
            target="_blank"
            className="link"
          >
            Поддержке
          </Link>
          .
        </blockquote>
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Код приглашения</FormLabel>
              <FormControl>
                <Input
                  // autoFocus={true}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {isLoading ? (
          <Button className="w-full" disabled>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          </Button>
        ) : (
          <Button type="submit" className="w-full">
            Зарегистрироваться
          </Button>
        )}
      </form>

      {error && (
        <p className="text-sm font-medium text-destructive text-center px-6 py-0">
          {error}
        </p>
      )}

      <p className="text-center muted px-6 py-0">
        Нажимая «Зарегистрироваться», Вы соглашаетесь с{' '}
        <Link
          to="/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="underline underline-offset-4 hover:text-primary"
        >
          Условиями обслуживания
        </Link>{' '}
        и{' '}
        <Link
          to="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline underline-offset-4 hover:text-primary"
        >
          Политикой конфиденциальности
        </Link>
        .
      </p>

      <p className="text-center muted p-4 pt-0">
        Уже есть аккаунт?{' '}
        <Link to="/" className="link">
          Войдите
        </Link>
      </p>
    </Form>
  );
};

export default RegistrationForm;
