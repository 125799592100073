import { DataTableColumnHeader } from '@/src/components/note/data-table/data-table-column-header';
import { formatDateAndTime } from '@/src/utils/utils';

export const columns = [
  {
    accessorKey: 'system',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Система" />
    ),
    cell: ({ row }) => {
      return row.getValue('system');
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'name',
    header: 'Наименование установки'
  },
  {
    accessorKey: 'result',
    header: 'Вид работ'
  },
  {
    accessorKey: 'master',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Ответственный" />
    ),
    cell: ({ row }) => {
      const master = `${row.getValue('master')?.lastName} ${
        row.getValue('master')?.firstName
      } ${row.getValue('master')?.middleName}`;

      return master;
    },
    enableSorting: false
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Дата" />
    ),
    cell: ({ row }) => {
      return formatDateAndTime(row.getValue('createdAt'));
    },
    filterFn: (row, id, value) => {
      const createdAt = formatDateAndTime(row.getValue('createdAt'));
      return createdAt.toLowerCase().includes(value.toLowerCase());
    }
  }
];
