import { format, formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatDateToNow = dateString => {
  const inputDate = new Date(dateString);
  return formatDistanceToNow(inputDate, { addSuffix: true, locale: ru });
};

export const formatDate = dateString => {
  const inputDate = new Date(dateString);
  return format(inputDate, 'd MMMM yyyy', { locale: ru });
};

export const formatDateShort = dateString => {
  const inputDate = new Date(dateString);
  return format(inputDate, 'dd.MM.yyyy', { locale: ru });
};

export const formatTime = dateString => {
  const inputDate = new Date(dateString);
  return format(inputDate, 'HH:mm', { locale: ru });
};

export const formatDateAndTime = dateString => {
  const inputDate = new Date(dateString);
  return format(inputDate, 'dd.MM.yyyy HH:mm', { locale: ru });
};
