import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import NoteForm from '@/src/components/note/NoteForm';
import { useAuthStore } from '@/src/store/useAuthStore';

const CreateNote = () => {
  const { customerId, objectId } = useParams();

  const user = useAuthStore(state => state.user);

  const isUser = user?.roles?.includes('user');
  const isMaster = user?.roles?.includes('master');
  const isCustomer = user?.roles?.includes('customer');

  let breadcrumbItems = [];

  if (isUser || isMaster) {
    breadcrumbItems = [
      { text: 'Заказчики', link: '/customers' },
      { text: 'Заказчик', link: `/customers/${customerId}` },
      { text: 'Объект', link: `/customers/${customerId}/objects/${objectId}` },
      { text: 'Создание записи', link: null }
    ];
  }

  if (isCustomer) {
    breadcrumbItems = [
      { text: 'Объекты', link: '/objects' },
      { text: 'Объект', link: `/objects/${objectId}` },
      { text: 'Создание записи', link: null }
    ];
  }

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Новая запись</h3>

      <NoteForm />
    </Layout>
  );
};

export default CreateNote;
