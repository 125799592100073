import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import InputMask from 'react-input-mask';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/src/components/ui/select';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import { Separator } from '@/src/components/ui/separator';
import Layout from '@/src/components/Layout';
import SidebarNav from '@/src/components/SidebarNav';
import Loader from '@/src/components/Loader';
import { useUserStore } from '@/src/store/useUserStore';
import { useAuthStore } from '@/src/store/useAuthStore';
import { SIDEBAR_NAV_ITEMS } from '@/src/constants/mainConstants';

const formSchema = z.object({
  lastName: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  firstName: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  middleName: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  position: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  type: z.enum(['Руководитель', 'Инженер', 'Специалист'], {
    errorMap: () => ({ message: 'Необходимо указать тип пользователя' })
  }),
  phone: z
    .string()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .refine(value => /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(value), {
      message: 'Поле должно содержать 10 цифр'
    }),
  email: z
    .string()
    .trim()
    .toLowerCase()
    .min(1, {
      message: 'Поле не может быть пустым'
    })
    .email({
      message: 'Поле должно содержать валидный e-mail'
    })
});

const Account = () => {
  const [editingUser, setEditingUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  const user = useAuthStore(state => state.user);

  const { getUser, updateUser, error } = useUserStore(state => ({
    getUser: state.getUser,
    updateUser: state.updateUser,
    error: state.error
  }));

  useEffect(() => {
    const fetchUser = async () => {
      setIsFetchLoading(true);
      try {
        const userData = await getUser(user.id);
        setEditingUser(userData);
      } catch (error) {
        console.error('Ошибка при загрузке данных пользователя:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchUser();
  }, [getUser, user.id]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      lastName: '',
      firstName: '',
      middleName: '',
      position: '',
      type: '',
      phone: '',
      email: ''
    }
  });

  useEffect(() => {
    if (editingUser) {
      form.setValue('lastName', editingUser.lastName);
      form.setValue('firstName', editingUser.firstName);
      form.setValue('middleName', editingUser.middleName);
      form.setValue('position', editingUser.position);
      form.setValue('type', editingUser.type);
      form.setValue('phone', editingUser.phone);
      form.setValue('email', editingUser.email);
    }
  }, [editingUser, form]);

  const onSubmit = async () => {
    const formData = form.getValues();
    setIsLoading(true);
    try {
      await updateUser(editingUser.id, formData);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      toast(`Изменения сохранены`, {
        description: 'Обновление профиля'
      });
    }
  };

  if (isFetchLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <Layout>
      <h3>Настройки</h3>
      <Separator className="my-6" />

      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="lg:w-1/5">
          <SidebarNav items={SIDEBAR_NAV_ITEMS} />
        </aside>
        <div className="flex-1 lg:max-w-2xl">
          <h3>Профиль</h3>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 mt-4"
            >
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Фамилия</FormLabel>
                    <FormControl>
                      <Input placeholder="Иванов" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Имя</FormLabel>
                    <FormControl>
                      <Input placeholder="Иван" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="middleName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Отчество</FormLabel>
                    <FormControl>
                      <Input placeholder="Иванович" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="position"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Должность</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Начальник отдела технического обслуживания"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Тип пользователя</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger
                          className={!field.value && 'text-muted-foreground'}
                        >
                          <SelectValue placeholder="Выберите тип пользователя" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Руководитель">
                          Руководитель
                        </SelectItem>
                        <SelectItem value="Инженер">Инженер</SelectItem>
                        <SelectItem value="Специалист">Специалист</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Телефон</FormLabel>
                    <FormControl>
                      <InputMask
                        mask="+7 (999) 999-99-99"
                        placeholder="+7 (900) 000-00-00"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      >
                        {inputProps => <Input {...inputProps} />}
                      </InputMask>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Почта</FormLabel>
                    <FormControl>
                      <Input placeholder="ivanov@example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button size="sm" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Сохранение...
                  </>
                ) : (
                  'Сохранить'
                )}
              </Button>

              {error && (
                <p className="text-sm font-medium text-destructive py-0">
                  {error}
                </p>
              )}
            </form>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
