import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import * as z from 'zod';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Loader2, CalendarIcon, UserIcon } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'sonner';
import { Button } from '@/src/components/ui/button';
import { Input } from '@/src/components/ui/input';
import { Textarea } from '@/src/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/src/components/ui/select';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/src/components/ui/form';
import { Checkbox } from '@/src/components/ui/checkbox';
import { Label } from '@/src/components/ui/label';
import { Switch } from '@/src/components/ui/switch';
import { Calendar } from '@/src/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/src/components/ui/popover';
import { Card, CardContent } from '@/src/components/ui/card';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import Loader from '@/src/components/Loader';
import InputAutocomplete from '@/src/components/InputAutocomplete';
import FileItem from '@/src/components/FileItem';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useUserStore } from '@/src/store/useUserStore';
import { useObjectStore } from '@/src/store/useObjectStore';
import { useContractStore } from '@/src/store/useContractStore';
import FileService from '@/src/services/FileService';
import {
  // MAX_FILE_COUNT,
  // MAX_FILE_SIZE,
  OBJECT_TYPE,
  OBJECT_FIRE_RESISTANCE,
  OBJECT_KKPO,
  OBJECT_KFPO,
  OBJECT_SERVICE_INTERVALS,
  OBJECT_SYSTEMS,
  OBJECT_SYSTEMS_STATUS,
  OBJECT_SYSTEMS_SPS_TYPE,
  OBJECT_SYSTEMS_SPS_MANUFACTURER,
  OBJECT_SYSTEMS_SOUE_TYPE,
  OBJECT_SYSTEMS_SOUE_MANUFACTURER,
  OBJECT_SYSTEMS_AUP_TYPE,
  OBJECT_SYSTEMS_AUP_TYPE_1,
  OBJECT_SYSTEMS_VPV_TYPE,
  OBJECT_SYSTEMS_SPDV_TYPE,
  OBJECT_SYSTEMS_SPDV_TYPE_1,
  OBJECT_SYSTEMS_SPDV_TYPE_2,
  OBJECT_SYSTEMS_OS_TYPE,
  OBJECT_SYSTEMS_OS_MANUFACTURER,
  OBJECT_SYSTEMS_SKUD_TYPE,
  OBJECT_SYSTEMS_SKUD_MANUFACTURER,
  OBJECT_SYSTEMS_SOT_TYPE,
  OBJECT_SYSTEMS_AO_TYPE
} from '@/src/constants/objectConstants';
import { formatDate } from '@/src/utils/utils';

const PAGE_URL = '/objects';

const formSchema = z.object({
  address: z.object({
    name: z.string().trim().min(1, {
      message: 'Поле не может быть пустым'
    }),
    coordinates: z.object({
      latitude: z.string().default(''),
      longitude: z.string().default('')
    })
  }),
  floor: z.string().trim().min(1, {
    message: 'Поле не может быть пустым'
  }),
  area: z.coerce.number().min(1, {
    message: 'Поле не может быть пустым'
  }),
  type: z.enum(OBJECT_TYPE, {
    errorMap: () => ({ message: 'Необходимо указать тип объекта' })
  }),
  // files: z.array(z.instanceof(File)).optional(),
  // .refine(
  //   files => files?.every(file => file.size <= MAX_FILE_SIZE),
  //   'Максимальный размер файла 5 МБ.'
  // ),
  // .refine(
  //   files => files?.length <= MAX_FILE_COUNT,
  //   `Максимум ${MAX_FILE_COUNT} файлов.`
  // ),
  fire_resistance: z.enum(OBJECT_FIRE_RESISTANCE, {
    errorMap: () => ({ message: 'Необходимо указать степень огнестойкости' })
  }),
  kkpo: z.enum(OBJECT_KKPO, {
    errorMap: () => ({
      message: 'Необходимо указать класс конструктивной пожарной опасности'
    })
  }),
  kfpo: z.enum(
    OBJECT_KFPO.reduce(
      (acc, curr) => [...acc, ...curr.items.map(item => item.label)],
      []
    ),
    {
      errorMap: () => ({
        message: 'Необходимо указать класс функциональной пожарной опасности'
      })
    }
  ),
  service_intervals: z.enum(OBJECT_SERVICE_INTERVALS, {
    errorMap: () => ({
      message: 'Необходимо указать периодичность обслуживания'
    })
  }),
  user_list: z.array(z.string()),
  systems_list: z.object({})
});

const ObjectForm = () => {
  const { customerId, objectId } = useParams();
  const navigate = useNavigate();

  const isEditing = !!objectId;

  const [object, setObject] = useState(null);
  const [users, setUsers] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [usersForCustomer, setUsersForCustomer] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.heic', '.tiff'],
      'image/vnd.dwg': ['.dwg'],
      'image/x-adobe-dng': ['.dng'],
      'application/pdf': []
    },
    multiple: true,
    onDrop: acceptedFiles => {
      form.setValue('files', acceptedFiles, {
        shouldValidate: true
      });
    }
  });

  const acceptedFileItems = acceptedFiles.map((file, index) => (
    <li key={index}>{file.path}</li>
  ));

  const fileRejectionItems = fileRejections.map((file, index) => (
    <li key={index}>{file.file.path}</li>
  ));

  const style = useMemo(
    () => `
    ${'border-dashed border-2 mt-0'}
    ${isFocused && 'border-slate-500'}
    ${isDragAccept && 'border-green-500'}
    ${isDragReject && 'border-red-500'}
  `,
    [isFocused, isDragAccept, isDragReject]
  );

  const user = useAuthStore(state => state.user);

  const { getObject, createObject, updateObject, error } = useObjectStore(
    state => ({
      getObject: state.getObject,
      createObject: state.createObject,
      updateObject: state.updateObject,
      error: state.error
    })
  );

  const { getUsers, getUsersForCustomer, offset, limit } = useUserStore(
    state => ({
      getUsers: state.getUsers,
      getUsersForCustomer: state.getUsersForCustomer,
      offset: state.offset,
      limit: state.limit
    })
  );

  const { getContracts } = useContractStore(state => ({
    getContracts: state.getContracts
  }));

  useEffect(() => {
    const fetchObject = async () => {
      try {
        const objectData = await getObject(objectId);
        setObject(objectData);
      } catch (error) {
        console.error('Ошибка при загрузке данных объекта:', error);
      } finally {
        setIsFetchLoading(false);
      }
    };

    const fetchContracts = async () => {
      const contractData = await getContracts(customerId);
      setContracts(contractData);
    };

    const fetchUsers = async () => {
      let isActive = true;

      const usersData = await getUsers(
        user.id,
        'master',
        isActive,
        offset,
        limit
      );
      setUsers([user, ...usersData.users]);

      const usersForCustomerData = await getUsersForCustomer(
        customerId,
        'customer',
        isActive
      );
      setUsersForCustomer(usersForCustomerData.users);
    };

    fetchUsers();
    fetchContracts();

    if (isEditing) {
      fetchObject();
    } else {
      setIsFetchLoading(false);
    }
  }, [getObject, objectId, isEditing]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: {
        name: ''
      },
      description: '',
      floor: '',
      area: '',
      type: '',
      files: [],
      fire_resistance: '',
      kkpo: '',
      kfpo: '',
      service_intervals: '',
      systems: [],
      user_list: [],
      systems_list: {}
      // contract: ''
    }
  });

  const {
    fields: fieldsAup,
    append: appendAup,
    remove: removeAup
  } = useFieldArray({
    name: 'systems_list.aup',
    control: form.control
  });

  const {
    fields: fieldsSpdv,
    append: appendSpdv,
    remove: removeSpdv
  } = useFieldArray({
    name: 'systems_list.spdv',
    control: form.control
  });

  const handleAupTypeChange = index => {
    const { setValue } = form;

    setValue(`systems_list.aup[${index}].aup_type_water_system`, '');
    setValue(`systems_list.aup[${index}].aup_substance`, '');
    setValue(`systems_list.aup[${index}].aup_inspection_date`, '');
    setValue(`systems_list.aup[${index}].aup_manufacturer`, '');
    setValue(`systems_list.aup[${index}].aup_module_manufacturer`, '');
    setValue(`systems_list.aup[${index}].aup_start_year`, '');
    setValue(`systems_list.aup[${index}].aup_status`, '');
  };

  const handleSpdvTypeChange = index => {
    const { setValue } = form;

    setValue(`systems_list.spdv[${index}].spdv_type_value`, '');
    setValue(`systems_list.spdv[${index}].spdv_manufacturer`, '');
    setValue(`systems_list.spdv[${index}].spdv_start_year`, '');
    setValue(`systems_list.spdv[${index}].spdv_status`, '');
  };

  useEffect(() => {
    if (isEditing && object) {
      form.reset({
        ...object
      });
    }
  }, [form, isEditing, object]);

  const handleDelete = async (fileId, fileName) => {
    try {
      await FileService.deleteFile(fileId);

      setObject(prevObject => ({
        ...prevObject,
        files: prevObject.files.filter(file => file._id !== fileId)
      }));

      toast('Файл удален', {
        description: fileName
      });
    } catch (error) {
      toast(error.response.data.message, {
        description: fileName
      });
      // console.error('Ошибка при удалении файла:', error.response.data.message);
    }
  };

  const onSubmit = async () => {
    const formData = form.getValues();
    setIsLoading(true);

    try {
      if (!isEditing) {
        const object = await createObject({
          ...(customerId ? { customer: customerId } : {}),
          ...formData,
          user_list: [user.id, ...formData.user_list]
        });

        // загрузить файлы
        if (acceptedFiles.length > 0) {
          setIsFileUploading(true);
          const formData = new FormData();
          acceptedFiles.forEach(file => {
            formData.append('files', file, encodeURIComponent(file.path));
          });
          formData.append('objectId', object._id);
          formData.append('type', 'object');

          await FileService.uploadFile(formData);
          setIsFileUploading(false);
        }

        setIsLoading(false);

        if (customerId) {
          return navigate(`/customers/${customerId}`);
        }

        if (!error) {
          navigate(PAGE_URL);
        }
      } else {
        // загрузить файлы
        if (acceptedFiles.length > 0) {
          setIsFileUploading(true);
          const formData = new FormData();
          acceptedFiles.forEach(file => {
            formData.append('files', file, encodeURIComponent(file.path));
          });
          formData.append('objectId', objectId);
          formData.append('type', 'object');

          await FileService.uploadFile(formData);
          setIsFileUploading(false);
        }

        // обновить объект
        await updateObject(objectId, formData);
        setIsLoading(false);

        if (customerId) {
          return navigate(`/customers/${customerId}/objects/${objectId}`);
        }

        if (!error) {
          navigate(`${PAGE_URL}/${objectId}`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchLoading) {
    return (
      <div className="mt-4">
        <Loader />
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Адрес</FormLabel>
              <FormControl>
                <InputAutocomplete field={field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Характеристики объекта</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Добавьте описание объекта, например: офисное здание, торговый центр и т.д."
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="floor"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Этажность</FormLabel>
              <FormControl>
                <Input placeholder="2" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="area"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Площадь (м²)</FormLabel>
              <FormControl>
                <Input type="number" min="0" placeholder="500" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Типы помещения</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger
                    className={!field.value && 'text-muted-foreground'}
                  >
                    <SelectValue placeholder="Выберите тип помещения" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {OBJECT_TYPE &&
                    OBJECT_TYPE.map(item => (
                      <SelectItem key={item} value={item}>
                        {item}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="fire_resistance"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Степень огнестойкости</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger
                    className={!field.value && 'text-muted-foreground'}
                  >
                    <SelectValue placeholder="Выберите степень огнестойкости" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {OBJECT_FIRE_RESISTANCE &&
                    OBJECT_FIRE_RESISTANCE.map(item => (
                      <SelectItem key={item} value={item}>
                        {item}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="kkpo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Класс конструктивной пожарной опасности</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger
                    className={!field.value && 'text-muted-foreground'}
                  >
                    <SelectValue placeholder="Выберите класс конструктивной пожарной опасности" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {OBJECT_KKPO &&
                    OBJECT_KKPO.map(item => (
                      <SelectItem key={item} value={item}>
                        {item}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="kfpo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Класс функциональной пожарной опасности</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger
                    className={!field.value && 'text-muted-foreground'}
                  >
                    <SelectValue placeholder="Выберите класс функциональной пожарной опасности" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent className="w-min">
                  {OBJECT_KFPO &&
                    OBJECT_KFPO.map((group, index) => (
                      <SelectGroup key={index}>
                        <SelectLabel>
                          {group.label} -{' '}
                          <span className="text-sm font-normal text-muted-foreground">
                            {group.description}
                          </span>
                        </SelectLabel>
                        {group.items.map((item, index) => (
                          <SelectItem key={index} value={item.label}>
                            {item.label} -{' '}
                            <span className="text-sm font-normal text-muted-foreground">
                              {item.description}
                            </span>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="service_intervals"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Периодичность обслуживания</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger
                    className={!field.value && 'text-muted-foreground'}
                  >
                    <SelectValue placeholder="Выберите периодичность обслуживания" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {OBJECT_SERVICE_INTERVALS &&
                    OBJECT_SERVICE_INTERVALS.map(item => (
                      <SelectItem key={item} value={item}>
                        {item}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        {object?.files.length > 0 && (
          <div>
            <div className="text-lg font-semibold">Загруженные файлы</div>

            <ul className="my-2 ml-0 list-disc [&>li]:mt-2">
              {object?.files.map(file => (
                <FileItem key={file._id} file={file} onDelete={handleDelete} />
              ))}
            </ul>
          </div>
        )}

        <FormField
          control={form.control}
          name="files"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Планировки объекта с экспликацией помещения</FormLabel>
              <FormDescription>
                Доступные форматы: pdf, png, jpeg, tiff, dwg
              </FormDescription>
              <FormControl>
                <Card {...field} {...getRootProps({ className: style })}>
                  <CardContent className="px-4 py-10 text-center cursor-pointer">
                    <Input {...getInputProps()} />
                    <p>
                      Перетащите сюда несколько файлов или нажмите, чтобы
                      выбрать файлы
                    </p>
                  </CardContent>
                </Card>
              </FormControl>
              <FormMessage />
              {acceptedFileItems.length > 0 && (
                <>
                  <div className="text-lg font-semibold">Принятые файлы</div>
                  <ul className="my-6 ml-6 list-disc [&>li]:mt-2">
                    {acceptedFileItems}
                  </ul>
                </>
              )}
              {fileRejectionItems.length > 0 && (
                <>
                  <div className="text-lg font-semibold">Отклоненные файлы</div>
                  <ul className="my-6 ml-6 list-disc [&>li]:mt-2 text-red-500">
                    {fileRejectionItems}
                  </ul>
                </>
              )}
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="systems"
          render={() => (
            <FormItem>
              <FormLabel className="text-base">Обслуживаемые системы</FormLabel>
              {OBJECT_SYSTEMS.map((item, index) => (
                <FormField
                  key={index}
                  control={form.control}
                  name="systems"
                  render={({ field }) => {
                    // const isChecked = field.value?.includes(item.value);
                    const isChecked = field.value?.some(
                      system => system.value === item.value
                    );

                    return (
                      <FormItem
                        key={index}
                        className="flex flex-row items-start space-x-3 space-y-0"
                      >
                        <FormControl>
                          <Checkbox
                            checked={isChecked}
                            onCheckedChange={checked => {
                              const updatedSystems = checked
                                ? [...field.value, item]
                                : field.value?.filter(
                                    system => system.value !== item.value
                                  );

                              const updatedSystemsList = {
                                ...form.getValues().systems_list
                              };

                              if (!checked) {
                                delete updatedSystemsList[item.value];
                              }

                              form.setValue(
                                'systems',
                                updatedSystems.sort(
                                  (a, b) => parseInt(a.id) - parseInt(b.id)
                                )
                              );

                              form.setValue('systems_list', updatedSystemsList);
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {item.label}
                        </FormLabel>
                      </FormItem>
                    );
                  }}
                />
              ))}
              <FormMessage />
            </FormItem>
          )}
        />

        {/*  */}
        {form.watch('systems').some(system => system.value === 'sps') && (
          // Дополнительные поля для СПС
          <>
            <h4>СПС (система пожарной сигнализации)</h4>

            <FormField
              control={form.control}
              name="systems_list.sps.sps_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тип системы</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите тип системы" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_SPS_TYPE &&
                        OBJECT_SYSTEMS_SPS_TYPE.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.sps.sps_manufacturer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Производитель (преимущественно)</FormLabel>
                  <Select
                    onValueChange={value => {
                      form.setValue('systems_list.sps.sps_manufacturer', value);

                      if (value !== 'Другой') {
                        form.setValue(
                          'systems_list.sps.sps_manufacturer_other',
                          ''
                        );
                      }
                    }}
                    value={form.watch('systems_list.sps.sps_manufacturer')}
                    // onValueChange={field.onChange}
                    // value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите производителя" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_SPS_MANUFACTURER &&
                        OBJECT_SYSTEMS_SPS_MANUFACTURER.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {form.watch('systems_list.sps.sps_manufacturer') === 'Другой' && (
              <FormField
                control={form.control}
                name="systems_list.sps.sps_manufacturer_other"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Свой вариант</FormLabel>
                    <FormControl>
                      <Input placeholder="Введите значение" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="systems_list.sps.sps_start_year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Год ввода в эксплуатацию</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.sps.sps_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Работоспособность</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите вариант" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_STATUS &&
                        OBJECT_SYSTEMS_STATUS.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        {form.watch('systems').some(system => system.value === 'soue') && (
          // Дополнительные поля для СОУЭ
          <>
            <h4>
              СОУЭ (система оповещения и управления эвакуацией людей при пожаре)
            </h4>

            <FormField
              control={form.control}
              name="systems_list.soue.soue_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тип системы</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите тип системы" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_SOUE_TYPE &&
                        OBJECT_SYSTEMS_SOUE_TYPE.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.soue.soue_manufacturer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Производитель (преимущественно)</FormLabel>
                  <Select
                    onValueChange={value => {
                      form.setValue(
                        'systems_list.soue.soue_manufacturer',
                        value
                      );

                      if (value !== 'Другой') {
                        form.setValue(
                          'systems_list.soue.soue_manufacturer_other',
                          ''
                        );
                      }
                    }}
                    value={form.watch('systems_list.soue.soue_manufacturer')}
                    // onValueChange={field.onChange}
                    // value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите производителя" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_SOUE_MANUFACTURER &&
                        OBJECT_SYSTEMS_SOUE_MANUFACTURER.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {form.watch('systems_list.soue.soue_manufacturer') === 'Другой' && (
              <FormField
                control={form.control}
                name="systems_list.soue.soue_manufacturer_other"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Свой вариант</FormLabel>
                    <FormControl>
                      <Input placeholder="Введите значение" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="systems_list.soue.soue_start_year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Год ввода в эксплуатацию</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.soue.soue_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Работоспособность</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите вариант" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_STATUS &&
                        OBJECT_SYSTEMS_STATUS.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        {form.watch('systems').some(system => system.value === 'aup') && (
          // Дополнительные поля для АУП
          <>
            <h4>АУП (автоматическая установка пожаротушения)</h4>

            {fieldsAup.map((field, index) => (
              <div key={index} className="space-y-4">
                <div className="flex flex-row gap-2 items-center justify-between">
                  <p className="text-lg font-semibold">
                    Система: {form.watch(`systems_list.aup[${index}].aup_name`)}
                  </p>
                  <Button
                    size="sm"
                    type="button"
                    className="mt-2"
                    onClick={() => removeAup(index)}
                  >
                    Удалить
                  </Button>
                </div>

                <FormField
                  control={form.control}
                  name={`systems_list.aup[${index}].aup_name`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Наименование системы</FormLabel>
                      <FormControl>
                        <Input placeholder="Введите значение" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  key={field.id}
                  name={`systems_list.aup[${index}].aup_type`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Тип установки`}</FormLabel>
                      <Select
                        onValueChange={value => {
                          field.onChange(value);
                          handleAupTypeChange(index, value);
                        }}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            className={!field.value && 'text-muted-foreground'}
                          >
                            <SelectValue placeholder="Выберите тип установки" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {OBJECT_SYSTEMS_AUP_TYPE &&
                            OBJECT_SYSTEMS_AUP_TYPE.map((item, idx) => (
                              <SelectItem key={idx} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {form.watch(`systems_list.aup[${index}].aup_type`) ===
                  OBJECT_SYSTEMS_AUP_TYPE[0] && (
                  <>
                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_type_water_system`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Тип водяной установки</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <FormControl>
                              <SelectTrigger
                                className={
                                  !field.value && 'text-muted-foreground'
                                }
                              >
                                <SelectValue placeholder="Выберите тип установки" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {OBJECT_SYSTEMS_AUP_TYPE_1 &&
                                OBJECT_SYSTEMS_AUP_TYPE_1.map((item, index) => (
                                  <SelectItem key={index} value={item}>
                                    {item}
                                  </SelectItem>
                                ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                {form.watch(`systems_list.aup[${index}].aup_type`) ===
                  OBJECT_SYSTEMS_AUP_TYPE[1] && (
                  <>
                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_manufacturer`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Производитель автоматики</FormLabel>
                          <FormControl>
                            <Input placeholder="Введите значение" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_substance`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Газовое огнетушащее вещество</FormLabel>
                          <FormControl>
                            <Input placeholder="Введите значение" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_inspection_date`}
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>
                            Дата последнего освидетельствования баллонов
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outline'}
                                  className={
                                    ('w-[240px] pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground')
                                  }
                                >
                                  {field.value ? (
                                    format(
                                      parseISO(field.value),
                                      'dd MMMM yyyy',
                                      {
                                        locale: ru
                                      }
                                    )
                                  ) : (
                                    <span>Дата</span>
                                  )}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-auto p-0"
                              align="start"
                            >
                              <Calendar
                                initialFocus
                                mode="single"
                                locale={ru}
                                selected={
                                  field.value ? parseISO(field.value) : null
                                }
                                onSelect={date =>
                                  field.onChange(
                                    date ? format(date, 'yyyy-MM-dd') : null
                                  )
                                }
                                captionLayout="dropdown-buttons"
                                fromYear={2015}
                                toYear={2035}
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                {form.watch(`systems_list.aup[${index}].aup_type`) ===
                  OBJECT_SYSTEMS_AUP_TYPE[2] && (
                  <>
                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_manufacturer`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Производитель автоматики</FormLabel>
                          <FormControl>
                            <Input placeholder="Введите значение" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_module_manufacturer`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Модули порошкового пожаротушения (производитель)
                          </FormLabel>
                          <FormControl>
                            <Input placeholder="Введите значение" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                {form.watch(`systems_list.aup[${index}].aup_type`) ===
                  OBJECT_SYSTEMS_AUP_TYPE[3] && (
                  <>
                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_manufacturer`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Производитель автоматики</FormLabel>
                          <FormControl>
                            <Input placeholder="Введите значение" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name={`systems_list.aup[${index}].aup_module_manufacturer`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Модули аэрозольного пожаротушения (производитель)
                          </FormLabel>
                          <FormControl>
                            <Input placeholder="Введите значение" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                <FormField
                  control={form.control}
                  name={`systems_list.aup[${index}].aup_start_year`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Год ввода в эксплуатацию</FormLabel>
                      <FormControl>
                        <Input placeholder="Введите значение" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`systems_list.aup[${index}].aup_status`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Работоспособность</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            className={!field.value && 'text-muted-foreground'}
                          >
                            <SelectValue placeholder="Выберите вариант" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {OBJECT_SYSTEMS_STATUS &&
                            OBJECT_SYSTEMS_STATUS.map((item, index) => (
                              <SelectItem key={index} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}

            <div>
              <Button
                size="sm"
                type="button"
                className="mt-2"
                onClick={() => {
                  appendAup({
                    aup_type: '',
                    aup_type_water_system: '',
                    aup_substance: '',
                    aup_inspection_date: '',
                    aup_manufacturer: '',
                    aup_module_manufacturer: '',
                    aup_start_year: '',
                    aup_status: ''
                  });
                }}
              >
                Добавить
              </Button>
            </div>
          </>
        )}

        {form.watch('systems').some(system => system.value === 'vpv') && (
          // Дополнительные поля для ВПВ
          <>
            <h4>ВПВ (внутренний противопожарный водопровод)</h4>

            <FormField
              control={form.control}
              name="systems_list.vpv.vpv_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тип водопровода</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите тип водопровода" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_VPV_TYPE &&
                        OBJECT_SYSTEMS_VPV_TYPE.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.vpv.vpv_manufacturer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Производитель автоматики</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.vpv.vpv_start_year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Год ввода в эксплуатацию</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.vpv.vpv_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Работоспособность</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите вариант" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_STATUS &&
                        OBJECT_SYSTEMS_STATUS.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        {form.watch('systems').some(system => system.value === 'spdv') && (
          // Дополнительные поля для СПДВ
          <>
            <h4>СПДВ (система противодымной вентиляции)</h4>

            {fieldsSpdv.map((field, index) => (
              <div key={index} className="space-y-4">
                <div className="flex flex-row gap-2 items-center justify-between">
                  <p className="text-lg font-semibold">
                    Система:{' '}
                    {form.watch(`systems_list.spdv[${index}].spdv_name`)}
                  </p>
                  <Button
                    size="sm"
                    type="button"
                    className="mt-2"
                    onClick={() => removeSpdv(index)}
                  >
                    Удалить
                  </Button>
                </div>

                <FormField
                  control={form.control}
                  name={`systems_list.spdv[${index}].spdv_name`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Наименование системы</FormLabel>
                      <FormControl>
                        <Input placeholder="Введите значение" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`systems_list.spdv[${index}].spdv_description`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Описание системы</FormLabel>
                      <FormControl>
                        <Textarea placeholder="Введите значение" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  key={field.id}
                  name={`systems_list.spdv[${index}].spdv_type`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{`Тип системы`}</FormLabel>
                      <Select
                        onValueChange={value => {
                          field.onChange(value);
                          handleSpdvTypeChange(index, value);
                        }}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            className={!field.value && 'text-muted-foreground'}
                          >
                            <SelectValue placeholder="Выберите тип системы" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {OBJECT_SYSTEMS_SPDV_TYPE &&
                            OBJECT_SYSTEMS_SPDV_TYPE.map((item, idx) => (
                              <SelectItem key={idx} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {form.watch(`systems_list.spdv[${index}].spdv_type`) ===
                  OBJECT_SYSTEMS_SPDV_TYPE[0] && (
                  <FormField
                    control={form.control}
                    key={field.id}
                    name={`systems_list.spdv[${index}].spdv_type_value`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{`Тип вытяжной противодымной вентиляции`}</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger
                              className={
                                !field.value && 'text-muted-foreground'
                              }
                            >
                              <SelectValue placeholder="Выберите тип системы" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {OBJECT_SYSTEMS_SPDV_TYPE_1 &&
                              OBJECT_SYSTEMS_SPDV_TYPE_1.map((item, idx) => (
                                <SelectItem key={idx} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                {form.watch(`systems_list.spdv[${index}].spdv_type`) ===
                  OBJECT_SYSTEMS_SPDV_TYPE[1] && (
                  <FormField
                    control={form.control}
                    key={field.id}
                    name={`systems_list.spdv[${index}].spdv_type_value`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{`Тип приточной противодымной вентиляции`}</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger
                              className={
                                !field.value && 'text-muted-foreground'
                              }
                            >
                              <SelectValue placeholder="Выберите тип системы" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {OBJECT_SYSTEMS_SPDV_TYPE_2 &&
                              OBJECT_SYSTEMS_SPDV_TYPE_2.map((item, idx) => (
                                <SelectItem key={idx} value={item}>
                                  {item}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <FormField
                  control={form.control}
                  name={`systems_list.spdv[${index}].spdv_manufacturer`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Производитель автоматики</FormLabel>
                      <FormControl>
                        <Input placeholder="Введите значение" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`systems_list.spdv[${index}].spdv_start_year`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Год ввода в эксплуатацию</FormLabel>
                      <FormControl>
                        <Input placeholder="Введите значение" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`systems_list.spdv[${index}].spdv_status`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Работоспособность</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            className={!field.value && 'text-muted-foreground'}
                          >
                            <SelectValue placeholder="Выберите вариант" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {OBJECT_SYSTEMS_STATUS &&
                            OBJECT_SYSTEMS_STATUS.map((item, index) => (
                              <SelectItem key={index} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}

            <div>
              <Button
                type="button"
                size="sm"
                className="mt-2"
                onClick={() => {
                  appendSpdv({
                    spdv_type: '',
                    spdv_type_value: '',
                    spdv_manufacturer: '',
                    spdv_start_year: '',
                    spdv_status: ''
                  });
                }}
              >
                Добавить
              </Button>
            </div>
          </>
        )}

        {form.watch('systems').some(system => system.value === 'os') && (
          // Дополнительные поля для ОС
          <>
            <h4>ОС (охранная сигнализация)</h4>

            <FormField
              control={form.control}
              name="systems_list.os.os_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тип системы</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите тип системы" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_OS_TYPE &&
                        OBJECT_SYSTEMS_OS_TYPE.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.os.os_manufacturer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Производитель (преимущественно)</FormLabel>
                  <Select
                    onValueChange={value => {
                      form.setValue('systems_list.os.os_manufacturer', value);

                      if (value !== 'Другой') {
                        form.setValue(
                          'systems_list.os.os_manufacturer_other',
                          ''
                        );
                      }
                    }}
                    value={form.watch('systems_list.os.os_manufacturer')}
                    // onValueChange={field.onChange}
                    // value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите производителя" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_OS_MANUFACTURER &&
                        OBJECT_SYSTEMS_OS_MANUFACTURER.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {form.watch('systems_list.os.os_manufacturer') === 'Другой' && (
              <FormField
                control={form.control}
                name="systems_list.os.os_manufacturer_other"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Свой вариант</FormLabel>
                    <FormControl>
                      <Input placeholder="Введите значение" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="systems_list.os.os_start_year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Год ввода в эксплуатацию</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.os.os_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Работоспособность</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите вариант" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_STATUS &&
                        OBJECT_SYSTEMS_STATUS.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        {form.watch('systems').some(system => system.value === 'skud') && (
          // Дополнительные поля для СКУД
          <>
            <h4>СКУД (система контроля и управления доступом)</h4>

            <FormField
              control={form.control}
              name="systems_list.skud.skud_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тип системы</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите тип системы" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_SKUD_TYPE &&
                        OBJECT_SYSTEMS_SKUD_TYPE.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.skud.skud_manufacturer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Производитель (преимущественно)</FormLabel>
                  <Select
                    onValueChange={value => {
                      form.setValue(
                        'systems_list.skud.skud_manufacturer',
                        value
                      );

                      if (value !== 'Другой') {
                        form.setValue(
                          'systems_list.skud.skud_manufacturer_other',
                          ''
                        );
                      }
                    }}
                    value={form.watch('systems_list.skud.skud_manufacturer')}
                    // onValueChange={field.onChange}
                    // value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите производителя" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_SKUD_MANUFACTURER &&
                        OBJECT_SYSTEMS_SKUD_MANUFACTURER.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {form.watch('systems_list.skud.skud_manufacturer') === 'Другой' && (
              <FormField
                control={form.control}
                name="systems_list.skud.skud_manufacturer_other"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Свой вариант</FormLabel>
                    <FormControl>
                      <Input placeholder="Введите значение" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="systems_list.skud.skud_start_year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Год ввода в эксплуатацию</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.skud.skud_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Работоспособность</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите вариант" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_STATUS &&
                        OBJECT_SYSTEMS_STATUS.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        {form.watch('systems').some(system => system.value === 'sot') && (
          // Дополнительные поля для СОТ
          <>
            <h4>СОТ (система охранного телевидения)</h4>

            <FormField
              control={form.control}
              name="systems_list.sot.sot_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тип системы</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите тип системы" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_SOT_TYPE &&
                        OBJECT_SYSTEMS_SOT_TYPE.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.sot.sot_manufacturer_camera"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Производитель камер</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.sot.sot_manufacturer_dvr"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Производитель видеорегистратора</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.sot.sot_archive_depth"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Глубина архива</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.sot.sot_number_of_cameras"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Количество камер</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.sot.sot_remote_access"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Есть удаленный доступ</FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </>
        )}

        {form.watch('systems').some(system => system.value === 'ao') && (
          // Дополнительные поля для АО
          <>
            <h4>АО (система аварийного освещения)</h4>

            <FormField
              control={form.control}
              name="systems_list.ao.ao_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Тип системы</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите тип системы" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_AO_TYPE &&
                        OBJECT_SYSTEMS_AO_TYPE.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.ao.ao_number_of_luminaires"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Количество светильников</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.ao.ao_dgu_availability"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Наличие ДГУ</FormLabel>
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.ao.ao_battery_power"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Питание от аккумулятора</FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </>
        )}

        {form.watch('systems').some(system => system.value === 'pak') && (
          // Дополнительные поля для Стрелец-Мониторинг
          <>
            <h4>Стрелец-Мониторинг</h4>

            <FormField
              control={form.control}
              name="systems_list.pak.pak_start_year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Год ввода в эксплуатацию</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите значение" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="systems_list.pak.pak_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Работоспособность</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger
                        className={!field.value && 'text-muted-foreground'}
                      >
                        <SelectValue placeholder="Выберите вариант" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {OBJECT_SYSTEMS_STATUS &&
                        OBJECT_SYSTEMS_STATUS.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        {/*  */}
        <h4>Договор</h4>
        <FormField
          control={form.control}
          name="contract"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Договор</FormLabel> */}
              {contracts && contracts.length <= 0 ? (
                <p className="muted">Вы не добавили ни одного договора</p>
              ) : (
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger
                      className={!field.value && 'text-muted-foreground'}
                    >
                      <SelectValue placeholder="Выберите договор" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {contracts &&
                      contracts.map(item => (
                        <SelectItem key={item._id} value={item._id}>
                          {item.number}{' '}
                          <span className="text-sm font-normal text-muted-foreground">
                            от {formatDate(item.dateSignature)}
                          </span>
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              )}
              <FormMessage />
            </FormItem>
          )}
        />

        <h4>Управление доступом</h4>
        <FormField
          control={form.control}
          name="user_list"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Представители Заказчика</FormLabel>
              <div className="grid gap-4">
                {usersForCustomer?.length > 0 ? (
                  usersForCustomer.map(user => (
                    <FormControl key={user.id}>
                      <div className="flex items-center justify-between space-x-4">
                        <div className="flex items-center space-x-2">
                          <Label
                            htmlFor={user.id}
                            className="flex flex-col space-y-1"
                          >
                            <div className="flex items-center space-x-4">
                              <Avatar>
                                <AvatarFallback>
                                  <UserIcon />
                                </AvatarFallback>
                              </Avatar>
                              <div>
                                <p className="text-sm font-medium leading-none">
                                  {`${user.lastName} ${user.firstName} ${user.middleName}`}
                                </p>
                                <p className="text-sm text-muted-foreground">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                          </Label>
                        </div>
                        <Switch
                          id={user.id}
                          checked={field.value?.includes(user.id)}
                          onCheckedChange={checked => {
                            const newUserList = checked
                              ? [...field.value, user.id]
                              : field.value.filter(id => id !== user.id);
                            field.onChange(newUserList);
                          }}
                        />
                      </div>
                    </FormControl>
                  ))
                ) : (
                  <p className="muted">Нет пользователей</p>
                )}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="user_list"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Сотрудники Исполнителя</FormLabel>
              <div className="grid gap-4">
                {users?.length > 0 ? (
                  users.map(user => (
                    <FormControl key={user.id}>
                      <div className="flex items-center justify-between space-x-4">
                        <div className="flex items-center space-x-2">
                          <Label
                            htmlFor={user.id}
                            className="flex flex-col space-y-1"
                          >
                            <div className="flex items-center space-x-4">
                              <Avatar>
                                <AvatarFallback>
                                  <UserIcon />
                                </AvatarFallback>
                              </Avatar>
                              <div>
                                <p className="text-sm font-medium leading-none">
                                  {`${user.lastName} ${user.firstName} ${user.middleName}`}
                                </p>
                                <p className="text-sm text-muted-foreground">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                          </Label>
                        </div>
                        <Switch
                          id={user.id}
                          disabled={user.roles.includes('user')}
                          checked={
                            user.roles.includes('user') ||
                            field.value?.includes(user.id)
                          }
                          onCheckedChange={checked => {
                            const newUserList = checked
                              ? [...field.value, user.id]
                              : field.value.filter(id => id !== user.id);
                            field.onChange(newUserList);
                          }}
                        />
                      </div>
                    </FormControl>
                  ))
                ) : (
                  <p className="muted">Нет пользователей</p>
                )}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button size="sm" type="submit" disabled={isLoading}>
          {isFileUploading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Загрузка файлов...
            </>
          ) : isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Сохранение...
            </>
          ) : (
            'Сохранить'
          )}
        </Button>

        {error && (
          <p className="text-sm font-medium text-destructive py-0">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default ObjectForm;
