import { useState, useEffect } from 'react';
import { Input } from '@/src/components/ui/input';
import {
  Command,
  CommandEmpty,
  CommandItem,
  CommandList
} from '@/src/components/ui/command';
import useDebounce from '@/src/hooks/useDebounce';

const InputAutocomplete = ({ field }) => {
  const [editingInput, setEditingInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const fetchDadata = async () => {
    try {
      const response = await fetch(
        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token ${process.env.REACT_APP_DADATA_API_KEY}`
          },
          body: JSON.stringify({
            query: inputValue,
            count: 5
          })
        }
      );

      const data = await response.json();
      setSuggestions(data.suggestions);
      setIsPopupVisible(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const debounceTitle = useDebounce(fetchDadata, 500);

  const handleInputChange = event => {
    const value = event.target.value;
    setInputValue(value);
    setEditingInput(false);
  };

  useEffect(() => {
    if (field.value !== '') {
      setInputValue(field.value);
      setEditingInput(true);
    }
  }, [field]);

  useEffect(() => {
    if (!editingInput && inputValue.trim() !== '') {
      debounceTitle(inputValue);
    } else {
      setSuggestions([]);
      setIsPopupVisible(false); // добавьте эту строку, чтобы скрыть список при необходимости
    }
  }, [editingInput, inputValue]);

  return (
    <>
      <Command className="overflow-visible">
        <Input
          value={inputValue?.name}
          onChange={handleInputChange}
          placeholder="Введите адрес"
        />

        {isPopupVisible && (
          <CommandList className="mt-2 border rounded-md">
            <CommandEmpty>
              <span className="muted">Не найдено</span>
            </CommandEmpty>
            {suggestions?.map(suggestion => (
              <CommandItem
                key={suggestion.value}
                value={suggestion.value}
                onSelect={currentValue => {
                  setInputValue(
                    currentValue === suggestion.value ? '' : suggestion.value
                  );
                  setEditingInput(true);
                  setIsPopupVisible(false);
                  field.onChange({
                    name: suggestion.value,
                    coordinates: {
                      latitude: suggestion.data.geo_lat,
                      longitude: suggestion.data.geo_lon
                    }
                  });
                }}
              >
                {suggestion.value}
              </CommandItem>
            ))}
          </CommandList>
        )}
      </Command>
    </>
  );
};

export default InputAutocomplete;
