import Layout from '@/src/components/Layout';
import ForgotPasswordForm from '@/src/components/auth/ForgotPasswordForm';

const ForgotPassword = () => {
  return (
    <Layout>
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
        <h3 className="text-center">Восстановление доступа</h3>

        <ForgotPasswordForm />
      </div>
    </Layout>
  );
};

export default ForgotPassword;
