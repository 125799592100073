const { default: $api } = require('@/src/lib/api');

const UserService = {
  forgotPassword: async email => {
    return $api.post('/users/forgot-password', { email });
  },

  changePassword: async (forgotPasswordLink, password) => {
    return $api.post('/users/change-password', {
      forgotPasswordLink,
      password
    });
  },

  createUser: async data => {
    return $api.post('/users', data);
  },

  getUsers: async (userId, role, isActive, offset, limit) => {
    return $api.get('/users', {
      params: { userId, role, isActive, offset, limit }
    });
  },

  getUser: async id => {
    return $api.get(`/users/${id}`);
  },

  updateUser: async (id, data) => {
    return $api.patch(`/users/${id}`, data);
  },

  deleteUser: async id => {
    return $api.delete(`/users/${id}`);
  },

  getUsersForObject: async id => {
    return $api.get(`/objects/${id}/users`);
  },

  getUsersForCustomer: async (customerId, role, isActive, offset, limit) => {
    return $api.get(`/customers/${customerId}/users`, {
      params: { customerId, role, isActive, offset, limit }
    });
  },

  disconnectTelegram: async () => {
    return $api.post('/telegram/disconnect');
  }
};

module.exports = UserService;
