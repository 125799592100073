import Layout from '@/src/components/Layout';

const Terms = () => {
  return (
    <Layout>
      <h3>Условия обслуживания</h3>
    </Layout>
  );
};

export default Terms;
