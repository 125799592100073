import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import CustomerItem from '@/src/components/customer/CustomerItem';

const Customer = () => {
  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Заказчик', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <CustomerItem />
    </Layout>
  );
};

export default Customer;
