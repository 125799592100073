import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@/src/components/ui/skeleton';
import { Button } from '@/src/components/ui/button';
import Layout from '@/src/components/Layout';
import UserList from '@/src/components/user/UserList';
import { useAuthStore } from '@/src/store/useAuthStore';
import { useUserStore } from '@/src/store/useUserStore';

const USERS_LIMIT = 10;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  const user = useAuthStore(state => state.user);

  const { getUsers } = useUserStore(state => ({
    getUsers: state.getUsers
  }));

  const isUser = user?.roles?.includes('user');
  // const isCustomer = user?.roles?.includes('customer');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let usersData;

        if (isUser) {
          usersData = await getUsers(
            user.id,
            'master',
            null,
            (currentPage - 1) * USERS_LIMIT,
            USERS_LIMIT
          );
        }

        setUsers(prevUsers => [...prevUsers, ...usersData.users]);
        setTotalUsers(usersData.total);
      } catch (error) {
        console.error('Ошибка при загрузке сотрудников:', error);
      } finally {
        setIsFetchLoading(false);
        setIsLoadingMore(false);
      }
    };

    fetchUsers();
  }, [currentPage]);

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    setCurrentPage(prevPage => prevPage + 1);
  };

  return (
    <Layout>
      <div className="flex flex-row gap-2 items-center justify-between">
        <h3>Сотрудники</h3>

        {isUser && (
          <Button size="sm" asChild>
            <Link to="/users/create">Добавить</Link>
          </Button>
        )}
      </div>

      {isFetchLoading ? (
        <div className="flex flex-col space-y-6 mt-4 mb-4">
          <div className="flex items-center space-x-4">
            <Skeleton className="h-10 w-10 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[350px]" />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Skeleton className="h-10 w-10 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[350px]" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <UserList users={users} />

          {users.length < totalUsers && (
            <div className="flex justify-center mt-5">
              <Button onClick={handleLoadMore} disabled={isLoadingMore}>
                {isLoadingMore ? 'Загрузка...' : 'Загрузить еще'}
              </Button>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default Users;
