import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Building2,
  UserIcon,
  Menu,
  X,
  Settings,
  Headphones,
  LogOut
} from 'lucide-react';
import { Avatar, AvatarFallback } from '@/src/components/ui/avatar';
import { Button } from '@/src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/src/components/ui/dropdown-menu';
import { useAuthStore } from '@/src/store/useAuthStore';

const menu = [
  {
    id: '1',
    name: 'Рабочий стол',
    slug: '/',
    roles: ['admin', 'user', 'customer', 'master']
  },
  {
    id: '2',
    name: 'Заказчики',
    slug: '/customers',
    roles: ['admin', 'user', 'master']
  },
  {
    id: '4',
    name: 'Сотрудники',
    slug: '/users',
    roles: ['admin', 'user']
  }
];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { isAuth, user, logout } = useAuthStore(state => ({
    isAuth: state.isAuth,
    user: state.user,
    logout: state.logout
  }));

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [pathname]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-white shadow-sm">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {isAuth ? (
          <div>
            <div className="relative flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={'/'}>
                    <Building2 />
                  </Link>
                </div>

                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {menu &&
                    menu.map(item =>
                      item.roles.filter(x => user.roles.indexOf(x) !== -1)
                        .length > 0 ? (
                        <Link
                          key={item.id}
                          className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                            pathname === item.slug ||
                            (item.slug !== '/' &&
                              pathname.startsWith(item.slug))
                              ? 'border-slate-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                          }`}
                          to={item.slug}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        ''
                      )
                    )}
                </div>

                <div className="flex items-center -my-px ml-6 space-x-8 sm:hidden">
                  {menu &&
                    menu.map(item =>
                      pathname === item.slug ? (
                        <div
                          key={item.id}
                          className={`text-sm font-medium text-gray-900'`}
                        >
                          {item.name}
                        </div>
                      ) : null
                    )}
                </div>
              </div>

              <div className="hidden sm:flex flex-shrink-0 items-center">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="relative h-8 w-8 rounded-full"
                    >
                      <Avatar className="h-8 w-8">
                        <AvatarFallback>
                          <UserIcon />
                        </AvatarFallback>
                      </Avatar>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="hidden sm:block w-200"
                    align="end"
                    forceMount
                  >
                    <DropdownMenuLabel className="font-normal">
                      <div className="flex flex-col space-y-2">
                        <p className="items-center text-sm font-medium leading-none">
                          {user &&
                            `${user?.lastName} ${user?.firstName} ${user?.middleName}`}
                        </p>
                        <p className="text-xs leading-none text-muted-foreground mt-1">
                          {user && user?.email}
                        </p>
                      </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem>
                        <Link
                          to="/settings"
                          className="flex flex-1 items-center"
                        >
                          <Settings className="mr-2 h-4 w-4" />
                          Настройки
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Link
                          to="/support"
                          className="flex flex-1 items-center"
                        >
                          <Headphones className="mr-2 h-4 w-4" />
                          Поддержка
                        </Link>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="flex flex-1 items-center cursor-pointer"
                      onClick={handleLogout}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>Выйти</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>

              <div className="flex sm:hidden flex-shrink-0 items-center -ml-2 -mr-2">
                <Button
                  variant="ghost"
                  className="p-2"
                  onClick={toggleMobileMenu}
                >
                  {isMobileMenuOpen ? (
                    <X className="h-6 w-6" />
                  ) : (
                    <Menu className="h-6 w-6" />
                  )}
                </Button>
              </div>
            </div>
            {isMobileMenuOpen && (
              <div className="sm:hidden">
                <div className="py-2">
                  {menu.map(item =>
                    item.roles.filter(x => user.roles.indexOf(x) !== -1)
                      .length > 0 ? (
                      <Link
                        key={item.id}
                        className={`block py-2 text-base font-medium ${
                          pathname === item.slug ||
                          (item.slug !== '/' && pathname.startsWith(item.slug))
                            ? 'text-gray-900'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        to={item.slug}
                      >
                        {item.name}
                      </Link>
                    ) : (
                      ''
                    )
                  )}
                </div>
                <div className="py-4 border-t border-gray-300">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Avatar className="h-10 w-10">
                        <AvatarFallback>
                          <UserIcon />
                        </AvatarFallback>
                      </Avatar>
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-800 truncate">
                        {user &&
                          `${user?.lastName} ${user?.firstName} ${user?.middleName}`}
                      </p>
                      <p className="text-sm text-gray-500">
                        {user && user?.email}
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Link
                      to="/settings"
                      className={`flex flex-1 items-center py-2 text-base font-medium ${
                        pathname === '/settings'
                          ? 'text-gray-900'
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                    >
                      <Settings className="mr-2 h-4 w-4" />
                      Настройки
                    </Link>
                    <Link
                      to="/support"
                      className={`flex flex-1 items-center py-2 text-base font-medium ${
                        pathname === '/support'
                          ? 'text-gray-900'
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                    >
                      <Headphones className="mr-2 h-4 w-4" />
                      Поддержка
                    </Link>
                    <div
                      className="flex flex-1 items-center py-2 text-base font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                      onClick={handleLogout}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      Выйти
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="relative flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <Link to={'/'}>
                  <Building2 />
                </Link>
              </div>
            </div>
            <div className="flex space-x-4">
              <Link
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                  pathname === '/'
                    ? 'text-gray-900'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                to={'/'}
              >
                Вход
              </Link>
              <Link
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                  pathname === '/registration'
                    ? 'text-gray-900'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                to={'/registration'}
              >
                Регистрация
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
