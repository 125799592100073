import { useParams } from 'react-router-dom';
import Layout from '@/src/components/Layout';
import Breadcrumb from '@/src/components/Breadcrumb';
import NoteForm from '@/src/components/note/NoteForm';

const EditNote = () => {
  const { customerId, objectId, noteId } = useParams();

  const breadcrumbItems = [
    { text: 'Заказчики', link: '/customers' },
    { text: 'Заказчик', link: `/customers/${customerId}` },
    { text: 'Объект', link: `/customers/${customerId}/objects/${objectId}` },
    {
      text: 'Запись',
      link: `/customers/${customerId}/objects/${objectId}/notes/${noteId}`
    },
    { text: 'Редактирование', link: null }
  ];

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />

      <h3>Редактировать запись</h3>

      <NoteForm />
    </Layout>
  );
};

export default EditNote;
